import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Amplify, { Hub } from "@aws-amplify/core";
import {
  getUserInfo,
  redirectToLoginPage,
} from "../../authentication/cognito-auth";
// import authStore from "../../authentication/oidc-auth";
import { useSelector } from "react-redux";
import { config } from "../../config/config";
import constants from "../../config/constants";

import {
  Button,
  SpaceBetween,
  Grid,
  Container,
  Header,
  Link,
} from "@amzn/awsui-components-react-v3";
import "./auth-control.css";

const AuthControl = () => {
  // redux
  const { authzReducer } = useSelector((state) => ({
    authzReducer: state.authzReducer,
  }));

  // state
  const [authState, setAuthState] = useState(constants.UNAUTHENTICATED);
  const [idp, setIdp] = useState(undefined);
  const [useCognito] = useState(true);
  //   const [useCognito, setUseCognito] = useState(true);

  const History = useHistory();

  Amplify.configure(config.AMPLIFY_CONFIG);

  // 2) cognito login listener
  if (window.location.pathname.includes(constants.WEBSITE_PATH_COGNITO)) {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log(event, data);
      switch (event) {
        case constants.SIGNIN:
          getUserInfo().then((userInfo) => {
            if (userInfo) {
              setAuthState(constants.AUTHENTICATED);
              setIdp(userInfo.identities[0].providerName);
            }
          });
          break;
        case constants.SIGNIN_FAILURE:
          setAuthState(constants.SIGNIN_FAILURE);
          break;
        case constants.SIGNOUT:
          redirectToLoginPage(idp);
          break;
        case constants.CUSTOM_OAUTH_STATE:
          window.location.href = decodeURIComponent(data);
          break;
        default:
          return;
      }
    });
  }

  // 1) cognito authorization code steps
  useEffect(() => {
    if (authState === constants.UNAUTHENTICATED && idp && useCognito) {
      setTimeout(
        () => {
          getUserInfo().then((_userInfo) => {
            if (_userInfo && idp === _userInfo.identities[0].providerName) {
              setAuthState(constants.AUTHENTICATED);
              if (
                !window.location.pathname.includes(
                  constants.WEBSITE_PATH_COGNITO
                )
              ) {
                History.push(constants.WEBSITE_PATH_COGNITO);
              }
            } else {
              redirectToLoginPage(idp);
            }
          });
        },
        new URLSearchParams(window.location.search).has("code") ? 3000 : 500
      );
    }
  }, [idp, useCognito, authState, History]);

  if (!idp)
    return (
      <React.Fragment>
        <div className="app-layout">
          <div className="app-title">
            <div className={"app-title-main-text"}>
              <div>WaaS</div>
            </div>
            <div className={"app-title-sub-text"}>
              <h3>
                Everything you need to create and manage your wireless testcases
                — in one web interface{" "}
              </h3>
            </div>
            <br />

            <div className={"app-login-btn-group"}>
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  className="app-button"
                  onClick={() => setIdp(constants.IDP_AMAZON_FEDERATE)}
                >
                  {/* Cognito + Amazon Federate */}
                  Amazon Employees/Contractors
                </Button>

                <div>
                  <Button
                    variant="primary"
                    className="app-button"
                    onClick={() => setIdp(constants.IDP_LASSO)}
                  >
                    Vendors and Bots
                    {/* Cognito + LASSO */}
                  </Button>
                </div>
              </SpaceBetween>
            </div>
          </div>

          <div className="app-intro-area">
            <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
              <div>
                <div className="app-intro-area-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                </div>
                <div className="app-intro-area-image">
                  <img
                    src="//d1.awsstatic.com/management-console/aws-management-console.85b74a70bbb5749c7ce951bbfc62d2d8760a4c34.png"
                    alt="placeholderimage"
                  />
                </div>
              </div>
              <div>
                {/* feature overview  */}
                <Container
                  header={
                    <Header variant="h2" description="Container description">
                      Feature overview
                    </Header>
                  }
                >
                  <ul>
                    <li>
                      Create test plan with your own configs & Assign Labs to
                      test{" "}
                    </li>
                    <li>View & Upload test plan result (lab)</li>
                    <li>Check test plan result & view visualizations</li>
                  </ul>
                </Container>
                <br />
                <Container
                  header={
                    <Header variant="h2" description="Container description">
                      Additional Resources
                    </Header>
                  }
                >
                  <ul>
                    <li>
                      <Link
                        external
                        externalIconAriaLabel="Opens in a new tab"
                        href="https://w.amazon.com/bin/view/Wireless-Infrastructure-Software-Engineering/Team"
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        external
                        externalIconAriaLabel="Opens in a new tab"
                        href="https://w.amazon.com/bin/view/Wireless-Infrastructure-Software-Engineering/ContactUs"
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        external
                        externalIconAriaLabel="Opens in a new tab"
                        href="https://sim.amazon.com/issues/create?template=94f5b1b7-59a8-4195-bdc9-15debfda57dc"
                      >
                        Submit Questions
                      </Link>
                    </li>
                  </ul>

                  {/* */}
                </Container>
              </div>
            </Grid>
          </div>
        </div>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      {authState === constants.UNAUTHENTICATED && (
        <div style={{ color: "white" }}>Authenticating...</div>
      )}
      {authState === constants.SIGNIN_FAILURE && (
        <div style={{ color: "white" }}>Logging failed</div>
      )}
      {authState === constants.AUTHENTICATED &&
        useCognito &&
        authzReducer.authz === 0 && (
          <div style={{ color: "white" }}>Verifying authorization...</div>
        )}
      {authState === constants.AUTHENTICATED &&
        useCognito &&
        authzReducer.authz === -1 && (
          <div style={{ color: "white" }}>Sorry, you are not authorized.</div>
        )}
      {/* {authState === constants.AUTHENTICATED &&
        (!useCognito || authzReducer.authz === 1) && <MainRouter />} */}
      {authState === constants.AUTHENTICATED &&
        (!useCognito || authzReducer.authz === 1) && <div>main router</div>}
    </React.Fragment>
  );
};

export default AuthControl;
