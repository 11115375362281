import { combineReducers } from "redux";
import authnReducer from "./reducers/authn-reducer";
import authzReducer from "./reducers/authz-reducer";
import { testcasesReducer } from "./reducers/testcases-reducer";
import {
  labAssignmentReducer,
  updateAssignmentReducer,
  assignmentTestCategoriesReducer,
} from "./reducers/assignement-reducer";
import requestFormReducer from "./reducers/request-form-reducer";
import requestStatusReducer from "./reducers/request-status-reducer";
import infoProjectBuildReducer from "./reducers/project-build-info-reducer";

//reducer list
const reducers = {
  authnReducer,
  authzReducer,
  testcasesReducer,
  labAssignmentReducer,
  assignmentTestCategoriesReducer,
  updateAssignmentReducer,
  requestFormReducer,
  requestStatusReducer,
  infoProjectBuildReducer,
};

// combine all reducers to rootReducer
const rootReducer = combineReducers(reducers);

export default rootReducer;
