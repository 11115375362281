import React from "react";
import { NavLink } from "react-router-dom";
// import { PROGRESS_COLOR } from "../../../config/constants";

export const defaultPageSize = 10;

// const listStatus = [
//   "Waiting for Shipping",
//   "Shipped Devices Received",
//   "Under Testing",
//   "Testing Done",
//   "To Be Shipping Out",
//   "Shipped Out",
// ];

const sort = (sortState, id, header) => {
  const isColumnSorted = sortState.sortingColumn === id;
  const ascending = !sortState.sortingDescending;
  return `${header}, ${
    isColumnSorted
      ? `sorted ${ascending ? "ascending" : "descending"}`
      : "not sorted"
  }.`;
};

// define columns, table mode
export const columnDefinitions = [
  {
    id: "request_id",
    header: "Request Id",
    cell: (item) => item.request_id,
    minWidth: "130px",
    label: (sortState) => {
      return sort(sortState, "request_id", "Request Id");
    },
    visible: true,
  },
  {
    id: "assignment_id",
    header: "Assign Id",
    // cell: (item) => item.assignment_id,
    cell: (item) => getNavLink(item, item.assignment_id),
    minWidth: "120px",
    label: (sortState) => {
      return sort(sortState, "assignment_id", "Assignment Id");
    },
    visible: true,
  },
  {
    id: "device_name",
    header: "Device Name",
    cell: (item) => `${item.project}/${item.build}/${item.version}`,
    minWidth: "120px",
    visible: true,
  },
  {
    id: "device_id",
    header: "Device Id",
    cell: (item) => item.dsn,
    minWidth: "120px",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "dsn";
      const ascending = !sortState.sortingDescending;
      return `Device Id, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    visible: true,
  },
  {
    id: "config",
    header: "Config",
    cell: (item) => item.config,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "firmware",
    header: "Firmware",
    cell: (item) => item.firmware,
    minWidth: "120px",
    visible: true,
  },
  {
    id: "lab",
    header: "Lab",
    cell: (item) => item.lab,
    minWidth: "120px",
    visible: true,
  },
  {
    id: "create_time",
    header: "Create Time",
    cell: (e) => e.create_time,
    label: (sortState) => {
      return sort(sortState, "create_time", "Create Time");
    },
    minWidth: "200px",
    visible: true,
  },
  {
    id: "update_time",
    header: "Update Time",
    cell: (e) => e.update_time,
    label: (sortState) => {
      return sort(sortState, "update_time", "Update Time");
    },
    minWidth: "200px",
    visible: true,
  },
  {
    id: "assignment_status",
    header: "Assignment Status",
    cell: (item) => item.assignment_status,
    minWidth: "220px",
    visible: true,
  },
];

// define default sorting column
export const defaultSortingColumn = "create_time";

// define sortable columns
export const sortableColumns = [
  // { id: "assignment_id", field: "Assignment Id" },
  // { id: "request_id", field: "Request Id" },
  { id: "create_time", field: "create_time" },
  { id: "update_time", field: "update_time" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "request_id", label: "Request Id", visible: true, editable: false },
      {
        id: "assignment_id",
        label: "Assignment Id",
        visible: true,
      },
      { id: "create_time", label: "Create Time", visible: true },
      { id: "update_time", label: "Update Time", visible: true },
      {
        id: "device_name",
        label: "Device Name",
        visible: true,
      },
      { id: "device_id", label: "Device Id", visible: true, editable: false },
      {
        id: "config",
        label: "Config",
        visible: true,
      },
      {
        id: "lab",
        label: "Lab",
        visible: true,
      },
      {
        id: "firmware",
        label: "Firmware",
        visible: true,
      },
      {
        id: "assignment_status",
        label: "Assignment Status",
        visible: true,
      },
    ],
  },
];

// define filtering options
export const filteringOptions = [];

const getNavLink = (item, text) => {
  return (
    <NavLink
      to={`/${item.assignment_id}/${item.dsn}/${item.lab}/request-detail`}
    >
      {text}
    </NavLink>
  );
};
