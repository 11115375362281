import { NavLink } from "react-router-dom";
import { PROGRESS_COLOR } from "../../../../config/constants";
import { getJiraLink } from "../../../../components/polaris/table/nav-link";

export const defaultPageSize = 20;

const sort = (sortState, id, header) => {
  const isColumnSorted = sortState.sortingColumn === id;
  const ascending = !sortState.sortingDescending;
  return `${header}, ${
    isColumnSorted
      ? `sorted ${ascending ? "ascending" : "descending"}`
      : "not sorted"
  }.`;
};

// define columns
export const columnDefinitions = [
  {
    id: "assignment_test_category_id",
    header: "Test Category Id",
    cell: (e) => (
      <NavLink
        to={`/third-party-lab/assignments/${e.assignment_id}/test_categories/${e.assignment_test_category_id}`}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          {e.assignment_test_category_id}
        </div>
      </NavLink>
    ),
    label: (sortState) => {
      return sort(sortState, "assignment_test_category_id", "Test Category Id");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "test_category",
    header: "Test Category",
    cell: (e) => e.test_category,
    label: (sortState) => {
      return sort(sortState, "test_category", "Test Category");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "status",
    header: "Status",
    cell: (item) => (
      <div
        style={{
          color: PROGRESS_COLOR[item.status]
            ? PROGRESS_COLOR[item.status]
            : PROGRESS_COLOR.untested,
          fontWeight: 600,
        }}
      >
        {item.status}
      </div>
    ),
    label: (sortState) => {
      return sort(sortState, "status", "Status");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "total_test_cases",
    header: "Total Test Cases",
    cell: (e) => e.total_test_cases,
    label: (sortState) => {
      return sort(sortState, "total_test_cases", "Total Test Cases");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "tested_test_cases",
    header: "Tested Test Cases",
    cell: (e) => e.tested_test_cases,
    label: (sortState) => {
      return sort(sortState, "tested_test_cases", "Tested Test Cases");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "untested",
    header: "Untested Test Cases",
    cell: (e) => e.untested,
    label: (sortState) => {
      return sort(sortState, "untested", "Untested Test Cases");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "passed",
    header: "Case Passed",
    cell: (item) => (
      <div style={{ color: PROGRESS_COLOR.pass, fontWeight: 600 }}>
        {item.passed}
      </div>
    ),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "failed",
    header: "Case Failed",
    cell: (item) => (
      <div style={{ color: PROGRESS_COLOR.fail, fontWeight: 600 }}>
        {item.failed}
      </div>
    ),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "warning",
    header: "Case Warning",
    cell: (item) => (
      <div style={{ color: PROGRESS_COLOR.warning, fontWeight: 600 }}>
        {item.warning}
      </div>
    ),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "jira",
    header: "Upload Jira",
    cell: (item) => getJiraLink(item, "link"),
    minWidth: "100px",
    visible: true,
  },
];

// define default sorting column
export const defaultSortingColumn = "untested";

// define sortable columns
export const sortableColumns = [
  { id: "assignment_test_category_id", field: "assignment_test_category_id" },
  { id: "test_category", field: "test_category" },
  { id: "status", field: "status" },
  { id: "total_test_cases", field: "total_test_cases" },
  { id: "tested_test_cases", field: "tested_test_cases" },
  { id: "untested", field: "untested" },
  { id: "passed", field: "passed" },
  { id: "failed", field: "failed" },
  { id: "warning", field: "warning" },
  { id: "jira", field: "jira" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      {
        id: "assignment_test_category_id",
        label: "Test Category Id",
        visible: true,
      },
      { id: "test_category", label: "Test Category", visible: true },
      { id: "status", label: "Status", visible: true },
      { id: "total_test_cases", label: "Total Test Cases", visible: true },
      { id: "tested_test_cases", label: "Tested Test Cases", visible: true },
      { id: "untested", label: "Untested Test Cases", visible: true },
      { id: "passed", label: "Passed", visible: true },
      { id: "failed", label: "Failed", visible: true },
      { id: "warning", label: "Warning", visible: true },
      { id: "jira", label: "jira", visible: true },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    propertyKey: "assignment_test_category_id",
    propertyLabel: "Test Category Id",
    groupValuesLabel: "Test Category Id",
    values: [],
  },
  {
    propertyKey: "test_category",
    propertyLabel: "Test Category",
    groupValuesLabel: "Test Category",
    values: [],
  },
  {
    propertyKey: "status",
    propertyLabel: "Status",
    groupValuesLabel: "Status",
    values: [],
  },
  {
    propertyKey: "total_test_cases",
    propertyLabel: "Total Test Cases",
    groupValuesLabel: "Total Test Cases",
    values: [],
  },
  {
    propertyKey: "tested_test_cases",
    propertyLabel: "Tested Test Cases",
    groupValuesLabel: "Tested Test Cases",
    values: [],
  },
  {
    propertyKey: "untested",
    propertyLabel: "Untested Test Cases",
    groupValuesLabel: "Untested Test Cases",
    values: [],
  },
  {
    propertyKey: "passed",
    propertyLabel: "Passed",
    groupValuesLabel: "Passed",
    values: [],
  },
  {
    propertyKey: "failed",
    propertyLabel: "Failed",
    groupValuesLabel: "Failed",
    values: [],
  },
  {
    propertyKey: "warning",
    propertyLabel: "Warning",
    groupValuesLabel: "Warning",
    values: [],
  },
  {
    propertyKey: "jira",
    propertyLabel: "jira",
    groupValuesLabel: "jira",
    values: [],
  },
];

export const features = ["propertyFiltering", "pagination", "sorting"];
