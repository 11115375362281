const requestFormType = {
  // REQUEST_FORM call
  CREATE_REQUEST_FORM_CALL: "CREATE_REQUEST_FORM_CALL",
  CREATE_REQUEST_FORM_CALL_COMMIT: "CREATE_REQUEST_FORM_CALL_COMMIT",
  CREATE_REQUEST_FORM_CALL_ROLLBACK: "CREATE_REQUEST_FORM_CALL_ROLLBACK",

  //set REQUEST_FORM
  SET_REQUEST_FORM: "SET_REQUEST_FORM",

  //set REQUEST_FORM
  SET_LAB_LIST: "SET_LAB_LIST",

  // set master test plan rules
  SET_MASTER_TEST_PLAN: "SET_MASTER_TEST_PLAN",

  //set REQUEST_FORM
  SET_USER_SELECT_FORM: "SET_USER_SELECT_FORM",
};

export default requestFormType;
