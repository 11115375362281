export const menuItems = [];

export const helpItem = {
  text: "Help & Support",
  type: "dropdown",
  options: [
    {
      text: "Support JIRA",
      href: "https://issues.labcollab.net/secure/Dashboard.jspa?selectPageId=63074",
      useATag: true,
      newTab: true,
    },
    {
      text: "Need Help",
      href: "https://sim.amazon.com/issues/create?template=94f5b1b7-59a8-4195-bdc9-15debfda57dc",
      useATag: true,
      newTab: true,
    },
  ],
};

export const analyticsItem = {
  text: "Analytics",
  href: "/analytics",
};

export const getUserItem = (username) => ({
  text: username,
  type: "dropdown",
  options: [
    {
      text: "Sign Out",
      href: "/signout",
    },
  ],
});
