import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
// import Button from "@amzn/awsui-components-react/polaris/button";
import Layout from "../layout/layout";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import RequestStatusTable from "./general/status-table";
import { getRequestStatus } from "../../redux/actions/request-status-action";
import { Breadcrumb } from "./request-status-config";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { username } from "../../authentication/cognito-auth";

interface StateProps {
  requestStatusReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<any>;

export interface RequestStatusState {}

class RequestStatus extends Component<Props> {
  // state = { :  }
  componentDidMount() {
    this._getStatusData();
  }

  _getStatusData = () => {
    this.props.dispatch(getRequestStatus(username));
  };

  render() {
    const { requestStatus, requestStatusLoading } =
      this.props.requestStatusReducer;
    // const history = useHistory();

    return (
      <Layout>
        <BreadcrumbGroup
          items={Breadcrumb}
          onFollow={(e) => {
            e.preventDefault();
            this.props.history.push(e.detail.href);
          }}
        />
        <br />
        <div>
          <RequestStatusTable
            data={
              requestStatus && Array.isArray(requestStatus) ? requestStatus : []
            }
            loadingStatus={requestStatusLoading}
            getStatusData={this._getStatusData}
          />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestStatusReducer: state.requestStatusReducer,
  };
};
export default withRouter(connect<StateProps>(mapStateToProps)(RequestStatus));
