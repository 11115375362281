import { Icon } from "@amzn/awsui-components-react/polaris";

export const getJiraLink = (item, text) => {
  if (!item || !item.jira || item.jira.length === 0) {
    return <div>No Jira</div>;
  }
  return (
    <a href={`${item.jira}`} target="_blank" rel="noreferrer">
      {text} <Icon name="external" size="normal" variant="link" />
    </a>
  );
};
