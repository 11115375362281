// general constants
const constants = {
  INITIATIVE: "engineering",

  NA: "N/A",
  NULL: "Null",

  SIGNIN: "signIn",
  UNAUTHENTICATED: "unauthenticated",
  AUTHENTICATING: "authenticating",
  AUTHENTICATED: "authenticated",
  SIGNIN_FAILURE: "signIn_failure",
  SIGNOUT: "signOut",
  CUSTOM_OAUTH_STATE: "customOAuthState",

  SESSION_EXPIRED: "session_expired",
  SESSION_WILL_EXPIRE: "session_will_expire",
  SESSION_TIMER_INTERVAL: 30000,

  IDP_AMAZON_FEDERATE: "AmazonFederate",
  IDP_LASSO: "LASSO",

  WEBSITE_PATH_COGNITO: "/home",

  // loading status
  LOADING_LOAD: 0,
  LOADING_SUCCESS: 1,
  LOADING_FAIL: -1,
  LOADING_BEFORE_START: 10,

  SELECT_STATUS_TYPE_LOADING: "loading" as "loading",

  DEFAULT_DATE_FORMAT: "YYYY-MM-DD",
  DEFAULT_DATETIME_FORMAT: "YYYY-MM-DD hh:mm:ss",

  SIM_TICKET_BASE_URL: "https://sim.amazon.com/issues/",
  JIRA_ISSUE_BASE_URL: "https://issues.labcollab.net/browse/",

  UI_TYPE_INPUT: "input",
  UI_TYPE_SELECT: "select",
  UI_TYPE_MULTI_SELECT: "multiselect",
  UI_TYPE_TOGGLE: "toggle",

  DSN_KEY: "dsn",
  REQUEST_FORM_ASSIGN_KEY: "assignments",
  REQUEST_FORM_TEST_PLAN_KEY: "test_plan",

  ASSIGN_LAB_KEY: "lab_id",
  ASSIGN_DSN_KEY: "dsn_list",

  TEST_PLAN_COUNTRY: "countries",
  TEST_PLAN_TECH: "technologies",

  TEST_PLAN_RVR_TESTITEM: "TestitemRvR",
  TEST_PLAN_DESENSE_TESTITEM: "TestitemDesense",
  TEST_PLAN_PASSIVE_TESTITEM: "passive",
  TEST_PLAN_PSEUDO_ACTIVE_TESTITEM: "active-pseudo-active",
  TEST_PLAN_SIGNALING_TIS_TESTITEM: "active-TIS",
  TEST_PLAN_SIGNALING_TRP_TESTITEM: "active-TRP",

  // a combine key used to flat and format testplan
  TEST_COMBINE_KEY: "_",
  RVR_CHANNEL_KEY: "channel",
  RVR_LINK_KEY: "link",
  RVR_WIFI_ONLY: "Wifi_Only",
  CHANNEL_COMBINE_SEPARATOR: ",",

  TEST_CATEGORY_KEY_RVR: "rvr",
  TEST_CATEGORY_KEY_COMPLIANCE: "compliance",
  TEST_CATEGORY_KEY_DESENSE: "desense",
  TEST_CATEGORY_KEY_ANTENNA: "antenna",

  TEST_CATEGORY_KEY_PSEUDO_ACTIVE: "pseudo_active",
  TEST_CATEGORY_KEY_SIGNALING_TIS: "signaling_tis",
  TEST_CATEGORY_KEY_SIGNALING_TRP: "signaling_trp",
  TEST_CATEGORY_KEY_PASSIVE: "passive",

  COMPLIANCE_VALID_KEY_ORDER: ["countries", "mode", "bandwidth", "antenna"],

  UPLOAD_CHECK_FILE_SUCCESS: "succeed",
  UPLOAD_CHECK_FILE_FAIL: "failed",
  COMBINATIONS_SUFFIX: "-combinations",
  ADD_SUFFIX: "-add",

  AUTH_LAB_KEY: "lab",
  AUTH_TENANT_KEY: "tenant",

  // keys for reducer params
  AUTH_PERMISSION_KEY: "permission",
  AUTH_USERTYPE_KEY: "userType",
  AUTH_USER_LAB_KEY: "userLab",
};
export default constants;

export const AUTH_USER_TYPE = {
  vendor: "vendor",
  tenant: "tenant",
};

export const PERMISSION_ROLE = {
  vendor: "admin",
  tenant: "user",
};

// upload status
export const UPLOAD_STATUS = {
  SUCCESS: "Success",
  FAIL: "Fail",
  IN_PROGRESS: "In Progress",
  NOT_STARTED: "Not Started",
};

// User metrics
export const METRIC_INFO = {
  INITIATIVE: "WaaS",

  LANDING: "Landing Page",
  CREATE_REQUEST_FORM: "Create request form",
  REQUEST_STATUS: "Request status",
  REQUEST_DETAIL_STATUS: "Request detail status",
  CATEGORY: "Categories",
  PROJECT: "Projects",
  BUILD: "Build Stage",
  PROJECT_BUILD_INFO: "Query project build info",
  PROJECT_BUILD_INFO_FAIL: "Query project build info fail",
  THIRD_PARTY_LAB_GET_TESTCASE: "Third party lab get testcases",
  METRIC: "Metric",
  AUTHORIZATION: "Authorization",
  AUTHORIZATION_FAIL: "Authorization Deny",
  UPLOAD: "Upload",
};

export const USER_REQUEST_METRICS_STATUS = {
  SUCCESS: "Success",
  FAIL: "Fail",
};
// user metrics status
export const METRICS_STATUS = {
  PASS: "PASS",
  FAIL: "FAIL",
};

// TESTCASE_FORM_UI_TYPE refer
export const TESTCASE_FORM_UI_TYPE = {};

export const ASSIGNMENT_STATUS = [
  "Waiting for Shipping",
  "Shipped Devices Received",
  "Under Testing",
  "Testing Done",
  "To Be Shipping Out",
  "Shipped Out",
];
// naming placeholder and label info
export const DROPDOWN_NAMING_COLLECTION = {
  project: "Project",
  build: "Build",
  version: "Version",
  dsn: "Device ID(DSN)",
  lab: "Labs Selection",
  config: "Config",
  firmware: "Firmware",
};

// clean dropdown lookup object
export const CLEAN_DROPDOWN_DICT = {
  project: "project",
  build: "build",
  version: "version",
  // build_id: "build_id",
};

export const TEST_CATEGORY_NAME_MAP = {
  compliance: "Compliance",
  rvr: "RvR",
  desense: "Desense",
  antenna: "Antenna",
};

export const TEST_TYPE_MAP = {
  rvr: "RvR",
  desense: "Desense",
  antenna: "Antenna",
  power_table: "Power Table",
  active_scan: "Active Scan",
  band_edge: "Band Edge",
  harmonics: "Harmonics",
  passive: "Passive",
  "active-pseudo-active": "Active Pseudo-Active",
  "active-TRP": "Active TRP",
  "active-TIS": "Active TIS",
};

// for antenna pseudo active
export const TEST_PLAN_PSEUDO_ACTIVE_KEYS = {
  technologies: "technologies",
  channel: "channel",
  set_power: "set_power",
  antenna: "antenna",
  type: "type",
};

// for antenna passive
export const TEST_PLAN_PASSIVE_KEYS = {
  frequency: "frequency",
  antenna: "antenna",
};

// for antenna active signaling
export const TEST_PLAN_SIGNALING_KEYS = {
  technologies: "technologies",
  channel: "channel",
};

export const UI_MAP_LOOKUP = {
  uiType: "ui_type",
  valid: "valid",
  optionValues: "optionValues",
  friednlyName: "friednly_name",
  defaultValue: "default_value",
  hideKey: "hideKey",
  showCombinations: "show_combinations",
  customAddPlan: "custom_add_plan",
  horizontalLine: "horizontal_line",
};

export const TEST_PLAN_SUBMIT_CHECK_LIST = [
  "username",
  "test_plan",
  "assignments",
  "build",
  "version",
  "build_id",
  "project",
  "config",
];

export const PROGRESS_COLOR = {
  pass: "var(--test-status-passed)", //pass - green
  fail: "var(--test-status-failed)", //fail - red
  warning: "var(--test-status-warning)", //warning - yellow
  untested: "var(--test-status-untested)", //untested - grey
  no_limit: "var(--test-status-no-limit)", // no_limit

  // detail status add
  testing: "var(--test-status-warning)", // yellow
  done: "var(--test-status-passed)", // green
};

// User specific requested: (may never change later based on usecase)
// based on service side lookup table, valid plan for compliance
export const COMPLIANCE_VALID_LIST = ["power_table", "band_edge", "harmonics"];
