import infoProjectBuildType from "../types/project-build-info-type";
import endpoints from "../../config/endpoints";
import { config } from "../../config/config";
import {
  METRIC_INFO,
  USER_REQUEST_METRICS_STATUS,
} from "../../config/constants";

export const getProjectBuildInfo = () => ({
  type: infoProjectBuildType.PROJECT_BUILD_INFO_CALL,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.ENGINEERING_URL + endpoints.getProjectBuildInfo(),
        method: "GET",
        // json: { username: username, bindle_type: bindleType },
      },
      commit: {
        type: infoProjectBuildType.PROJECT_BUILD_INFO_CALL_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.PROJECT_BUILD_INFO,
          status: USER_REQUEST_METRICS_STATUS.SUCCESS,
        },
      },
      rollback: {
        type: infoProjectBuildType.PROJECT_BUILD_INFO_CALL_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.PROJECT_BUILD_INFO,
          status: USER_REQUEST_METRICS_STATUS.FAIL,
        },
      },
    },
  },
});
