import infoProjectBuildType from "../types/project-build-info-type";
import constants from "../../config/constants";

const initialState = {
  infoLoadingStatus: constants.LOADING_LOAD,
  infoCollection: {},
  infoMessage: "",
};

const infoProjectBuildReducer = (state = initialState, action) => {
  switch (action.type) {
    case infoProjectBuildType.PROJECT_BUILD_INFO_CALL:
      return {
        ...state,
        infoLoadingStatus: constants.LOADING_LOAD,
      };
    case infoProjectBuildType.PROJECT_BUILD_INFO_CALL_COMMIT:
      return {
        ...state,
        infoLoadingStatus: constants.LOADING_SUCCESS,
        infoCollection: action.payload.project_build,
      };
    case infoProjectBuildType.PROJECT_BUILD_INFO_CALL_ROLLBACK:
      return {
        ...state,
        infoLoadingStatus: constants.LOADING_FAIL,
        infoMessage: action.payload.message,
      };

    default:
      return state;
  }
};

export default infoProjectBuildReducer;
