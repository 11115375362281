import React, { Component } from "react";
import { Toggle, FormField } from "@amzn/awsui-components-react/polaris";
// import { capitalizeFirstLetter } from "../../../../utils/general-utils";

interface StateProps {}

// declare prop check
type Props = {
  friendlyName: string;
  helpText: string;
  fieldValue: string;
  handleOnChange: (e: any) => void;
} & StateProps;

class ToggleSelectField extends Component<Props> {
  render() {
    const { friendlyName, fieldValue, handleOnChange } = this.props;

    return (
      <div className="test-plan-field-multi-select">
        <FormField
          label={friendlyName !== "" && `${friendlyName} :`}
          stretch={true}
        >
          <div className="test-plan-field-element">
            <Toggle
              onChange={handleOnChange}
              checked={fieldValue === "true" ? true : false}
            >
              {/* {friendlyName !== "" ? friendlyName : helpText} */}
            </Toggle>
          </div>
        </FormField>
      </div>
    );
  }
}

export default ToggleSelectField;
