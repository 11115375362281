import { Component } from "react";
import Layout from "../layout/layout";
import Button from "@amzn/awsui-components-react/polaris/button";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import { connect } from "react-redux";
import { Breadcrumb, getCurrentLabData } from "./third-party-lab-config";
import Container from "@amzn/awsui-components-react-v3/polaris/container/internal";
import {
  Alert,
  FormField,
  Header,
  Modal,
  Select,
  Spinner,
} from "@amzn/awsui-components-react-v3/polaris";
import { SpaceBetween } from "@amzn/awsui-components-react-v3";
import AssignmentTable from "./assignment-table/table";
import constants, { AUTH_USER_TYPE } from "../../config/constants";
import { graphqlCall } from "../../utils/graphql-api";
import { Dispatch } from "redux";
import {
  resetLabAssignments,
  getLabAssignments,
  resetUpdateAssignments,
} from "../../redux/actions/assignment-action";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface StateProps {}

const defaultProps = Object.freeze({});

type Props = {
  dispatch: Dispatch<any>;
  labAssignmentReducer: any;
  updateAssignmentReducer: any;
} & typeof defaultProps &
  StateProps &
  RouteComponentProps<any>;

type State = {
  labError: string;
  selectedLab: any;
  labsLoadingStatus: number;
  currentLab: number;
  labs: Array<any>;
};

class ThirdPartyLab extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  state = {
    labError: "",
    selectedLab: {},
    labsLoadingStatus: constants.LOADING_LOAD,
    labs: [],
    currentLab: 0,
  };
  _onChangeLab = (event: any) => {
    this.setState({ selectedLab: event.detail.selectedOption });
  };
  _onSubmit = () => {
    this.setState({ currentLab: Number(this.state.selectedLab["id"]) });
    this.getAssignData();
  };

  getAssignData = () => {
    if (this.state.selectedLab && this.state.selectedLab["id"]) {
      this.props.dispatch(
        getLabAssignments(Number(this.state.selectedLab["id"]))
      );
    }
  };

  componentDidMount() {
    this.props.dispatch(resetLabAssignments());
    const { permission } = this.props.authzReducer;
    const preAssignUserLab = permission
      ? permission[constants.AUTH_USER_LAB_KEY]
      : null;

    // 1) get lab list
    const labsQuery = `query {
      labs {
        id
        name
      }
    }`;
    graphqlCall(labsQuery).then(({ json, ok }) => {
      if (ok && json.labs) {
        let labs: Array<any> = [];
        json.labs.forEach((lab) => {
          labs.push({
            id: lab.id,
            name: lab.name,
            label: lab.name,
            value: lab.id,
          });
        });

        const preSelectLab = getCurrentLabData(preAssignUserLab, labs);
        this.setState({
          labs: labs,
          labsLoadingStatus: constants.LOADING_SUCCESS,
          selectedLab: preSelectLab,
        });
        if (preSelectLab && Object.keys(preSelectLab).length !== 0) {
          this._onSubmit();
        }
        // update labinfo after graphqlcall
      } else {
        this.setState({
          labsLoadingStatus: constants.LOADING_FAIL,
        });
      }
    });
  }

  display = () => {
    if (
      this.props.updateAssignmentReducer.loadingStatus ===
      constants.LOADING_LOAD
    ) {
      return (
        <Modal visible={true} size="small" header="Loading">
          <Spinner size="normal" />
        </Modal>
      );
    } else if (
      this.props.updateAssignmentReducer.loadingStatus ===
      constants.LOADING_FAIL
    ) {
      return (
        <Modal
          visible={true}
          size="small"
          header="Failure"
          onDismiss={() => this.props.dispatch(resetUpdateAssignments())}
        >
          "Failure to load. Please try again"
        </Modal>
      );
    } else if (this.state.labsLoadingStatus === constants.LOADING_LOAD) {
      return <Spinner size="large" />;
    } else if (this.state.labsLoadingStatus === constants.LOADING_FAIL) {
      return (
        <Alert
          visible={true}
          type="error"
          header="Failure to load. Please try again"
        ></Alert>
      );
    } else if (this.state.labsLoadingStatus === constants.LOADING_SUCCESS) {
      return (
        <>
          {this.props.authzReducer.permission[constants.AUTH_USERTYPE_KEY] ===
          AUTH_USER_TYPE.vendor ? (
            <div>
              Lab Info:
              <Select
                options={this.state.labs}
                onChange={this._onChangeLab}
                selectedOption={this.state.selectedLab}
                disabled
              ></Select>
            </div>
          ) : (
            <div>
              <SpaceBetween direction="vertical" size="xs">
                <FormField
                  label="Select Lab"
                  errorText={this.state.labError}
                  description="* Required"
                >
                  <Select
                    options={this.state.labs}
                    onChange={this._onChangeLab}
                    selectedOption={this.state.selectedLab}
                  ></Select>
                </FormField>
                <Button variant="primary" onClick={this._onSubmit}>
                  Submit
                </Button>
              </SpaceBetween>
            </div>
          )}

          <br />
          {this.props.labAssignmentReducer.loadingStatus !==
            constants.LOADING_BEFORE_START && (
            <AssignmentTable
              getAssignData={this.getAssignData}
              dispatch={this.props.dispatch}
              data={this.props.labAssignmentReducer.data}
              loadingStatus={this.props.labAssignmentReducer.loadingStatus}
              lab={this.state.currentLab}
            />
          )}
        </>
      );
    }
  };
  render() {
    return (
      <Layout>
        <BreadcrumbGroup
          items={Breadcrumb}
          onFollow={(e) => {
            e.preventDefault();
            this.props.history.push(e.detail.href);
          }}
        />
        <Container header={<Header variant="h2">Third Party Lab View</Header>}>
          {this.display()}
        </Container>
      </Layout>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    labAssignmentReducer: state.labAssignmentReducer,
    updateAssignmentReducer: state.updateAssignmentReducer,
    authzReducer: state.authzReducer,
  };
};

export default withRouter(connect<StateProps>(mapStateToProps)(ThirdPartyLab));
