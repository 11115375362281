import requestFormType from "../types/request-form-type";
import constants from "../../config/constants";

const initialState = {
  requestFromLoadingStatus: constants.LOADING_LOAD,
  requestForm: [],

  labListLoadingStatus: constants.LOADING_LOAD,
  labList: [],

  lookupTestPlan: [],
  lookupTestPlanLoadingStatus: constants.LOADING_LOAD,

  createRequestLoadingStatus: constants.LOADING_BEFORE_START,
  createRequestMessage: "",

  // base template for creating request form
  userSelectForm: {
    username: "",
    [constants.REQUEST_FORM_TEST_PLAN_KEY]: {},
    [constants.REQUEST_FORM_ASSIGN_KEY]: [],
  },
};

const requestFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case requestFormType.SET_REQUEST_FORM:
      return {
        ...state,
        requestForm: action.requestForm,
      };
    case requestFormType.SET_LAB_LIST:
      return {
        ...state,
        labList: action.labList,
        labListLoadingStatus: constants.LOADING_SUCCESS,
      };
    case requestFormType.SET_MASTER_TEST_PLAN:
      return {
        ...state,
        lookupTestPlan: action.lookupTestPlan,
        lookupTestPlanLoadingStatus: constants.LOADING_SUCCESS,
      };
    case requestFormType.SET_USER_SELECT_FORM:
      return {
        ...state,
        userSelectForm: action.userSelectForm,
      };

    // Create Request
    case requestFormType.CREATE_REQUEST_FORM_CALL:
      return {
        ...state,
        createRequestLoadingStatus: constants.LOADING_LOAD,
      };
    case requestFormType.CREATE_REQUEST_FORM_CALL_COMMIT:
      return {
        ...state,
        createRequestLoadingStatus: constants.LOADING_SUCCESS,
        createRequestMessage: action.payload,
      };
    case requestFormType.CREATE_REQUEST_FORM_CALL_ROLLBACK:
      return {
        ...state,
        createRequestLoadingStatus: constants.LOADING_FAIL,
        createRequestMessage: action.payload.message,
      };

    default:
      return state;
  }
};

export default requestFormReducer;
