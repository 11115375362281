import { Component } from "react";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import TestCasesTable from "./testcases-table/table";
import { Breadcrumb } from "./testcases-config";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getTestCases } from "../../../redux/actions/testcases-action";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface StateProps {}

interface StateProps {
  testcasesReducer: any;
}

const initialState = Object.freeze({});

type Props = { dispatch: Dispatch<any> } & StateProps &
  RouteComponentProps<any>;
type State = typeof initialState;

class TestCases extends Component<Props, State> {
  readonly state = initialState;
  componentDidMount() {
    this.props.dispatch(
      getTestCases(this.props["match"]["params"]["test_category"])
    );
  }
  render() {
    return (
      <div className="waas-container">
        <BreadcrumbGroup
          items={Breadcrumb(
            this.props["match"]["params"]["assignment"],
            this.props["match"]["params"]["test_category"]
          )}
          onFollow={(e) => {
            e.preventDefault();
            this.props.history.push(e.detail.href);
          }}
        />
        <TestCasesTable
          data={this.props.testcasesReducer.data}
          headers={this.props.testcasesReducer.headers}
          loadingStatus={this.props.testcasesReducer.loadingStatus}
        ></TestCasesTable>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    testcasesReducer: state.testcasesReducer,
  };
};

export default withRouter(connect<StateProps>(mapStateToProps)(TestCases));
