import React from "react";
import Dropzone from "react-dropzone";
import { Button } from "@amzn/awsui-components-react/polaris";
import { getIcon, deleteButton } from "./file-list";
import styled from "styled-components";

const UploadDropzoneContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const UploadDropzone = styled.div`
  /* flex: 1; */
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const UploadFileDropzone = ({ uploadItems, onChange, deleteFile }) => {
  const onDrop = (files) => {
    onChange(files);
  };

  return (
    <section>
      <Dropzone onDrop={onDrop} noClick={true}>
        {({ getRootProps, getInputProps, open }) => {
          return (
            <UploadDropzoneContainer>
              <UploadDropzone {...getRootProps({})}>
                <input {...getInputProps()} />
                <i className="fas fa-cloud-upload-alt" style={{ fontSize: "30px" }}></i>
                <p style={{ fontSize: "18px" }}>Drag and drop files here</p>
                <br />
                <Button onClick={open} icon="upload" iconAlign="left">
                  Click to select File
                </Button>
              </UploadDropzone>
            </UploadDropzoneContainer>
          );
        }}
      </Dropzone>
      <aside>
        {uploadItems.length > 0 && (
          <React.Fragment>
            <span style={{ fontWeight: "bold", fontSize: "14px" }}>Files:</span>
            <ul>
              {uploadItems.map(({ file, message, fileCheckStatus }, index: number) => (
                <li key={index} style={{ fontSize: "14px" }}>
                  {file.name} - <i style={{ color: "grey" }}>{file.size} bytes</i>
                  &nbsp; &nbsp;
                  {deleteButton(deleteFile, file.name)}
                  <span style={{ marginLeft: "20px" }}>
                    {getIcon(
                      fileCheckStatus, // status
                      message // will show error msg when failed
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </React.Fragment>
        )}
      </aside>
    </section>
  );
};

export default UploadFileDropzone;
