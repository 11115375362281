import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { TokenGroup, Button } from "@amzn/awsui-components-react/polaris";
import constants from "../../../../config/constants";
import { rvrCombineKeyHandler, formatRvRRequestJson } from "./rvr-combine-util";
import { setUserRequestForm } from "../../../../redux/actions/request-form-action";
import RvRFinalCombineKeys from "./rvr-combine-key-final";
// import { capitalizeFirstLetter } from "../../../utils/general-utils";
import "./rvr-combine-key-style.css";

interface StateProps {
  requestFormReducer: any;
}

// declare prop check
type Props = { dispatch: Dispatch<any> } & StateProps;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  tokenItem: [],
});

// this add combine key component is unique for RvR compare with Desense and Compliance
class RvRCombineKeys extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  onClickConfirm = () => {
    const { tokenItem } = this.state;
    const { userSelectForm } = this.props.requestFormReducer;

    const formatTokenItems = tokenItem.map((item: any) => item.id);
    const testplan = userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY];

    const confirmedCombine = formatRvRRequestJson(testplan, formatTokenItems);
    this._setRvRCombineKeyToReducer(confirmedCombine);
  };

  onTokenDismiss = ({ detail: { itemIndex } }) => {
    const tokenList = [...this.state.tokenItem];
    if (itemIndex > -1) {
      tokenList.splice(itemIndex, 1);
    }
    this.setState({ tokenItem: tokenList });
  };

  _setRvRCombineKeyToReducer = (value) => {
    const { userSelectForm } = this.props.requestFormReducer;
    let updateSelected = { ...userSelectForm };
    if (
      constants.TEST_CATEGORY_KEY_RVR in
      updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY]
    ) {
      const existedRvRObj =
        updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][
          constants.TEST_CATEGORY_KEY_RVR
        ];
      // if exist
      updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][
        constants.TEST_CATEGORY_KEY_RVR
      ] = Object.assign(existedRvRObj, value);
    } else {
      // if not exist create one
      updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][
        constants.TEST_CATEGORY_KEY_RVR
      ] = value;
    }
    this.props.dispatch(setUserRequestForm(updateSelected));
  };

  _formatStateItems = () => {
    const { userSelectForm } = this.props.requestFormReducer;
    const formatItems = rvrCombineKeyHandler(
      userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY]
    );
    const tokenItem =
      formatItems !== undefined
        ? formatItems.map((each) => {
            return { label: each, id: each };
          })
        : ([] as any);
    this.setState({ tokenItem: tokenItem });
  };

  componentDidUpdate(prevProps) {
    const { userSelectForm } = this.props.requestFormReducer;
    if (
      userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY] !==
      prevProps.requestFormReducer.userSelectForm[
        constants.REQUEST_FORM_TEST_PLAN_KEY
      ]
    ) {
      this._formatStateItems();
    }
  }

  render() {
    const { tokenItem } = this.state;

    return (
      <div className={"rvr-combine-key-container"}>
        <div className={"rvr-combine-key-label"}>Wifi+ combine:</div>
        <div className={"rvr-combine-key-tokens"}>
          <TokenGroup onDismiss={this.onTokenDismiss} items={tokenItem} />
        </div>

        <div className={"rvr-confim-combine-btn"}>
          <Button onClick={this.onClickConfirm}>Add plan</Button>
        </div>

        <hr />
        <div className={"rvr-combine-key-label"}>Confirmed combine:</div>
        <div className={"rvr-combine-key-tokens"}>
          <RvRFinalCombineKeys />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(RvRCombineKeys);
