import { NavLink } from "react-router-dom";
import { ASSIGNMENT_STATUS } from "../../../config/constants";
import { Button } from "@amzn/awsui-components-react-v3";

export const defaultPageSize = 20;

const sort = (sortState, id, header) => {
  const isColumnSorted = sortState.sortingColumn === id;
  const ascending = !sortState.sortingDescending;
  return `${header}, ${
    isColumnSorted
      ? `sorted ${ascending ? "ascending" : "descending"}`
      : "not sorted"
  }.`;
};

const getNextStatus = (currentStatus) => {
  for (let i = 0; i < ASSIGNMENT_STATUS.length - 1; i++) {
    if (ASSIGNMENT_STATUS[i] === currentStatus) {
      // return the next status
      return ASSIGNMENT_STATUS[i + 1];
    }
  }
};

// define columns
export const columnDefinitions = (updateStatus) => [
  {
    id: "assignment_id",
    header: "Assignment Id",
    cell: (e) => (
      <NavLink to={`/third-party-lab/assignments/${e.assignment_id}`}>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          {e.assignment_id}
        </div>
      </NavLink>
    ),
    label: (sortState) => {
      return sort(sortState, "assignment_id", "Assignment Id");
    },
    minWidth: "100px",
    visible: true,
  },

  {
    id: "config",
    header: "Config",
    cell: (e) => e.config,
    label: (sortState) => {
      return sort(sortState, "config", "Config");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "dsn",
    header: "DSN",
    cell: (e) => e.dsn,
    label: (sortState) => {
      return sort(sortState, "dsn", "DSN");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "firmware",
    header: "Firmware",
    cell: (e) => e.firmware,
    label: (sortState) => {
      return sort(sortState, "firmware", "Firmware");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "assignment_status",
    header: "Current Status",
    cell: (e) => e.assignment_status,
    label: (sortState) => {
      return sort(sortState, "assignment_status", "Status");
    },
    minWidth: "100px",
    visible: true,
  },

  {
    id: "username",
    header: "User name",
    cell: (e) => e.username,
    label: (sortState) => {
      return sort(sortState, "username", "User name");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "create_time",
    header: "Create Time",
    cell: (e) => e.create_time,
    label: (sortState) => {
      return sort(sortState, "create_time", "Create Time");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "update_time",
    header: "Update Time",
    cell: (e) => e.update_time,
    label: (sortState) => {
      return sort(sortState, "update_time", "Update Time");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "update_status",
    header: "Update Status",
    cell: (e) =>
      getNextStatus(e.assignment_status) && (
        <Button
          variant="normal"
          onClick={() => updateStatus(e, getNextStatus(e.assignment_status))}
        >
          {getNextStatus(e.assignment_status)}
        </Button>
      ),
    label: (sortState) => {
      return sort(sortState, "assignment_status", "Status");
    },
    minWidth: "260px",
    visible: true,
  },
];

// define default sorting column
export const defaultSortingColumn = "update_time";

// define sortable columns
export const sortableColumns = [
  { id: "assignment_id", field: "assignment_id" },
  { id: "config", field: "config" },
  { id: "dsn", field: "dsn" },
  { id: "firmware", field: "firmware" },
  { id: "assignment_status", field: "assignment_status" },
  { id: "username", field: "username" },
  { id: "create_time", field: "create_time" },
  { id: "update_time", field: "update_time" },
  { id: "update_status", field: "update_status" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "assignment_id", label: "Assignment Id", visible: true },
      { id: "config", label: "Config", visible: true },
      { id: "dsn", label: "DSN", visible: true },
      { id: "firmware", label: "Firmware", visible: true },
      { id: "assignment_status", label: "Current Status", visible: true },
      { id: "username", label: "Username", visible: true },
      { id: "create_time", label: "Create Time", visible: true },
      { id: "update_time", label: "Update Time", visible: true },
      { id: "update_status", label: "Update Status", visible: true },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    propertyKey: "assignment_id",
    propertyLabel: "Assignment Id",
    groupValuesLabel: "Assignment Id",
    values: [],
  },
  {
    propertyKey: "config",
    propertyLabel: "Config",
    groupValuesLabel: "Config",
    values: [],
  },
  {
    propertyKey: "dsn",
    propertyLabel: "DSN",
    groupValuesLabel: "DSN",
    values: [],
  },
  {
    propertyKey: "firmware",
    propertyLabel: "Firmware",
    groupValuesLabel: "Firmware",
    values: [],
  },
  {
    propertyKey: "assignment_status",
    propertyLabel: "Current Status",
    groupValuesLabel: "Current Status",
    values: [],
  },

  {
    propertyKey: "username",
    propertyLabel: "Username",
    groupValuesLabel: "Username",
    values: [],
  },
  {
    propertyKey: "create_time",
    propertyLabel: "Create Time",
    groupValuesLabel: "Create Time",
    values: [],
  },
  {
    propertyKey: "update_time",
    propertyLabel: "Update Time",
    groupValuesLabel: "Update Time",
    values: [],
  },
  {
    propertyKey: "update_status",
    propertyLabel: "Update Status",
    groupValuesLabel: "Update Status",
    values: [],
  },
];

export const features = ["propertyFiltering", "pagination", "sorting"];
