import metricType from "../types/metric-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";

export const logMetric = (requestJson, status) => ({
  type: metricType.POST_METRIC_DATA,
  http: {
    cognito: {
      effect: {
        url: config.ENGINEERING_URL + endpoints.metricEndpoint(),
        method: "POST",
        json: requestJson,
        headers: {},
      },
      commit: {
        type: metricType.POST_METRIC_DATA_COMMIT,
      },
      rollback: {
        type: metricType.POST_METRIC_DATA_ROLLBACK,
      },
    },
  },
  status,
});
