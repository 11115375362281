import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { TokenGroup } from "@amzn/awsui-components-react/polaris";
import constants from "../../../../config/constants";
import { combineKeyHandler } from "../combine-addon/combine-util";
import { setUserRequestForm } from "../../../../redux/actions/request-form-action";

interface StateProps {
  requestFormReducer: any;
}

type Props = {
  dispatch: Dispatch<any>;
  requestFormKey: string;
} & StateProps;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  tokenItem: [],
  requestItem: [],
});

class AddGroup extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _setCombineKeyToReducer = () => {
    const { userSelectForm } = this.props.requestFormReducer;
    let updateSelected = { ...userSelectForm };
    const { requestItem } = this.state;
    updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][
      `${this.props.requestFormKey}${constants.COMBINATIONS_SUFFIX}`
    ] = requestItem;
    this.props.dispatch(setUserRequestForm(updateSelected));
  };

  _formatStateItems = () => {
    const { userSelectForm } = this.props.requestFormReducer;
    const testPlan = userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY];
    const formatItems = combineKeyHandler(testPlan, this.props.requestFormKey);

    const tokenItem =
      formatItems !== undefined
        ? formatItems.map((each) => {
            return { label: each, id: each };
          })
        : ([] as any);

    this.setState({ tokenItem: tokenItem, requestItem: formatItems }, () => {
      this._setCombineKeyToReducer();
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { userSelectForm } = this.props.requestFormReducer;
    if (
      userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY][
        `${this.props.requestFormKey}`
      ] !==
      prevProps.requestFormReducer.userSelectForm[
        constants.REQUEST_FORM_TEST_PLAN_KEY
      ][`${this.props.requestFormKey}`]
    ) {
      this._formatStateItems();
    }
  }

  render() {
    const testPlan =
      this.props.requestFormReducer.userSelectForm[
        constants.REQUEST_FORM_TEST_PLAN_KEY
      ];
    const finalItem =
      testPlan[`${this.props.requestFormKey}${constants.ADD_SUFFIX}`];
    const finalTokenItem =
      finalItem !== undefined
        ? finalItem.map((each) => {
            return { label: each, id: each };
          })
        : ([] as any);
    const { tokenItem } = this.state;

    return (
      <div>
        <div>Current Frequency Range:</div>
        <div>
          <TokenGroup items={tokenItem} />
        </div>
        <div>Final Frequency Range:</div>
        <div>
          <TokenGroup items={finalTokenItem} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(AddGroup);
