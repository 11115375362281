import React from "react";
import { CollectionPreferences, Link } from "@amzn/awsui-components-react-v3";

// define columns
export const COLUMN_DEFINITIONS = [
  {
    id: "id",
    header: "ID",
    sortingField: "id",
    cell: (item) => item.id,
    visible: false,
  },
  {
    id: "name",
    header: "Name",
    sortingField: "name",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "name";
      const ascending = !sortState.sortingDescending;
      return `Name, ${
        isColumnSorted ? `sorted ${ascending ? "ascending" : "descending"}` : "not sorted"
      }.`;
    },
    cell: (item) => item.name,
    visible: true,
  },
  {
    id: "file_type",
    header: "File Type",
    sortingField: "file_type",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "file_type";
      const ascending = !sortState.sortingDescending;
      return `File Type, ${
        isColumnSorted ? `sorted ${ascending ? "ascending" : "descending"}` : "not sorted"
      }.`;
    },
    cell: (item) => item.file_type,
    visible: true,
  },
  {
    id: "file_name_format",
    header: "File Name Format",
    sortingField: "file_name_format",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "file_name_format";
      const ascending = !sortState.sortingDescending;
      return `File Name Format, ${
        isColumnSorted ? `sorted ${ascending ? "ascending" : "descending"}` : "not sorted"
      }.`;
    },
    cell: (item) => item.file_name_format,
    visible: true,
  },
  {
    id: "example_file_url",
    header: "Example File",
    sortingField: "example_file_url",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "example_file_url";
      const ascending = !sortState.sortingDescending;
      return `Example File, ${
        isColumnSorted ? `sorted ${ascending ? "ascending" : "descending"}` : "not sorted"
      }.`;
    },
    cell: (item) => (
      <Link external externalIconAriaLabel="Opens in a new tab" href={item.example_file_url}>
        {item.example_file_url}
      </Link>
    ),
    visible: true,
  },
];

// define visiable columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: COLUMN_DEFINITIONS.map((column) => ({
      id: column.id,
      label: column.header,
      visible: column.visible,
      editable: column.id === "id" ? false : true,
    })),
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 5, label: "5 items" },
  { value: 10, label: "10 items" },
  { value: 20, label: "20 items" },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 5,
  visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map((column) => column.id),
  wraplines: false,
};

export const Preferences = ({ preferences, disabled, setPreferences }) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: PAGE_SIZE_OPTIONS,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible columns",
      options: VISIBLE_CONTENT_OPTIONS,
    }}
  />
);
