import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { TokenGroup } from "@amzn/awsui-components-react/polaris";
import constants from "../../../../config/constants";
import { combineKeyHandler } from "./combine-util";
import { setUserRequestForm } from "../../../../redux/actions/request-form-action";

interface StateProps {
  requestFormReducer: any;
}

type Props = {
  dispatch: Dispatch<any>;
  requestFormKey: string;
} & StateProps;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  tokenItem: [],
  requestItem: [],
});

class CombineKeys extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  onTokenDismiss = ({ detail: { itemIndex } }) => {
    const tokenList = [...this.state.tokenItem];
    const formatList = [...this.state.requestItem];
    if (itemIndex > -1) {
      tokenList.splice(itemIndex, 1);
      formatList.splice(itemIndex, 1);
    }
    this.setState({ tokenItem: tokenList, requestItem: formatList }, () => {
      this._setCombineKeyToReducer();
    });
  };

  _setCombineKeyToReducer = () => {
    const { userSelectForm } = this.props.requestFormReducer;
    let updateSelected = { ...userSelectForm };
    const { requestItem } = this.state;
    updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][
      `${this.props.requestFormKey}${constants.COMBINATIONS_SUFFIX}`
    ] = requestItem;
    this.props.dispatch(setUserRequestForm(updateSelected));
  };

  _formatStateItems = () => {
    const { userSelectForm } = this.props.requestFormReducer;
    const formatItems = combineKeyHandler(
      userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY],
      this.props.requestFormKey
    );
    const tokenItem =
      formatItems !== undefined
        ? formatItems.map((each) => {
            return { label: each, id: each };
          })
        : ([] as any);
    this.setState({ tokenItem: tokenItem, requestItem: formatItems }, () => {
      this._setCombineKeyToReducer();
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { userSelectForm } = this.props.requestFormReducer;
    if (
      userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY][
        this.props.requestFormKey
      ] !==
      prevProps.requestFormReducer.userSelectForm[
        constants.REQUEST_FORM_TEST_PLAN_KEY
      ][this.props.requestFormKey]
    ) {
      this._formatStateItems();
    }
  }

  render() {
    const { tokenItem } = this.state;
    return (
      <div>
        <div>Combine keys:</div>
        <div>
          <TokenGroup onDismiss={this.onTokenDismiss} items={tokenItem} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(CombineKeys);
