import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import InputField from "./input-field";
import MultiSelectField from "./multi-select-field";
import SelectField from "./select-field";
import ToggleSelectField from "./toggle-field";
import constants from "../../../../config/constants";
import { mergeTestPlan, removeEmpty } from "../test-plan-utils";
import { setUserRequestForm } from "../../../../redux/actions/request-form-action";

interface StateProps {
  requestFormReducer: any
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  testType: string;
  fieldName: string;
  friendlyName: string;
  uiType: string;
  selectListContains: Array<any>;
  testDetailIndex: any;
  fieldProps: object;
} & StateProps;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({});

class RootField extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  // Why 4 kinds of handlers here? => diff UI type read diff value
  // each UI type has it's own way to get 'currentValue'
  handleToggleOnChange = (event: any) => {
    const { fieldName } = this.props;
    const currentValue = event.detail.checked;

    this._setToReducer(fieldName, currentValue);
  };

  handleInputOnChange = (event: any) => {
    const key = event.target.id;
    const currentValue = event.detail.value;

    this._setToReducer(key, currentValue);
  };

  handleSelectOnChange = (event: any) => {
    // const { testType } = this.props;
    const key = event.target.id;
    const currentValue = event.detail.selectedId;

    this._setToReducer(key, currentValue);
  };

  handleMultiSelectOnChange = (event: any) => {
    // user select
    const key = event.target.id;
    const currentValue = event.detail.selectedIds;

    // empty check
    if (currentValue.length === 0) {
      this._setToReducer(key, null);
    } else {
      this._setToReducer(key, currentValue);
    }
  };

  _setToReducer = (key: any, value: any) => {
    const { testType } = this.props;
    const { userSelectForm } = this.props.requestFormReducer;
    let updateSelected = { ...userSelectForm };

    updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY] = mergeTestPlan(
      updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY],
      testType,
      {
        [key]: value,
      }
    );
    updateSelected = removeEmpty(updateSelected);

    // remove top lvl empty object
    Object.keys(updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY]).forEach(
      (eachItem) => {
        if (
          Object.keys(
            updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][eachItem]
          ).length === 0
        ) {
          delete updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][eachItem];
        }
      }
    );

    this.props.dispatch(setUserRequestForm(updateSelected));
  };

  render() {
    const {
      // templateName,
      testType,
      fieldName,
      friendlyName,
      uiType,
      selectListContains,
      // handleOnChange,
      fieldProps,
    } = this.props;
    const { userSelectForm } = this.props.requestFormReducer;
    const testPlanObject = userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY];

    const fieldValue = testPlanObject[testType]
      ? testPlanObject[testType][fieldName]
      : null;
    if (uiType === constants.UI_TYPE_INPUT) {
      return (
        <InputField
          helpText={fieldName}
          friendlyName={friendlyName}
          fieldValue={fieldValue ? fieldValue : ""}
          handleOnChange={this.handleInputOnChange}
          fieldProps={fieldProps}
        ></InputField>
      );
    }
    if (uiType === constants.UI_TYPE_SELECT) {
      return (
        <SelectField
          helpText={fieldName}
          friendlyName={friendlyName}
          fieldValue={fieldValue ? fieldValue : ""}
          selectListContains={selectListContains}
          handleOnChange={this.handleSelectOnChange}
        ></SelectField>
      );
    }
    if (uiType === constants.UI_TYPE_MULTI_SELECT) {
      return (
        <MultiSelectField
          helpText={fieldName}
          friendlyName={friendlyName}
          fieldValueArray={fieldValue ? fieldValue : []}
          selectListContains={selectListContains}
          handleOnChange={this.handleMultiSelectOnChange}
        ></MultiSelectField>
      );
    }
    if (uiType === constants.UI_TYPE_TOGGLE) {
      return (
        <ToggleSelectField
          helpText={fieldName}
          friendlyName={friendlyName}
          fieldValue={fieldValue ? fieldValue : ""}
          handleOnChange={this.handleToggleOnChange}
        ></ToggleSelectField>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(RootField);
