import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import constants from "../../../../config/constants";
import { Button } from "@amzn/awsui-components-react/polaris";
import { setUserRequestForm } from "../../../../redux/actions/request-form-action";
// import { capitalizeFirstLetter } from "../../../utils/general-utils";
import "../combine-addon/combine-key-style.css"
interface StateProps {
  requestFormReducer: any;
}

// declare prop check
type Props = { dispatch: Dispatch<any>, testitem: string } & StateProps;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({});

// this add combine key component is unique for RvR compare with Desense and Compliance
class SignalingFinalCombineKeys extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  onClickRemove = (event) => {
    const { userSelectForm } = this.props.requestFormReducer;
    let updateSelected = { ...userSelectForm };
    const removeKey = event.target.id;
    const key =  `${this.props.testitem}${constants.ADD_SUFFIX}`
    if (
      key in
      updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY]
    ) {
      // if exist
      delete updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][key][removeKey];
    }
    this.props.dispatch(setUserRequestForm(updateSelected));
  };

  render() {
    const { userSelectForm } = this.props.requestFormReducer;
    const key =  `${this.props.testitem}${constants.ADD_SUFFIX}`
    const signalingCombineObj =
      userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY] &&
      key in
        userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY]
        ? userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY][key]
        : {};

    const mappedCombineKey = Object.keys(signalingCombineObj).map(
      (tech, index) => {
        const channel = signalingCombineObj[tech].join(", ");


        return (
          <div key={index} className={"each-combine"}>
            <div className={"each-combine-remove-btn"}>
              <Button id={tech} onClick={this.onClickRemove}>
                Remove
              </Button>
            </div>
            <div className={"combine-inner-key-label"}>
              Technology: {tech}
            </div>
            <hr />
            <div>
              Channel: {channel}
            </div>
            <br />
          </div>
        );
      }
    );

    return (
      <div className={"rvr-confirmed-combine-container"}>
        {mappedCombineKey}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(SignalingFinalCombineKeys);
