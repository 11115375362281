import React, { useState, useEffect } from "react";
import {
  Container,
  Header,
  Form,
  Button,
  Alert,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";
import UploadFileDropzone from "./file-dropzone";
import UploadingModal from "./uploading-modal";
import { func } from "prop-types";
// ajax
import { fileNameCheckCall, fileUploadS3Call } from "./ajax";
// constans & interface
import constants from "../../../config/constants";
import { FileStatus, UploadItem } from "../interface";

const UploadPanel = ({ lensProjectCodename, getUploadHistory }) => {
  const [uploadItems, setUploadItems] = useState<Array<UploadItem>>([]);
  const [showModal, setShowModal] = useState(false);

  // event: when on drag and drop file in dropzone
  const _onChangeFiles = (files: Array<File>) => {
    setUploadItems((oldUploadItems) => {
      const newUploadItems = [...oldUploadItems];
      files.forEach((file: File) => {
        if (!newUploadItems.some((item) => item.filename === file.name)) {
          newUploadItems.push({
            file,
            filename: file.name,
            fileCheckStatus: constants.LOADING_LOAD,
            uploadStatus: constants.LOADING_LOAD,
            message: "",
          });
        }
      });
      return newUploadItems;
    });
  };

  // loop each file, and send ajxa call to validate file name
  useEffect(() => {
    if (uploadItems.length > 0) {
      uploadItems.forEach(({ file, fileCheckStatus }) => {
        if (fileCheckStatus === constants.LOADING_LOAD) {
          fileNameCheckCall(file, lensProjectCodename).then((rst: FileStatus) => {
            setUploadItems((oldUploadItems) => {
              const newUploadItems = [...oldUploadItems];
              newUploadItems.forEach((item) => {
                if (file.name === item.filename) {
                  item.fileCheckStatus = rst.status;
                  item.message = rst.message;
                }
              });
              return newUploadItems;
            });
          });
        }
      });
    }
  }, [uploadItems, lensProjectCodename]);

  /*
        loop each file, 
        1. get presigned url
        2. the upload to s3
       */
  const _uploadFiles = async () => {
    uploadItems.forEach(({ file }) => {
      fileUploadS3Call(file, lensProjectCodename).then((rst: FileStatus) => {
        setUploadItems((oldUploadItems) => {
          const newUploadItems = [...oldUploadItems];
          newUploadItems.forEach((item) => {
            if (file.name === item.filename) {
              item.uploadStatus = rst.status;
              item.message = rst.message;
            }
          });
          return newUploadItems;
        });
      });
    });
  };

  // onClick clear button
  const _onClear = () => {
    setUploadItems([]);
    setShowModal(false);
  };

  // delete a file
  const _deleteFile = (filename: string) => {
    setUploadItems((oldUploadItems) => {
      const newUploadItems = [...oldUploadItems].filter((item) => item.filename !== filename);
      return newUploadItems;
    });
  };

  /* 
        check file name validation in fileCheckList
        return false if any of the file name is invalid
        return true if all file names are valid
      */
  const _validateForm = () => {
    if (uploadItems.some((item) => item.fileCheckStatus === constants.LOADING_FAIL)) {
      return false;
    }
    return true;
  };

  const _showModal = () => {
    setShowModal(true);
  };

  const _closeModal = () => {
    setShowModal(false);

    // clear form
    _onClear();

    // refetch upload status data, after 3s, give parse time to write log into db
    // tmp solution
    setTimeout(() => getUploadHistory(true), 3000);
  };

  /*
        onClick Upload button
        1. fileList is empty => alert
        2. validate file name failed => alert
        3. all good => confirm
            3.1 no => clear and return
            3.2 yes => open model, start upload process
      */
  const _onClickUpload = () => {
    // 1. fileList is empty
    if (uploadItems.length === 0) {
      alert("No file was uploaded.");
      return;
    }

    // 2. validate file name failed
    if (!_validateForm()) {
      alert("Invalid file name.");
      return;
    }

    // 3. all good, confirm
    let isConfirmed = window.confirm("Files will be uploaded LENS. Click OK to confirm. ");
    if (!isConfirmed) {
      return;
    }

    _showModal();

    _uploadFiles();
  };

  /* 
        check if need to disable upload button
        case 1: fileCheckList is empty
        case 2: Any file name is checking or failed
      */
  const _checkDisableUploadButton = () => {
    // case 1:
    if (uploadItems.length === 0) {
      return true;
    }

    // case 2:
    if (uploadItems.some((items) => items.fileCheckStatus !== constants.LOADING_SUCCESS)) {
      return true;
    }

    // otherwise, do not disable, return false
    return false;
  };

  return (
    <Container header={<Header variant="h2">Upload File</Header>}>
      {/* main upload portion */}
      <Form header={<Header description="Drag and drop to upload files"></Header>}>
        <SpaceBetween direction="vertical" size="m">
          {!_validateForm() && (
            <Alert header="Errors Detected" type="error">
              Please check you file name, and try again.
            </Alert>
          )}
          <UploadFileDropzone
            uploadItems={uploadItems}
            onChange={_onChangeFiles}
            deleteFile={_deleteFile}
          />
        </SpaceBetween>
      </Form>
      <br />

      {/* action stripe group */}
      <SpaceBetween direction="horizontal" size="xs">
        <Button onClick={_onClear}>Clear</Button>
        <Button variant="primary" onClick={_onClickUpload} disabled={_checkDisableUploadButton()}>
          Upload
        </Button>
      </SpaceBetween>

      <UploadingModal
        showModal={showModal}
        onDismiss={_onClear}
        onClose={_closeModal}
        uploadItems={uploadItems}
      />
    </Container>
  );
};

UploadPanel.prototype = {
  getUploadHistory: func,
};

export default UploadPanel;
