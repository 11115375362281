import React from "react";
import { CollectionPreferences } from "@amzn/awsui-components-react-v3";
import { UPLOAD_STATUS } from "../../../config/constants";

// since we don't have an unifored status defination in the upload table
// map all to the same status defination here:
export const uploadStatusMap = {
  // not started
  not_start: UPLOAD_STATUS.NOT_STARTED,
  not_started: UPLOAD_STATUS.NOT_STARTED,
  // in progress
  in_process: UPLOAD_STATUS.IN_PROGRESS,
  in_progress: UPLOAD_STATUS.IN_PROGRESS,
  // done
  done: UPLOAD_STATUS.SUCCESS,
  success: UPLOAD_STATUS.SUCCESS,
  // fail
  fail: UPLOAD_STATUS.FAIL,
  error: UPLOAD_STATUS.FAIL,
};

export const badgeColorMap = {
  [UPLOAD_STATUS.SUCCESS]: "green" as "green",
  [UPLOAD_STATUS.FAIL]: "red" as "red",
  [UPLOAD_STATUS.IN_PROGRESS]: "blue" as "blue",
  [UPLOAD_STATUS.NOT_STARTED]: "grey" as "grey",
};

export const statusClassNameMap = {
  [UPLOAD_STATUS.SUCCESS]: "text-success",
  [UPLOAD_STATUS.FAIL]: "text-danger",
  [UPLOAD_STATUS.IN_PROGRESS]: "text-info",
  [UPLOAD_STATUS.NOT_STARTED]: "text-secondary",
};

// define columns
export const COLUMN_DEFINITIONS = [
  {
    id: "id",
    header: "ID",
    sortingField: "id",
    cell: (item) => item.id,
    visible: false,
  },
  {
    id: "user",
    header: "User",
    sortingField: "User",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "user";
      const ascending = !sortState.sortingDescending;
      return `User, ${
        isColumnSorted ? `sorted ${ascending ? "ascending" : "descending"}` : "not sorted"
      }.`;
    },
    cell: (item) => item.user,
    visible: true,
  },
  {
    id: "tool",
    header: "Automation Tool",
    sortingField: "tool",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "tool";
      const ascending = !sortState.sortingDescending;
      return `Automation Tool, ${
        isColumnSorted ? `sorted ${ascending ? "ascending" : "descending"}` : "not sorted"
      }.`;
    },
    cell: (item) => item.tool,
    visible: true,
  },
  {
    id: "file_name",
    header: "File Name",
    sortingField: "file_name",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "file_name";
      const ascending = !sortState.sortingDescending;
      return `File Name, ${
        isColumnSorted ? `sorted ${ascending ? "ascending" : "descending"}` : "not sorted"
      }.`;
    },
    cell: (item) => item.file_name,
    visible: true,
  },
  {
    id: "upload_time",
    header: "Upload Time(local)",
    sortingField: "upload_time",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "upload_time";
      const ascending = !sortState.sortingDescending;
      return `Upload Time, ${
        isColumnSorted ? `sorted ${ascending ? "ascending" : "descending"}` : "not sorted"
      }.`;
    },
    cell: (item) => item.upload_time,
    visible: true,
  },
  {
    id: "status",
    header: "Status",
    sortingField: "Status",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "status";
      const ascending = !sortState.sortingDescending;
      return `Status, ${
        isColumnSorted ? `sorted ${ascending ? "ascending" : "descending"}` : "not sorted"
      }.`;
    },
    cell: (item) => {
      return <div className={statusClassNameMap[item.status]}>{item.status}</div>;
    },
    visible: true,
  },
  {
    id: "error_message",
    header: "Message",
    sortingField: "error_message",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "error_message";
      const ascending = !sortState.sortingDescending;
      return `Message, ${
        isColumnSorted ? `sorted ${ascending ? "ascending" : "descending"}` : "not sorted"
      }.`;
    },
    cell: (item) => {
      let className = "";
      // check Error only, others the same
      if (item.status === UPLOAD_STATUS.FAIL) {
        className = "awsui-util-status-negative";
      }
      return <div className={className}>{item.error_message}</div>;
    },
    visible: true,
  },
];

// define default sorting
export const DEFAULT_SORTING_COLUMN = {
  id: "upload_time",
  sortingField: "upload_time",
};

export const DEFAULT_SORTING_DESCENDING = true;

// define visiable columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Properties",
    options: COLUMN_DEFINITIONS.map((column) => ({
      id: column.id,
      label: column.header,
      visible: column.visible,
      editable: column.id === "id" ? false : true,
    })),
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 items" },
  { value: 20, label: "20 items" },
  { value: 50, label: "50 items" },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: COLUMN_DEFINITIONS.filter((column) => column.visible).map((column) => column.id),
  wraplines: false,
};

export const Preferences = ({ preferences, disabled, setPreferences }) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: PAGE_SIZE_OPTIONS,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible columns",
      options: VISIBLE_CONTENT_OPTIONS,
    }}
  />
);
