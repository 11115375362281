import React, { useState } from "react";
import { ExpandableSection, StatusIndicator } from "@amzn/awsui-components-react-v3";
import FileExplanationTable from "./table";
// ajax
import { config } from "../../../config/config";
import endpoints from "../../../config/endpoints";
import { postData, Response } from "../../../utils/cognito-fetch";
// constants, interface
import constants, { METRIC_INFO } from "../../../config/constants";
import { SupportedFile } from "../interface";

const FileExplanation = ({ lensProjectCodename }) => {
  // supported files states
  const [supportedFiles, setSupportedFiles] = useState<Array<SupportedFile>>([]);
  const [LoadingStatus, setLoadingStatus] = useState(constants.LOADING_LOAD);
  const [error, setError] = useState("");

  // Ajax: get supported file list
  React.useEffect(() => {
    postData(
      config.UPLOAD_BASE_URL + endpoints.getSupportedUploadFilesEndpoint(),
      {
        lens_project_codename: lensProjectCodename.toLowerCase(),
      },
      { initiative: lensProjectCodename, feature: METRIC_INFO.UPLOAD }
    )
      .then((res: Response) => {
        if (res.ok) {
          setSupportedFiles(res.json?.data);
          setLoadingStatus(constants.LOADING_SUCCESS);
        } else {
          setLoadingStatus(constants.LOADING_FAIL);
          setError(res.json?.message || res.status_text);
        }
      })
      .catch((e) => {
        setLoadingStatus(constants.LOADING_FAIL);
        setError(e.toString());
      });
  }, [lensProjectCodename]);

  return (
    <ExpandableSection header="Expand to see files supported">
      {LoadingStatus === constants.LOADING_FAIL && (
        <StatusIndicator type="error">{error}</StatusIndicator>
      )}

      <FileExplanationTable
        data={supportedFiles}
        loading={LoadingStatus === constants.LOADING_LOAD}
      />
    </ExpandableSection>
  );
};

export default FileExplanation;
