import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Button } from "@amzn/awsui-components-react/polaris";
import constants, { TEST_PLAN_SIGNALING_KEYS } from "../../../../config/constants";
import { setUserRequestForm } from "../../../../redux/actions/request-form-action";
import "../combine-addon/combine-key-style.css"
import SignalingFinalCombineKeys from "./signaling-key-final"
interface StateProps {
  requestFormReducer: any;
}

// declare prop check
type Props = { dispatch: Dispatch<any>,  testitem: string} & StateProps;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  tokenItem: [],
});

// this add combine key component is unique for RvR compare with Desense and Compliance
class SignalingAddPlan extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _onClickAdd = () =>{
    // add current combination to comfirmed keys
    const { userSelectForm } = this.props.requestFormReducer;

    const testplan = userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY];
    const selections = testplan[
      `${this.props.testitem}`
    ]
    if (!this._checkValidSelections(selections)) {
        window.alert(
      "You need to select all Technologies, Channel to add a plan"
    );
    } else {
        let addedPlan = testplan[
        `${this.props.testitem}${constants.ADD_SUFFIX}`
        ]
        const tech = selections[TEST_PLAN_SIGNALING_KEYS.technologies]
        const channel = selections[TEST_PLAN_SIGNALING_KEYS.channel].sort((a, b) => (a - b))
        if (!addedPlan) {
            addedPlan = {
                [tech]: channel
            }
        } else {
            addedPlan = {
                ...addedPlan,
                [tech]: tech in addedPlan ? Array.from(new Set([...addedPlan[tech],...channel])).sort((a, b) => (a - b)) : channel
            }
        }
        let updateSelected = { ...userSelectForm };
        updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][
            `${this.props.testitem}${constants.ADD_SUFFIX}`
        ] = addedPlan;
        this.props.dispatch(setUserRequestForm(updateSelected));
    }
    
  }
  _checkValidSelections = (selections) => {
    if (selections === undefined) {
        return false;
    }
    let valid = true;
    Object.keys(TEST_PLAN_SIGNALING_KEYS).forEach((key) => {
        if (!(key in selections)) {
            valid = false;
        }
    })
    return valid;
  }
  render() {
    return (
        <React.Fragment>
        <div className={"confim-combine-btn"}>
            <Button onClick={this._onClickAdd}>Add plan</Button>
        </div>
        <div className={"confim-keys-session"}>Confirmed combine:</div>
        <div className={"rvr-combine-key-tokens"}>
          <SignalingFinalCombineKeys testitem={this.props.testitem}/>
        </div>
        </React.Fragment>
      
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(SignalingAddPlan);
