import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
// import Button from "@amzn/awsui-components-react/polaris/button";
import Layout from "../layout/layout";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import RequestDetailTable from "./detail-table/detail-table";
import { getRequestStatusDetail } from "../../redux/actions/request-status-action";
// import { useSelector } from "react-redux";
import { Breadcrumb } from "./request-detail-config";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface StateProps {
  requestStatusReducer: any;
}

interface MatchParams {
  assignment_id: string;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<MatchParams>;

class RequestStatus extends Component<Props> {
  // state = { :  }
  componentDidMount() {
    this._getStatusDetail();
  }

  _getStatusDetail = () => {
    const { assignment_id } = this.props.match.params;
    this.props.dispatch(getRequestStatusDetail(assignment_id));
  };

  render() {
    const { requestStatusDetail, requestStatusDetailLoading } =
      this.props.requestStatusReducer;

    return (
      <Layout>
        <React.Fragment>
          <BreadcrumbGroup
            items={Breadcrumb}
            onFollow={(e) => {
              e.preventDefault();
              this.props.history.push(e.detail.href);
            }}
          />
          <br />
          <div>
            <RequestDetailTable
              data={
                requestStatusDetail && Array.isArray(requestStatusDetail)
                  ? requestStatusDetail
                  : []
              }
              loadingStatus={requestStatusDetailLoading}
              getStatusData={this._getStatusDetail}
            />
          </div>
        </React.Fragment>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestStatusReducer: state.requestStatusReducer,
  };
};
export default withRouter(connect<StateProps>(mapStateToProps)(RequestStatus));
