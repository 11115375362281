import { capitalizeFirstLetter } from "../../../utils/general-utils";
import constants, { TEST_PLAN_PASSIVE_KEYS } from "../../../config/constants";

const removeKeyList = [
  constants.TEST_PLAN_RVR_TESTITEM,
  constants.TEST_PLAN_DESENSE_TESTITEM,
];

[
  constants.TEST_PLAN_PASSIVE_TESTITEM,
  constants.TEST_PLAN_PSEUDO_ACTIVE_TESTITEM,
  constants.TEST_PLAN_SIGNALING_TIS_TESTITEM,
  constants.TEST_PLAN_SIGNALING_TRP_TESTITEM,
].forEach((eachTestItem) => {
  removeKeyList.push(eachTestItem);
  removeKeyList.push(`${eachTestItem}${constants.ADD_SUFFIX}`);
  removeKeyList.push(`${eachTestItem}${constants.COMBINATIONS_SUFFIX}`);
});

const removeSublevelIgnoreKeyList = [
  constants.TEST_CATEGORY_KEY_RVR,
  constants.TEST_PLAN_RVR_TESTITEM,
  constants.TEST_CATEGORY_KEY_DESENSE,
  constants.TEST_PLAN_DESENSE_TESTITEM,
  constants.TEST_CATEGORY_KEY_ANTENNA,
];

[
  constants.TEST_PLAN_PASSIVE_TESTITEM,
  constants.TEST_PLAN_PSEUDO_ACTIVE_TESTITEM,
  constants.TEST_PLAN_SIGNALING_TIS_TESTITEM,
  constants.TEST_PLAN_SIGNALING_TRP_TESTITEM,
].forEach((eachTestItem) => {
  removeSublevelIgnoreKeyList.push(eachTestItem);
  removeSublevelIgnoreKeyList.push(`${eachTestItem}${constants.ADD_SUFFIX}`);
  removeSublevelIgnoreKeyList.push(
    `${eachTestItem}${constants.COMBINATIONS_SUFFIX}`
  );
});

const antennaConvertKey = {};
[
  [constants.TEST_PLAN_PASSIVE_TESTITEM, constants.TEST_CATEGORY_KEY_PASSIVE],
  [
    constants.TEST_PLAN_PSEUDO_ACTIVE_TESTITEM,
    constants.TEST_CATEGORY_KEY_PSEUDO_ACTIVE,
  ],
  [
    constants.TEST_PLAN_SIGNALING_TRP_TESTITEM,
    constants.TEST_CATEGORY_KEY_SIGNALING_TRP,
  ],
  [
    constants.TEST_PLAN_SIGNALING_TIS_TESTITEM,
    constants.TEST_CATEGORY_KEY_SIGNALING_TIS,
  ],
].forEach((eachTest) => {
  antennaConvertKey[eachTest[0]] = eachTest[1];
});

export const formatOptions = (inputArray) => {
  let result: Array<any> = [];

  result = inputArray.map((eachEle) => {
    return { id: eachEle, label: capitalizeFirstLetter(eachEle) };
  });
  return result;
};

export const handleLvlOptions = (inputArray: Array<string>) => {
  let sliceStart = 0;
  let sliceEnd = 1;

  const filteredArray = inputArray.map((eachCombine) =>
    eachCombine.includes(constants.TEST_COMBINE_KEY)
      ? eachCombine
          .split(constants.TEST_COMBINE_KEY)
          .slice(sliceStart, sliceEnd)
          .join(constants.TEST_COMBINE_KEY)
      : null
  );
  const uniqueList = Array.from(new Set(filteredArray));

  let resultList = uniqueList.map((item) => ({
    label: item ? capitalizeFirstLetter(item) : "",
    id: item,
  }));
  // take tcs_id, but dont show to user

  return resultList;
};

export const getSublvlOptions = (
  inputArray: Array<string>,
  selectedValue: string
) => {
  // filter selectedValues in inputArray,
  // each item format in filteredlistare the same as input array
  const filteredlist = inputArray.filter((eachValue) =>
    eachValue.includes(`${selectedValue}_`)
  );
  // using set to get unique
  const uniqueList = Array.from(new Set(filteredlist));
  const resultList = uniqueList.map((item) => {
    // chaneg item to current level
    const currentItem = item.replace(`${selectedValue}_`, "");
    return {
      label: currentItem ? capitalizeFirstLetter(currentItem) : "",
      id: currentItem,
    };
  });
  return resultList;
};

export const getTabLael = (requestFormTab: Array<any>, activeId) => {
  let tabLabel = "";
  if (requestFormTab) {
    requestFormTab.forEach((eachObject) => {
      if (eachObject["id"]) {
        if (eachObject["id"] === activeId) {
          tabLabel = eachObject["label"];
        }
      }
    });
  }

  return tabLabel;
};

export const formatTestPlan = (userSelectForm) => {
  // Make copy of userSelectForm, rename as formatted result
  let formatResult = { ...userSelectForm };
  let currentTestTypeObject =
    formatResult[constants.REQUEST_FORM_TEST_PLAN_KEY];
  if (currentTestTypeObject) {
    Object.keys(currentTestTypeObject).forEach((eachType) => {
      // eachType = active scan, desense, passive
      if (currentTestTypeObject[eachType]) {
        if (eachType in antennaConvertKey) {
          let antennaObject =
            constants.TEST_CATEGORY_KEY_ANTENNA in currentTestTypeObject
              ? {
                  ...currentTestTypeObject[constants.TEST_CATEGORY_KEY_ANTENNA],
                }
              : {};
          const categoryKey = antennaConvertKey[eachType];
          if (`${eachType}${constants.ADD_SUFFIX}` in currentTestTypeObject) {
            // format antenna passive
            if (eachType === constants.TEST_PLAN_PASSIVE_TESTITEM) {
              antennaObject[categoryKey] = formatAntennaPassive(
                currentTestTypeObject
              );
            } else if (
              eachType === constants.TEST_PLAN_PSEUDO_ACTIVE_TESTITEM
            ) {
              antennaObject[categoryKey] = formatAntennaPseudoActive(
                currentTestTypeObject
              );
            } else if (eachType === constants.TEST_PLAN_SIGNALING_TRP_TESTITEM || eachType === constants.TEST_PLAN_SIGNALING_TIS_TESTITEM) {
              antennaObject[categoryKey] = formatAntennaSignaling(
                currentTestTypeObject, eachType
              );
            }
          } else if (
            `${eachType}${constants.COMBINATIONS_SUFFIX}` in
            currentTestTypeObject
          ) {
            antennaObject[categoryKey] =
              currentTestTypeObject[
                `${eachType}${constants.COMBINATIONS_SUFFIX}`
              ];
          }
          formatResult[constants.REQUEST_FORM_TEST_PLAN_KEY][
            constants.TEST_CATEGORY_KEY_ANTENNA
          ] = antennaObject;
        }
        Object.keys(currentTestTypeObject[eachType]).forEach((eachTech) => {
          // eachTech = countries, technologies_wifi_bands, technologies
          // {countries: "br"}, {technologies_wifi_bands: Array(1)}
          const techLvlObject = currentTestTypeObject[eachType];

          if (eachTech.includes(constants.TEST_COMBINE_KEY)) {
            // technologies_wifi_bands
            const keyValue = techLvlObject[eachTech]; // ["5g, 2g"]
            const splitedTechArray = eachTech.split(constants.TEST_COMBINE_KEY);

            // TODO future, make this more dynamic if form structure changed
            // for now this is enough
            if (splitedTechArray.length === 3) {
              const techLvl = splitedTechArray[0];
              const techItem = splitedTechArray[1];
              const subItem = splitedTechArray[2];

              // Overwrite technology lvl object
              currentTestTypeObject[eachType] = {
                ...currentTestTypeObject[eachType],
                ...helperAppendObject(
                  techLvlObject,
                  techLvl,
                  techItem,
                  subItem,
                  keyValue
                ),
              };
              // testplan: old testplan or new testplan if exists
              formatResult[constants.REQUEST_FORM_TEST_PLAN_KEY] = {
                ...formatResult[constants.REQUEST_FORM_TEST_PLAN_KEY],
                ...currentTestTypeObject,
              };
            }
          }
        });
      }
    });
  }

  return formatResult;
};

const formatAntennaPassive = (currentTestTypeObject) => {
  const testPlan = {};
  testPlan[TEST_PLAN_PASSIVE_KEYS.frequency] =
    currentTestTypeObject[
      `${constants.TEST_PLAN_PASSIVE_TESTITEM}${constants.ADD_SUFFIX}`
    ];
  testPlan[TEST_PLAN_PASSIVE_KEYS.antenna] =
    currentTestTypeObject[constants.TEST_PLAN_PASSIVE_TESTITEM][TEST_PLAN_PASSIVE_KEYS.antenna] || [];
  return testPlan;
};

const formatAntennaPseudoActive = (currentTestTypeObject) => {
  const testPlan: Array<any> = [];
  const selected =
    currentTestTypeObject[
      `${constants.TEST_PLAN_PSEUDO_ACTIVE_TESTITEM}${constants.ADD_SUFFIX}`
    ];
  Object.keys(selected).forEach((key) => {
    const keyInfo = key.split(constants.TEST_COMBINE_KEY);
    const tech = keyInfo[0];
    const channel = keyInfo[1]
      .split(constants.CHANNEL_COMBINE_SEPARATOR)
      .map((c) => Number(c));
    const testitem = selected[key];
    testPlan.push({
      technology: tech,
      channel: channel,
      testitem: testitem,
    });
  });
  return testPlan;
};

const formatAntennaSignaling= (currentTestTypeObject, testitem) => {
  const testPlan: Array<string>= [];
  const selected =
    currentTestTypeObject[
      `${testitem}${constants.ADD_SUFFIX}`
    ];
  Object.keys(selected).forEach((tech) => {
    let channels = selected[tech];
    channels.forEach((channel) => {
      testPlan.push(`${channel}, ${tech}`);
    })
  });
  return testPlan;
};

const helperAppendObject = (
  existedObject: any,
  techLvl: string,
  techItem: string,
  subItem: string,
  value: any
) => {
  const resultObject = { ...existedObject };
  if (techLvl in resultObject) {
    if (techItem in resultObject[techLvl]) {
      resultObject[techLvl][techItem][subItem] = value;
    } else {
      resultObject[techLvl][techItem] = {
        [subItem]: value,
      };
    }
  } else {
    resultObject[techLvl] = {
      [techItem]: {
        [subItem]: value,
      },
    };
  }
  return resultObject;
};

export const removeNoNeedKeys = (userSelectForm) => {
  let formatResult = { ...userSelectForm };
  let currentTestTypeObject =
    formatResult[constants.REQUEST_FORM_TEST_PLAN_KEY];

  Object.keys(currentTestTypeObject).forEach((eachType) => {
    // Remove RvR's TEST_PLAN_RVR_TESTITEM
    if (removeKeyList.includes(eachType)) {
      delete formatResult[constants.REQUEST_FORM_TEST_PLAN_KEY][eachType];
    }
    // ignore rvr items in this remove no need key to avoid wrong remove
    if (!removeSublevelIgnoreKeyList.includes(eachType)) {
      Object.keys(currentTestTypeObject[eachType]).forEach((eachKey) => {
        if (eachKey.includes(constants.TEST_COMBINE_KEY)) {
          delete formatResult[constants.REQUEST_FORM_TEST_PLAN_KEY][eachType][
            eachKey
          ];
        }
      });
    }
  });

  return formatResult;
};
