import { withRouter } from "react-router-dom";
import SideNavigation from "@amzn/awsui-components-react-v3/polaris/side-navigation";
import { getSidebarItems } from "./sidebar-config";
import constants from "../../config/constants";
import { useSelector } from "react-redux";

const Sidebar = ({ location, history }) => {
  const { authzReducer } = useSelector((state: any) => ({
    authzReducer: state.authzReducer,
  }));

  const permissionObj = authzReducer[constants.AUTH_PERMISSION_KEY];

  const onFollow = (e) => {
    if (!e.detail.external) {
      e.preventDefault();
      history.push(e.detail.href);
    }
  };

  return (
    <SideNavigation
      header={{ href: "/home", text: "Engineering" }}
      activeHref={location.pathname || "/"}
      onFollow={onFollow}
      items={getSidebarItems(permissionObj[constants.AUTH_USERTYPE_KEY])}
    />
  );
};

export default withRouter(Sidebar);
