import { Component } from "react";
import {
  Table,
  TableSorting,
  TablePropertyFiltering,
  TablePagination,
  TablePreferences,
  TablePageSizeSelector,
  TableWrapLines,
  TableContentSelector,
  Button,
} from "@amzn/awsui-components-react/polaris";

import {
  defaultPageSize,
  columnDefinitions,
  features,
  defaultSortingColumn,
  sortableColumns,
  filteringOptions,
  visibleColumns,
} from "./table-config";
import { customizedFilteringFunction } from "../../../components/polaris/table/property-filtering-utils";
// import { Button } from "@amzn/awsui-components-react-v3";
import constants from "../../../config/constants";
import { Dispatch } from "redux";
import {
  getLabAssignments,
  updateAssignment,
} from "../../../redux/actions/assignment-action";

type State = typeof initialState;

const initialState = Object.freeze({
  currentPageIndex: 1,
  pagesCount: 0,
  startDate: "",
  endDate: "",
});

type Props = {
  data: any;
  loadingStatus: any;
  lab: number;
  dispatch: Dispatch;
  getAssignData: (syncMode: boolean) => void;
} & typeof defaultProps;

const defaultProps = Object.freeze({});

class AssignmentTable extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = initialState;

  _onPaginationChange = (event) => {
    const { currentPageIndex, pagesCount } = event.detail;
    this.setState({
      currentPageIndex,
      pagesCount,
    });
  };

  _onUpdateStatus = (e, status) => {
    this.props.dispatch(updateAssignment(e.assignment_id, { status: status }));
    this.props.dispatch(getLabAssignments(this.props.lab));
    this.props.getAssignData(false);
  };

  render() {
    const { data, loadingStatus, getAssignData } = this.props;
    return (
      <Table
        columnDefinitions={columnDefinitions(this._onUpdateStatus)}
        items={data}
        loading={loadingStatus === constants.LOADING_LOAD}
        features={features}
        header={
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>
                Assignments &nbsp;
                <span className="awsui-util-header-counter">{`(${data.length})`}</span>
              </h2>
            </div>
            <div className="awsui-util-action-stripe-group">
              <Button
                icon="refresh"
                onClick={() => getAssignData(false)}
              ></Button>
            </div>
          </div>
        }
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No resources</b>
            </div>
            <p className="awsui-util-mb-s">No resources to display.</p>
          </div>
        }
        wrapLines={false}
        noMatch={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No matches</b>
            </div>
            <p className="awsui-util-mb-s">We can’t find a match.</p>
            <div className="awsui-util-mb-m">
              <Button>Clear filter</Button>
            </div>
          </div>
        }
      >
        <TablePropertyFiltering
          filteringOptions={filteringOptions}
          filteringFunction={customizedFilteringFunction}
          groupValuesText="Values"
          groupPropertiesText="Properties"
          operationAndText="and"
          operationNotAndText="and not"
          operationOrText="or"
          operationNotOrText="or not"
          clearFiltersText="Clear filter"
          placeholder="Filter resources by property or value"
          allowFreeTextFiltering={true}
          filteringCountTextFunction={(count) =>
            `${count} ${count === 1 ? "match" : "matches"}`
          }
        ></TablePropertyFiltering>
        <TablePagination
          pageSize={defaultPageSize}
          onPaginationChange={this._onPaginationChange}
        ></TablePagination>
        <TableSorting
          sortingColumn={defaultSortingColumn}
          sortableColumns={sortableColumns}
          sortingDescending={true}
        ></TableSorting>
        <TablePreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
        >
          <TablePageSizeSelector
            title="Page size"
            options={[
              { value: 3, label: "3 items" },
              { value: 5, label: "5 items" },
              { value: 10, label: "10 items" },
              { value: 20, label: "20 items" },
              { value: 50, label: "50 items" },
            ]}
          />
          <TableWrapLines
            label="Wrap lines"
            description="Enable to wrap table cell content, disable to truncate text."
          />
          <TableContentSelector
            title="Select visible columns"
            options={visibleColumns}
          />
        </TablePreferences>
      </Table>
    );
  }
}

export default AssignmentTable;
