import React, { Component } from "react";

const containerStyle = {
  textAlign: "center" as const,
  marginTop: "80px",
  display: "flex",
  flexDirection: "column" as const,
  justifyContent: "center" as const,
  alignItems: "center" as const,
};

const titleStyle = {
  fontSize: "40px",
  fontWeight: 700,
};

const textStyle = {
  fontSize: "16px",
  marginTop: "20px",
  width: "800px",
};

class NotAuthz extends Component {
  render() {
    return (
      <div style={containerStyle}>
        <div style={titleStyle}>Not Authorized</div>
        <br />
        <div style={textStyle}>
          Sorry, you don't have the access to the website. To request the
          access, please create a
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://sim.amazon.com/issues/create?template=f54292fe-5e20-4a46-819d-56997631b88d"
          >
            &nbsp;SIM ticket&nbsp;
          </a>
          to WISe team.
        </div>
        <div style={textStyle}></div>
      </div>
    );
  }
}

export default NotAuthz;
