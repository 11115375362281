import { GraphQLClient, gql } from "graphql-request";
import {
  redirectToLoginPage,
  getAccessToken,
} from "../authentication/cognito-auth";
import { config } from "../config/config";
import endpoints from "../config/endpoints";

export interface Response {
  ok: boolean;
  status_code: number;
  status_text: string;
  json?: any;
}

export async function graphqlCall(queryStr) {
  const token = await getAccessToken();
  if (token === "") {
    redirectToLoginPage();
  }

  const graphQLClient = new GraphQLClient(
    config.BASE_URL + endpoints.graphqlEndpoint(),
    {
      headers: {
        Authorization: token,
      },
    }
  );

  const query = gql`
    ${queryStr}
  `;
  try {
    const { data, extensions, status } = await graphQLClient.rawRequest(query);
    const jsonResponse: Response = {
      ok: status === 200,
      status_code: status,
      status_text: JSON.stringify(extensions, undefined, 2),
      json: data,
    };
    return jsonResponse;
  } catch (error) {
    console.error(JSON.stringify(error, undefined, 2));
    const jsonResponse: Response = {
      ok: false,
      status_code: 500,
      status_text: JSON.stringify(error, undefined, 2),
      json: {},
    };
    return jsonResponse;
  }
}
