import React, { useState } from "react";
import {
  Button,
  FormField,
  Select,
  SelectProps,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
// ajax
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { getData, Response } from "../../utils/cognito-fetch";
// constants
import constants, { METRIC_INFO } from "../../config/constants";

const StyledHeader = styled.div`
  background-color: #232f3e;
  padding-bottom: 20px;
  padding-top: 50px;
  background-position: 0;
  background-size: cover;
  background-image: url("//d1.awsstatic.com/Digital Marketing/acquisition/2018/Free Tier/Heroes/WEB_FreeTier_Yellow_Hero_1400x500.eff27f1d5e226d558fc858f0b9b03f9417dfc826.jpg");
`;

const StyledHeaderTitle = styled.div`
  color: #ffffff;
  font-weight: lighter;

  p {
    color: #d5dbdb;
  }
`;

const PreUploadPage = () => {
  const history = useHistory();

  const [lensProjectOptions, setLensProjectOptions] = useState<
    Array<SelectProps.Option>
  >([]);
  const [selection, setSelection] = useState<SelectProps.Option | null>(null);
  const [loadingStatus, setLoadingStatus] = useState(constants.LOADING_LOAD);
  const [message, setMessage] = useState("");

  // ajax call to get the list of lens projects supported in upload service
  React.useEffect(() => {
    getData(
      config.UPLOAD_BASE_URL + endpoints.getSupportedLENSProjectsEndpoint(),
      {
        initiative: METRIC_INFO.INITIATIVE,
        feature: METRIC_INFO.UPLOAD,
      }
    )
      .then((res: Response) => {
        if (res.ok) {
          setLensProjectOptions(
            (res.json?.data || []).map((item) => ({
              label: item.name,
              value: item.codename,
            }))
          );
          setLoadingStatus(constants.LOADING_SUCCESS);
        } else {
          setLoadingStatus(constants.LOADING_FAIL);
          setMessage(res.json?.message || res.status_text);
        }
      })
      .catch((e) => {
        setLoadingStatus(constants.LOADING_FAIL);
        setMessage(e.toString());
      });
  }, []);

  const GetStartedAction = () => (
    <div className="awsui-util-container awsui-util-mb-n">
      <h2>Get Started</h2>
      <FormField
        stretch={true}
        label="Select a LENS Project to start with"
        className="awsui-util-d-b"
      >
        <SpaceBetween direction="vertical" size="xs">
          <Select
            selectedOption={selection}
            onChange={({ detail }) => setSelection(detail.selectedOption)}
            options={lensProjectOptions}
            selectedAriaLabel="Selected"
            errorText={message}
            statusType={
              loadingStatus === constants.LOADING_LOAD
                ? "loading"
                : loadingStatus === constants.LOADING_SUCCESS
                ? "finished"
                : "error"
            }
          />
          <Button
            variant="primary"
            disabled={!selection}
            onClick={() => history.push(`/upload/${selection?.value}`)}
          >
            Launch Upload Portal
          </Button>
        </SpaceBetween>
      </FormField>
    </div>
  );

  return (
    <div className="awsui-grid awsui-util-p-s">
      <StyledHeader className="awsui-row">
        <div className="col-xxs-12">
          <div className="awsui-row">
            <StyledHeaderTitle className="col-xxs-10 offset-xxs-1 col-s-6 col-l-5 offset-l-2 col-xl-6">
              <div className="awsui-text-large">
                <h1 className="awsui-text-large">
                  <strong>LENS File Uploader</strong>
                </h1>
                {/* <div>
                  Fast and reliable upload solution for 3rd party lab and
                  vendors
                </div> */}
              </div>
              <p>
                LENS File Uploader is a solution that enables 3rd party lab and
                vendors to directly upload testing result files to Amazon
                Lab126.
              </p>
            </StyledHeaderTitle>
            <div className="col-xxs-10 offset-xxs-1 col-s-4 offset-s-0 col-l-3 col-xl-2 custom-home__header-sidebar">
              <GetStartedAction />
            </div>
          </div>
        </div>
      </StyledHeader>
    </div>
  );
};

export default PreUploadPage;
