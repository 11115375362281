export const labAssignmentTypes = {
  GET_LAB_ASSIGNMENT: "GET_LAB_ASSIGNMENT",
  GET_LAB_ASSIGNMENT_COMMIT: "GET_LAB_ASSIGNMENT_COMMIT",
  GET_LAB_ASSIGNMENT_ROLLBACK: "GET_LAB_ASSIGNMENT_ROLLBACK",
  RESET_LAB_ASSIGNMENT: "RESET_LAB_ASSIGNMENT",
};

export const updateAssignmentTypes = {
  UPDATE_ASSIGNMENT: "UPDATE_ASSIGNMENT",
  UPDATE_ASSIGNMENT_COMMIT: "UPDATE_ASSIGNMENT_COMMIT",
  UPDATE_ASSIGNMENT_ROLLBACK: "UPDATE_ASSIGNMENT_ROLLBACK",
  RESET_UPDATE_ASSIGNMENT_ASSIGNMENT: "RESET_UPDATE_ASSIGNMENT_ASSIGNMENT",
};

export const assignmentTestCategoriesTypes = {
  GET_ASSIGNMENT_TEST_CATEGORIES: "GET_ASSIGNMENT_TEST_CATEGORIES",
  GET_ASSIGNMENT_TEST_CATEGORIES_COMMIT:
    "GET_ASSIGNMENT_TEST_CATEGORIES_COMMIT",
  GET_ASSIGNMENT_TEST_CATEGORIES_ROLLBACK:
    "GET_ASSIGNMENT_TEST_CATEGORIES_ROLLBACK",
};
