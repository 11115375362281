export const Breadcrumb = (assignmentId) => {
  return [
    { text: "Home", href: "/" },
    { text: "Third Party Lab", href: "/third-party-lab" },
    {
      text: "Assignment : " + assignmentId,
      href: `/third-party-lab/assignments/${assignmentId}`,
    },
  ];
}

export const test_case_sample_data = [];