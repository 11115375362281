class Endpoints {
  /* 
        define all you endpoints here
        each endpoint will be in form of function 
    */
  sampleEndpoint = (if_you_need_variable: string) => `/this_is_an_example/${if_you_need_variable}`;

  // get assignments for a lab
  getLabAssignments = (labId: number) => `/labs/${labId}/assignments`;

  // get test catgeories for an assignment
  getAssignmentTestCategories = (assignmentId: number) => `/requests/assignment/${assignmentId}`;

  // get test cases for assignment test category
  getTestCategoryTestCases = (testCategoryId: number) =>
    `/requests/assignment/testcategory/${testCategoryId}`;

  // graphql
  graphqlEndpoint = () => "/graphql";

  // get project list
  getProjectListEndpoint = (category) => `/categories/${category}/projects`;
  getProjectListWithoutCategoryEndpoint = () => `/projects`;
  // get build list
  getBuildList = (project) => `/projects/${project}/builds`;

  getProjectBuildInfo = () => "/project_build_info";

  // request status
  requestStatusEndpoint = (user) => `/requests/user/${user}`;

  getAssignmentStatusDetail = (assignmentId: number) => `/requests/assignment/${assignmentId}`;

  // User metrics
  metricEndpoint = () => `/log/metrics`;

  // request form
  // post
  createRequestFormEndpoint = () => `/requests`;

  // ************************** upload endpoints **************************
  getSupportedLENSProjectsEndpoint = () => `/supported-lens-projects`;

  getSupportedUploadFilesEndpoint = () => `/supported-files`;

  // file name check
  uploadFileNameCheckEndpoint = () => "/upload/check";

  // generate pre-signed url
  uploadPreSignedUrlEndpoint = () => "/upload";

  // upload history (status)
  uploadHistoryEndpoint = () => "/upload/status";
}

const endpoints = new Endpoints();
export default endpoints;
