const requestStatusType = {
  // REQUEST_FORM call
  REQUEST_STATUS_CALL: "REQUEST_STATUS_CALL",
  REQUEST_STATUS_CALL_COMMIT: "REQUEST_STATUS_CALL_COMMIT",
  REQUEST_STATUS_CALL_ROLLBACK: "REQUEST_STATUS_CALL_ROLLBACK",

  REQUEST_STATUS_DETAIL_CALL: "REQUEST_STATUS_DETAIL_CALL",
  REQUEST_STATUS_DETAIL_CALL_COMMIT: "REQUEST_STATUS_DETAIL_CALL_COMMIT",
  REQUEST_STATUS_DETAIL_CALL_ROLLBACK: "REQUEST_STATUS_DETAIL_CALL_ROLLBACK",

  // SET REQUEST
  SET_REQUEST_DETAIL: "SET_REQUEST_DETAIL",
};

export default requestStatusType;
