import React, { useState } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  Table,
  Button,
  Pagination,
  TextFilter,
  Badge,
  Icon,
  Header,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react-v3";
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  DEFAULT_SORTING_COLUMN,
  DEFAULT_SORTING_DESCENDING,
  Preferences,
  uploadStatusMap,
  badgeColorMap,
} from "./table-config";
import {
  TableEmptyState,
  EmptyState,
  getMatchesCountText,
  paginationLabels,
} from "../../../components/polaris/table/common-components";
import { exportCSVFunc } from "@amzn/lens-react-ui";

const UploadHistoryTable = ({ data, getData, loading, error }) => {
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  const formatTableData = (rawData) => {
    return rawData.map((item, index) => {
      item.id = index;
      item.status = uploadStatusMap[item.status] || item.status;
      return item;
    });
  };

  const allItems = formatTableData(data);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } =
    useCollection(allItems, {
      filtering: {
        empty: <TableEmptyState resourceName={"Upload History Record"} />,
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering("")}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
        defaultState: {
          sortingColumn: DEFAULT_SORTING_COLUMN,
          isDescending: DEFAULT_SORTING_DESCENDING,
        },
      },
    });

  const statusComponent = () => {
    const statusCountMap = {};
    if (Array.isArray(allItems)) {
      allItems.forEach((eachObj) => {
        const currentStatus = eachObj.status;
        statusCountMap[currentStatus] = statusCountMap[currentStatus]
          ? statusCountMap[currentStatus] + 1
          : 1;
      });
    }

    return Object.keys(statusCountMap).map((statusKey, index) => (
      <span className={"awsui-util-header-counter"} key={index}>
        <Badge
          color={badgeColorMap[statusKey]}
        >{`${statusKey}:${statusCountMap[statusKey]}`}</Badge>
      </span>
    ));
  };

  return (
    <React.Fragment>
      {error && <StatusIndicator type="error">{error}</StatusIndicator>}
      <Table
        {...collectionProps}
        trackBy="id"
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        loading={loading}
        loadingText="Loading resources"
        visibleColumns={preferences.visibleContent}
        empty={<TableEmptyState resourceName={"Upload History Record"} />}
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" onClick={() => getData(false)}>
                  <Icon name="refresh" size="normal" variant="normal" />
                  &nbsp;Refresh
                </Button>
                <Button variant="normal" onClick={() => exportCSVFunc(allItems, "upload_history")}>
                  <Icon name="download" size="normal" variant="normal" />
                  &nbsp;Export
                </Button>
              </SpaceBetween>
            }
          >
            <h2>
              Upload History &nbsp;
              <span className="awsui-util-header-counter">
                <Badge color="grey">{`Total:${allItems.length} `}</Badge>
              </span>
              {statusComponent()}
            </h2>
          </Header>
        }
        resizableColumns={true}
        wrapLines={preferences.wraplines}
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringAriaLabel="Filter project"
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <Preferences
            preferences={preferences}
            disabled={loading}
            setPreferences={(detail) => {
              setPreferences(detail);
            }}
          />
        }
      />
    </React.Fragment>
  );
};

export default UploadHistoryTable;
