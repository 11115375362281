import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import RootField from "./fields/root-field";
import constants, {
  UI_MAP_LOOKUP,
  COMPLIANCE_VALID_LIST,
} from "../../../config/constants";
import {
  flatNestedObject,
  splitFieldByKey,
  filterTechKeys,
} from "./test-plan-utils";
import { setUserRequestForm } from "../../../redux/actions/request-form-action";
import { Button } from "@amzn/awsui-components-react/polaris";
import { capitalizeFirstLetter } from "../../../utils/general-utils";
import CombineKeys from "./combine-addon/combine-key";
import AddGroup from "./antenna-addon/passive-add-group";
import PseudoActiveAddPlan from "./antenna-addon/pseudo-active-add-plan";
import SignalingAddPlan from "./antenna-addon/signaling-add-plan";

import LookupTestPlanCombine from "./compliance-lookup-testplan/valid-lookup-plan";

const ADD_BUTTON: string = "addButton";

interface StateProps {
  requestFormReducer: any;
}

// declare prop check
type Props = {
  testDetail: any;
  testType: string;
  dispatch: Dispatch<any>;
} & StateProps;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({});

class TestPlanWrapUp extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _addRange = (keys, testType) => {
    const onClickAdd = () => {
      const { userSelectForm } = this.props.requestFormReducer;

      let updateSelected = { ...userSelectForm };
      const testPlan = updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY];
      const addKey = `${testType}${constants.ADD_SUFFIX}`;
      const combinationsKey = `${testType}${constants.COMBINATIONS_SUFFIX}`;

      if (testType === constants.TEST_PLAN_PASSIVE_TESTITEM) {
        if (combinationsKey in testPlan) {
          let passiveSet: Set<number> = new Set(
            addKey in updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY]
              ? updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][addKey]
              : []
          );

          const freqRange: number[] = testPlan[combinationsKey];
          freqRange.forEach((eachFreq) => {
            passiveSet.add(eachFreq);
          });

          updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][addKey] =
            Array.from(passiveSet).sort((a, b) => a - b);
          this.props.dispatch(setUserRequestForm(updateSelected));
        }
      }
    };
    return onClickAdd;
  };

  _addInput = (testType, fieldName) => {
    const onClickAdd = () => {
      const { userSelectForm } = this.props.requestFormReducer;

      let updateSelected = { ...userSelectForm };
      const testPlan = updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY];
      const addKey = `${testType}${constants.ADD_SUFFIX}`;

      if (testType === constants.TEST_PLAN_PASSIVE_TESTITEM) {
        let passiveSet: Set<number> = new Set(
          addKey in updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY]
            ? updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][addKey]
            : []
        );

        testPlan[testType][fieldName] !== undefined &&
          passiveSet.add(Number(testPlan[testType][fieldName]));

        updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][addKey] =
          Array.from(passiveSet).sort((a, b) => a - b);
        this.props.dispatch(setUserRequestForm(updateSelected));
      }
    };
    return onClickAdd;
  };

  _renderCustomAddPlan = (testType) => {
    if (testType === constants.TEST_PLAN_PSEUDO_ACTIVE_TESTITEM) {
      return <PseudoActiveAddPlan />;
    } else if (
      testType === constants.TEST_PLAN_SIGNALING_TRP_TESTITEM ||
      testType === constants.TEST_PLAN_SIGNALING_TIS_TESTITEM
    ) {
      return <SignalingAddPlan testitem={testType} />;
    }
  };

  render() {
    let addButton = false;
    const { testDetail, testType } = this.props;
    const formatTechLevel = flatNestedObject(testDetail);
    // TODO, filter by keys and split to lvls in UI div
    const fieldMap = splitFieldByKey(formatTechLevel);
    // For each category i.e. channel, antenna, technologies-wifi
    const mapTestField = fieldMap.map((eachKey, fieldIndex) => {
      const currentFormatTech = filterTechKeys(formatTechLevel, eachKey);
      return (
        <div key={fieldIndex} className={"test-plan-detail"}>
          <div className={"test-type-lvl-label"}>
            {capitalizeFirstLetter(eachKey)}
          </div>
          <div className={"test-type-lvl-element"}>
            {currentFormatTech &&
              Object.keys(currentFormatTech).map((eachDetail, index) => {
                const testTypeObj = currentFormatTech[eachDetail];

                const hideCurrentFriendlyName =
                  testTypeObj[UI_MAP_LOOKUP.hideKey];

                const addButtonKeys =
                  ADD_BUTTON in testTypeObj ? testTypeObj[ADD_BUTTON] : [];
                addButton = addButtonKeys.length > 0 || addButton;
                const fieldProps =
                  "fieldProps" in testTypeObj ? testTypeObj["fieldProps"] : {};

                return (
                  <div key={index} className="test-type-group">
                    {UI_MAP_LOOKUP.uiType in testTypeObj &&
                      testTypeObj[UI_MAP_LOOKUP.uiType] && (
                        <RootField
                          uiType={testTypeObj[UI_MAP_LOOKUP.uiType]}
                          friendlyName={
                            hideCurrentFriendlyName
                              ? ""
                              : eachDetail.replace(`${eachKey}_`, "")
                          }
                          fieldName={eachDetail}
                          testType={testType}
                          testDetailIndex={index}
                          selectListContains={
                            testTypeObj[UI_MAP_LOOKUP.optionValues]
                          }
                          fieldProps={fieldProps}
                        ></RootField>
                      )}
                    {
                      // Pass add button on click confirm util
                      addButtonKeys.length > 1 && (
                        <div>
                          <Button
                            id={testType}
                            onClick={this._addRange(addButtonKeys, testType)}
                          >
                            Add Range
                          </Button>
                        </div>
                      )
                    }
                    {addButtonKeys.length === 1 && (
                      <div>
                        <Button
                          id={testType}
                          onClick={this._addInput(testType, eachDetail)}
                        >
                          Add Input
                        </Button>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      );
    });
    return (
      <div>
        {mapTestField}
        {testDetail[UI_MAP_LOOKUP.showCombinations] && !addButton && (
          <CombineKeys requestFormKey={testType}></CombineKeys>
        )}
        {addButton && <AddGroup requestFormKey={testType}></AddGroup>}
        {testDetail[UI_MAP_LOOKUP.customAddPlan] &&
          this._renderCustomAddPlan(testType)}

        {testDetail[UI_MAP_LOOKUP.horizontalLine] && <hr />}

        {/* valid user view for lookup table test plan filter */}

        {COMPLIANCE_VALID_LIST.includes(testType) && (
          <div>
            <LookupTestPlanCombine testType={testType} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(TestPlanWrapUp);
