import React from "react";
import Layout from "../layout/layout";

const NotFoundPage = () => (
  <Layout>
    <div
      style={{
        textAlign: "center",
        marginTop: "80px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ fontSize: "80px" }}>
        <i className="fas fa-bug" style={{ color: "#0094D2" }}></i>
        <span style={{ color: "#84C8E4" }}> 404</span>
      </div>
      <div style={{ fontSize: "40px", fontWeight: "bold" }}>Page not found</div>
      <div style={{ fontSize: "16px", marginTop: "20px", width: "800px" }}>
        We're sorry, the page you requested could not be found. Please go back
        to the homepage or contact us at wise-dev@amazon.com
      </div>
    </div>
  </Layout>
);
export default NotFoundPage;
