import React, { Component } from "react";
import {
  Table,
  Button,
  TablePropertyFiltering,
  TablePagination,
  TableSorting,
  TablePreferences,
  TablePageSizeSelector,
  TableWrapLines,
  TableContentSelector,
} from "@amzn/awsui-components-react/polaris";
import { customizedFilteringFunction } from "../../../components/table/property-filtering-utils";
import {
  defaultPageSize,
  columnDefinitions,
  defaultSortingColumn,
  sortableColumns,
  visibleColumns,
  //   filteringOptions,
} from "./detail-table-config";
import constants from "../../../config/constants";

// declare prop check
type Props = {
  data: Array<any>;
  loadingStatus: number;
  getStatusData: (syncMode: boolean) => void;
} & typeof defaultProps;

type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  currentPageIndex: 1,
  pagesCount: 0,
  selectedItems: [] as any[],
});

class RequestDetailTable extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _onPaginationChange = (event) => {
    const { currentPageIndex, pagesCount } = event.detail;
    this.setState({
      currentPageIndex,
      pagesCount,
    });
  };

  _onSelectionChange = (event) => {
    const { selectedItems } = event.detail;
    this.setState({ selectedItems });
  };

  render() {
    const { data, loadingStatus, getStatusData } = this.props;

    return (
      <Table
        loadingText="Loading resources"
        columnDefinitions={columnDefinitions}
        loading={loadingStatus === constants.LOADING_LOAD}
        items={data}
        // features={features}
        header={
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>
                Request Status Detail &nbsp;
                <span className="awsui-util-header-counter">{`(${data.length})`}</span>
              </h2>
            </div>
            <div className="awsui-util-action-stripe-group">
              <Button
                icon="refresh"
                onClick={() => getStatusData(false)}
              ></Button>
            </div>
          </div>
        }
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No resources</b>
            </div>
            <p className="awsui-util-mb-s">No resources to display.</p>
          </div>
        }
        noMatch={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No matches</b>
            </div>
            <p className="awsui-util-mb-s">We can’t find a match.</p>
            <div className="awsui-util-mb-m">
              <Button>Clear filter</Button>
            </div>
          </div>
        }
      >
        <TablePropertyFiltering
          //   filteringOptions={filteringOptions}
          filteringFunction={customizedFilteringFunction}
          groupValuesText="Values"
          groupPropertiesText="Properties"
          operationAndText="and"
          operationNotAndText="and not"
          operationOrText="or"
          operationNotOrText="or not"
          clearFiltersText="Clear filter"
          placeholder="Filter resources by property or value"
          allowFreeTextFiltering={true}
          filteringCountTextFunction={(count) =>
            `${count} ${count === 1 ? "match" : "matches"}`
          }
        ></TablePropertyFiltering>
        <TablePagination
          pageSize={defaultPageSize}
          onPaginationChange={this._onPaginationChange}
        ></TablePagination>
        <TableSorting
          sortingColumn={defaultSortingColumn}
          sortableColumns={sortableColumns}
          sortingDescending={true}
        ></TableSorting>
        <TablePreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
        >
          <TablePageSizeSelector
            title="Page size"
            options={[
              { value: 5, label: "5 items" },
              { value: 10, label: "10 items" },
              { value: 20, label: "20 items" },
            ]}
          />
          <TableWrapLines
            label="Wrap lines"
            description="Enable to wrap table cell content, disable to truncate text."
          />
          <TableContentSelector
            title="Select visible columns"
            options={visibleColumns}
          />
        </TablePreferences>
      </Table>
    );
  }
}

export default RequestDetailTable;
