import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import constants, { COMPLIANCE_VALID_LIST } from "../../../../config/constants";
import { filterValidPlan, complianceCombineKeys } from "./valid-plan-util";
import ValidDetailHelpView from "./valid-detail-help-view";
import "./valid-lookup-plan.css";

import {
  Modal,
  Button,
  Box,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";

interface StateProps {
  requestFormReducer: any;
}

const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  visible: false,
});

type Props = { dispatch: Dispatch<any>; testType: string } & StateProps;

class LookupTestPlanCombine extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  render() {
    const { lookupTestPlan, userSelectForm } = this.props.requestFormReducer;
    const { testType } = this.props;

    const currentTestPlan =
      userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY];

    // user selected some items in testType, then we show combine
    if (
      (testType in currentTestPlan &&
        testType === COMPLIANCE_VALID_LIST[0] &&
        constants.TEST_PLAN_COUNTRY in currentTestPlan[testType]) ||
      (testType in currentTestPlan && testType !== COMPLIANCE_VALID_LIST[0])
    ) {
      const combinedAllKeys = complianceCombineKeys(currentTestPlan[testType]);
      const { validResult, invalidResult } = filterValidPlan(
        combinedAllKeys,
        lookupTestPlan
      );

      return (
        <div>
          <span className={"compliance-valid-combine-info"}>
            Lookup test plan confirmed area:
          </span>
          <button
            className={"icon-btn"}
            onClick={() => this.setState({ visible: true })}
          >
            <span style={{ color: "#0273BB" }}>(what is this validation?)</span>
          </button>

          <div>
            <Modal
              onDismiss={() => this.setState({ visible: false })}
              visible={this.state.visible}
              closeAriaLabel="Close modal"
              size="medium"
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="primary"
                      onClick={() => this.setState({ visible: false })}
                    >
                      Ok
                    </Button>
                  </SpaceBetween>
                </Box>
              }
              header="Compliance Lookup test plan validation"
            >
              <ValidDetailHelpView />
            </Modal>
          </div>

          <div className={"compliance-valid-combine"}>
            <div className={"compliance-validPlan"}>
              <div className={"compliance-valid-plan-text"}>Valid Plan</div>
              <div className={"compliance-validPlan-each"}>
                {validResult.map((eachPlan, index) => (
                  <div key={index} className={"compliace-valid-each"}>
                    {eachPlan}
                  </div>
                ))}
              </div>
            </div>
            <div className={"compliance-validPlan"}>
              <div className={"compliance-invalid-plan-text"}>Invalid Plan</div>
              <div className={"compliance-validPlan-each"}>
                {invalidResult.map((eachPlan, index) => (
                  <div key={index} className={"compliace-valid-each"}>
                    {eachPlan}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {Array.isArray(testType) && testType.length === 0 && (
          <div>
            Current valid Lookup plan is empty, please connect wise team via
            issue SIM
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(LookupTestPlanCombine);
