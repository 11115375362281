import constants from "../../../../config/constants";

const SPLIT_BY = ", ";

// const exampleData for lookupPlan = [
//   {
//     antenna: "0",
//     bandwidth: "20",
//     country: "us",
//     id: "3", // no need
//     mode: "b",
//     technology: "wifi", // no need, but we only filter wifi
//   },
// ];
export const filterValidPlan = (userSelectList, lookupPlan) => {
  const validResult = [];
  const invalidResult = [];

  // format lookup table data which get from serverside
  const filterArray = [];
  const filterArrayWithCountry = [];

  if (Array.isArray(lookupPlan)) {
    lookupPlan.forEach((eachItem) => {
      if (eachItem["technology"] === "wifi" && !hasNull(eachItem)) {
        const currentItem = `${eachItem["mode"]}${SPLIT_BY}${eachItem["bandwidth"]}${SPLIT_BY}${eachItem["antenna"]}`;
        const currentItemWithCountry = `${eachItem["country"]}${SPLIT_BY}${eachItem["mode"]}${SPLIT_BY}${eachItem["bandwidth"]}${SPLIT_BY}${eachItem["antenna"]}`;
        filterArray.push(currentItem);
        filterArrayWithCountry.push(currentItemWithCountry);
      }
    });
  }

  const filterType = userSelectList && userSelectList[0].split(SPLIT_BY).length;
  let filterUsedArray = [];
  if (filterType === 4) {
    // filterArrayWithCountry: country,mode, bandwidth, antenna
    filterUsedArray = filterArrayWithCountry;
  }
  if (filterType === 3) {
    // filterArray; mode, bandwidth, antenna
    filterUsedArray = filterArray;
  }

  userSelectList &&
    userSelectList.forEach((eachSelect) => {
      if (filterUsedArray.includes(eachSelect)) {
        validResult.push(eachSelect);
      } else {
        invalidResult.push(eachSelect);
      }
    });

  return { validResult, invalidResult };
};

// inputObject example: {
// countries: ["us"]
// technologies_wifi_antenna: (2) ["0", "0+1"]
// technologies_wifi_bandwidth: (2) [40, 20]
// technologies_wifi_mode: (2) ["b", "g"]
// }
export const complianceCombineKeys = (inputObject) => {
  const orderArray = [];
  if (inputObject) {
    let orderKeyList = [];
    if (constants.TEST_PLAN_COUNTRY in inputObject) {
      orderKeyList = constants.COMPLIANCE_VALID_KEY_ORDER;
    } else {
      orderKeyList = constants.COMPLIANCE_VALID_KEY_ORDER.filter(
        (item) => item !== constants.TEST_PLAN_COUNTRY
      );
    }

    if (orderKeyList.length > 0) {
      Object.keys(inputObject).forEach((eachKey) => {
        orderKeyList.forEach((eachOrderKeyword, index) => {
          if (eachKey.includes(eachOrderKeyword)) {
            orderArray[index] = inputObject[eachKey].map(String);
          }
        });
      });
    }
  }

  return combine(orderArray);
};

const combine = ([head, ...[headTail, ...tailTail]]) => {
  if (!headTail) return head;
  const combined = headTail.reduce((acc, x) => {
    return acc.concat(head && head.map((h) => `${h}${SPLIT_BY}${x}`));
  }, []);

  return combine([combined, ...tailTail]);
};

const hasNull = (targetObject) => {
  Object.keys(targetObject).forEach((eachKey) => {
    if (targetObject[eachKey] === null || targetObject[eachKey] === undefined)
      return true;
  });
  return false;
};
