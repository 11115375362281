/*
    Why we have this file?
    Polaris table property filtering doesn't not support cell value other than string.
    If we convert type number to type string, then the sort of polaris table doesn't work.

    Workaround:
    As a workaround, we defined our own filteringFunction, which is copied 
    from Polaris source code for most of the parts, and made a little changes to support number.
    To keep it simple, most the function names and variable are the same as the source code.

    Long term solution:
    This is definitely a problem of polaris table property filtering.
    We can try fix in the source code, and submit a pull request to polaris team to push them to fix it.
*/
enum FilteringOperation {
  AND = "and",
  OR = "or",
}

export const customizedFilteringFunction = (
  item,
  tokens,
  operation
): boolean => {
  return (
    !tokens.length ||
    tokens.reduce((include, token) => {
      const opFn = getOperationFn(operation);
      const strategy = token.isFreeText
        ? partialMatchStategy
        : fullMatchStategy;
      const eqFn = getMatchFn(token.negated, strategy);

      return opFn(include, eqFn(token, item));
    }, operation === FilteringOperation.AND)
  );
};

const getOperationFn: Function = (operation) => {
  switch (operation) {
    case FilteringOperation.OR:
      return (value1: boolean, value2: boolean) => value1 || value2;
    case FilteringOperation.AND:
    default:
      // This default has been intentionally added as defensive programming to catch
      // unsupported operations passed in my customers of the API
      return (value1: boolean, value2: boolean) => value1 && value2;
  }
};

const getMatchFn: Function = (negated: boolean, matchStrategy: Function) => {
  return negated ? (a, b) => !matchStrategy(a, b) : matchStrategy;
};

const fullMatchStategy = (token, item) => {
  return token.value === item[token.propertyKey];
};

const partialMatchStategy = (token, item) => {
  const searchText = token.value.toLowerCase();
  const searchableProps = token.propertyKey
    ? [token.propertyKey]
    : Object.keys(item);

  return searchableProps.some((prop) => {
    switch (typeof item[prop]) {
      case "string":
        return item[prop].toLowerCase().indexOf(searchText) !== -1;
      // edit by ourselves to support number/boolean
      case "number":
        return !isNaN(searchText) && item[prop] === Number(searchText);
      case "boolean":
        return item[prop].toString().toLowerCase() === searchText;
      default:
        return false;
    }
  });
};
