import React, { Component } from "react";
import { Select, FormField } from "@amzn/awsui-components-react/polaris";
// import { capitalizeFirstLetter } from "../../../../utils/general-utils";

interface StateProps {}

// declare prop check
type Props = {
  friendlyName: string;
  helpText: string;
  fieldValue: string;
  selectListContains: Array<any>;
  handleOnChange: (e: any) => void;
} & StateProps;

class SelectField extends Component<Props> {
  render() {
    const {
      friendlyName,
      helpText,
      fieldValue,
      handleOnChange,
      selectListContains,
    } = this.props;

    const selectOptions =
      selectListContains && Array.isArray(selectListContains)
        ? selectListContains.map((eachKey) => {
            return {
              id: eachKey,
              label: eachKey,
            };
          })
        : [];

    return (
      <div className="test-plan-field-select">
        <FormField
          label={friendlyName !== "" && `${friendlyName} :`}
          stretch={true}
        >
          <div className="test-plan-field-element">
            <Select
              id={helpText}
              options={selectOptions}
              empty="No options"
              placeholder={friendlyName !== "" ? friendlyName : helpText}
              selectedLabel="Selected"
              selectedOption={
                fieldValue ? { id: fieldValue, label: fieldValue } : null
              }
              onChange={handleOnChange}
              filteringType="auto"
            ></Select>
          </div>
        </FormField>
      </div>
    );
  }
}

export default SelectField;
