import React, { useState } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  Table,
  Button,
  Pagination,
  TextFilter,
  Icon,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";
import { COLUMN_DEFINITIONS, DEFAULT_PREFERENCES, Preferences } from "./table-config";
import {
  TableEmptyState,
  EmptyState,
  getMatchesCountText,
  paginationLabels,
} from "../../../components/polaris/table/common-components";
import { exportCSVFunc } from "@amzn/lens-react-ui";

const UploadHistoryTable = ({ data, loading }) => {
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } =
    useCollection(data, {
      filtering: {
        empty: <TableEmptyState resourceName={"File"} />,
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering("")}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    });

  return (
    <Table
      {...collectionProps}
      trackBy="id"
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      loading={loading}
      loadingText="Loading resources"
      visibleColumns={preferences.visibleContent}
      empty={<TableEmptyState resourceName={"File"} />}
      header={
        <Header
          counter={`(${data.length})`}
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="normal" onClick={() => exportCSVFunc(data, "supported files")}>
                <Icon name="download" size="normal" variant="normal" />
                &nbsp;Export
              </Button>
            </SpaceBetween>
          }
        >
          Supported File For Upload
        </Header>
      }
      resizableColumns={true}
      wrapLines={preferences.wraplines}
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount)}
          filteringAriaLabel="Filter project"
        />
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      preferences={
        <Preferences
          preferences={preferences}
          disabled={loading}
          setPreferences={(detail) => {
            setPreferences(detail);
          }}
        />
      }
    />
  );
};

export default UploadHistoryTable;
