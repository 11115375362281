const callbackUrl = window.location ? window.location.origin : "/";

const base_config = {
  // Cognito common settings
  TOKEN_SCOPES_ARRAY: ["openid"],
  REGION: "us-west-2",
  OAUTH_RESPONSE_TYPE: "code",
  CALL_BACK_URL: callbackUrl,
  REFRESH_TOKEN_VALIDITY: 1,
};
export default base_config;
