export const Breadcrumb = (assignmentId, testCategoryId) => {
  return [
    { text: "Home", href: "/" },
    { text: "Third Party Lab", href: "/third-party-lab" },
    {
      text: "Assignment : " + assignmentId,
      href: `/third-party-lab/assignments/${assignmentId}`,
    },
    {
      text: "Test Category : " + testCategoryId,
      href: `/third-party-lab/assignments/${assignmentId}/test_categories/${testCategoryId}`,
    },
  ];
};

