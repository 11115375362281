import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import constants from "../../../../config/constants";
import { Button } from "@amzn/awsui-components-react/polaris";
import { setUserRequestForm } from "../../../../redux/actions/request-form-action";
// import { capitalizeFirstLetter } from "../../../utils/general-utils";
import "../combine-addon/combine-key-style.css"
interface StateProps {
  requestFormReducer: any;
}

// declare prop check
type Props = { dispatch: Dispatch<any> } & StateProps;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({});

// this add combine key component is unique for RvR compare with Desense and Compliance
class PseudoActiveFinalCombineKeys extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  onClickRemove = (event) => {
    const { userSelectForm } = this.props.requestFormReducer;
    let updateSelected = { ...userSelectForm };
    const removeKey = event.target.id;
    const key =  `${constants.TEST_PLAN_PSEUDO_ACTIVE_TESTITEM}${constants.ADD_SUFFIX}`
    if (
      key in
      updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY]
    ) {
      // if exist
      delete updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][key][removeKey];
    }
    this.props.dispatch(setUserRequestForm(updateSelected));
  };

  render() {
    const { userSelectForm } = this.props.requestFormReducer;
    const key =  `${constants.TEST_PLAN_PSEUDO_ACTIVE_TESTITEM}${constants.ADD_SUFFIX}`
    const pseudoActiveCombineObj =
      userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY] &&
      key in
        userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY]
        ? userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY][key]
        : {};

    const mappedCombineKey = Object.keys(pseudoActiveCombineObj).map(
      (eachKey, index) => {
        const currValues = pseudoActiveCombineObj[eachKey];
        const splitedArray = eachKey.split(constants.TEST_COMBINE_KEY);
        const currTech = Array.isArray(splitedArray) && splitedArray.length > 1
            ? splitedArray[0]
            : undefined;
        const currChannelsStr = Array.isArray(splitedArray) && splitedArray.length > 1
            ? splitedArray[1]
            : undefined;


        return (
          <div key={index} className={"each-combine"}>
            <div className={"each-combine-remove-btn"}>
              <Button id={eachKey} onClick={this.onClickRemove}>
                Remove
              </Button>
            </div>
            <div className={"combine-inner-key-label"}>
              Technology: {currTech}
            </div>
            <div className={"combine-inner-key-label"}>
              Channel: {currChannelsStr}
            </div>
            <hr />
            <div>
                {currValues.map((ele, index) => {
                  return (
                    <div key={index}>
                      {ele}
                    </div>
                  );
                })}
              </div>
            <br />
          </div>
        );
      }
    );

    return (
      <div className={"rvr-confirmed-combine-container"}>
        {mappedCombineKey}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(PseudoActiveFinalCombineKeys);
