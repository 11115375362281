import React from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  SpaceBetween,
  ColumnLayout,
} from "@amzn/awsui-components-react-v3";
import { redirectToLoginPage } from "../authentication/cognito-auth";
import constants from "../config/constants";

const SignIn = () => {
  const history = useHistory();

  const onClickSignIn = (idp) => {
    history.push("/");
    redirectToLoginPage(idp);
  };

  return (
    <ColumnLayout columns={2} variant="text-grid">
      {/* col 1 */}
      <SpaceBetween direction="vertical" size="xs">
        <div>Sign In For Amazon Employees and Contractors</div>
        <Button
          variant="normal"
          className="app-button"
          onClick={() => onClickSignIn(constants.IDP_AMAZON_FEDERATE)}
        >
          {/* Cognito + Amazon Federate */}
          Continue with Midway
        </Button>
      </SpaceBetween>

      {/* col 2*/}
      {/* not release now */}
      <SpaceBetween direction="vertical" size="xs">
        <div>Sign In For Lab Users</div>
        <Button
          variant="normal"
          className="app-button"
          onClick={() => {
            onClickSignIn(constants.IDP_LASSO);
          }}
        >
          Continue with LASSO
          {/* Cognito + LASSO */}
        </Button>
      </SpaceBetween>
    </ColumnLayout>
  );
};

export default SignIn;
