import requestFormType from "../types/request-form-type";
import endpoints from "../../config/endpoints";
import { config } from "../../config/config";
import {
  METRIC_INFO,
  USER_REQUEST_METRICS_STATUS,
} from "../../config/constants";

export const setResponseRequestForm = (requestForm) => ({
  type: requestFormType.SET_REQUEST_FORM,
  requestForm,
});

export const setLabList = (labList) => ({
  type: requestFormType.SET_LAB_LIST,
  labList,
});

export const setLookupTestPlan = (lookupTestPlan) => ({
  type: requestFormType.SET_MASTER_TEST_PLAN,
  lookupTestPlan,
});

export const setUserRequestForm = (userSelectForm) => ({
  type: requestFormType.SET_USER_SELECT_FORM,
  userSelectForm,
});

export const createRequestForm = (json) => ({
  type: requestFormType.CREATE_REQUEST_FORM_CALL,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.BASE_URL + endpoints.createRequestFormEndpoint(),
        method: "POST",
        json: json,
      },
      commit: {
        type: requestFormType.CREATE_REQUEST_FORM_CALL_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.CREATE_REQUEST_FORM,
          status: USER_REQUEST_METRICS_STATUS.SUCCESS,
        },
      },
      rollback: {
        type: requestFormType.CREATE_REQUEST_FORM_CALL_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.CREATE_REQUEST_FORM,
          status: USER_REQUEST_METRICS_STATUS.FAIL,
        },
      },
    },
  },
});
