import constants from "../../../../config/constants";

// used only in RvR combine
const RVR_WIFI_COMBINE_PREFIX = "Wifi";
const RVR_COMBINE_KEY = "+";

export const WIFI_PLUS_KEY = `${RVR_WIFI_COMBINE_PREFIX}${RVR_COMBINE_KEY}`;
// in RvR user request ORDER combined
// "A2DP"+"Zigbee"+"BLE"+"HFP";
const RVR_A2DP = "A2DP";
const RVR_ZIGBEE = "Zigbee";
const RVR_SUFFIX_ARRAY = ["BLE", "HFP"];

export const rvrCombineKeyHandler = (testPlan) => {
  let combineList: string[] = [];

  // not valid input
  if (
    typeof testPlan !== "object" ||
    !(constants.TEST_PLAN_RVR_TESTITEM in testPlan)
  ) {
    return combineList;
  }

  const testItemObject = testPlan[constants.TEST_PLAN_RVR_TESTITEM];

  // because rvr has a2dp and zigbee only & requested combine one by one
  let rvrA2DP = "";
  let rvrZigbee = "";
  let suffixSet = new Set();
  Object.keys(testItemObject).forEach((eachItem) => {
    if (eachItem.includes(RVR_A2DP)) {
      rvrA2DP = eachItem;
    }
    if (eachItem.includes(RVR_ZIGBEE)) {
      rvrZigbee = eachItem;
    }
    if (RVR_SUFFIX_ARRAY.some((el) => eachItem.includes(el))) {
      suffixSet.add(eachItem);
    }
  });

  const loopA2DP = testItemObject[rvrA2DP] ? testItemObject[rvrA2DP] : [];
  const loopZB = testItemObject[rvrZigbee] ? testItemObject[rvrZigbee] : [];

  if (loopA2DP.length > 0 && loopZB.length > 0) {
    loopA2DP.forEach((eachA2DP) => {
      loopZB.forEach((eachZb) => {
        const currentCombineKey = `${RVR_WIFI_COMBINE_PREFIX}${RVR_COMBINE_KEY}${eachA2DP}${RVR_COMBINE_KEY}${eachZb}`;
        combineList.push(currentCombineKey);
      });
    });
  } else if (
    (loopA2DP.length > 0 && loopZB.length === 0) ||
    (loopA2DP.length === 0 && loopZB.length > 0)
  ) {
    [...loopA2DP, ...loopZB].forEach((eachEle) => {
      const currentCombineKey = `${RVR_WIFI_COMBINE_PREFIX}${RVR_COMBINE_KEY}${eachEle}`;
      combineList.push(currentCombineKey);
    });
  }

  suffixSet.forEach((eachSuffix: any) => {
    const formatEachSuffix = eachSuffix.split(constants.TEST_COMBINE_KEY)[
      eachSuffix.split(constants.TEST_COMBINE_KEY).length - 1
    ];

    combineList =
      combineList.length === 0 ? [`${RVR_WIFI_COMBINE_PREFIX}`] : combineList;

    const currentSuffixArray = combineList.map(
      (key) => `${key}${RVR_COMBINE_KEY}${formatEachSuffix}`
    );
    combineList = [...combineList, ...currentSuffixArray];
  });

  // clean up remove RVR_WIFI_COMBINE_PREFIX for combineList = 0 case
  const index = combineList.indexOf(RVR_WIFI_COMBINE_PREFIX, 0);
  if (index > -1) {
    combineList.splice(index, 1);
  }

  return combineList;
};

// format rvr request form value into confirmed combine field and used to send as
// rvr part of request json
export const formatRvRRequestJson = (testPlan, tokenList) => {
  // formatResult = {channel1_linkUp:techObject}
  const formatResult = {};
  // techObject = { Wifi_Only:"True", "Wifi+":["Wifi+A2DPSrcMaster+ZigbeeMonitor", ...]}
  const techObject = {};

  if (
    typeof testPlan !== "object" ||
    !(constants.TEST_PLAN_RVR_TESTITEM in testPlan)
  ) {
    return formatResult;
  }
  const testItemObject = testPlan[constants.TEST_PLAN_RVR_TESTITEM];
  techObject[WIFI_PLUS_KEY] = tokenList;

  if (
    constants.RVR_CHANNEL_KEY in testItemObject &&
    constants.RVR_LINK_KEY in testItemObject
  ) {
    if (constants.RVR_WIFI_ONLY in testItemObject) {
      techObject[constants.RVR_WIFI_ONLY] =
        testItemObject[constants.RVR_WIFI_ONLY];
    }

    const key = `${testItemObject[constants.RVR_CHANNEL_KEY]}${
      constants.TEST_COMBINE_KEY
    }${testItemObject[constants.RVR_LINK_KEY]}`;

    formatResult[key] = techObject;
  } else {
    window.alert(
      "You need to select both CHANNEL and LINK fields for RvR to confirm & add as plan"
    );
  }

  return formatResult;
};
