import constants from "../../../config/constants";

const validFormJson = {
  power_table: ["countries", "technologies"],
  active_scan: ["countries", "technologies"],
  band_edge: ["technologies"],
  harmonics: ["technologies"],
  TestitemDesense: ["subsystem", "antenna", "band"],
  "active-TIS": ["channel", "technologies"],
  "active-TRP": ["channel", "technologies"],
  passive: ["antenna"],
};

const addCheckList = {
  passive: "passive-add",
  "active-pseudo-active": "active-pseudo-active-add",
};

// const techCheckList = ["wifi", "bt", "zigbee"];

const wifiCheckList = ["mode", "bandwidth", "antenna"];
const activeWifi = ["bands"];
const WIFI_KEY = "wifi";
const ACTIVE_KEY = "active_scan";

// don't valid items in this list, because of diff use case
const ignoreList = [
  constants.TEST_CATEGORY_KEY_RVR,
  constants.TEST_PLAN_RVR_TESTITEM,
  constants.TEST_CATEGORY_KEY_DESENSE,
  constants.TEST_CATEGORY_KEY_ANTENNA,
];

[
  constants.TEST_PLAN_PASSIVE_TESTITEM,
  constants.TEST_PLAN_PSEUDO_ACTIVE_TESTITEM,
  constants.TEST_PLAN_SIGNALING_TIS_TESTITEM,
  constants.TEST_PLAN_SIGNALING_TRP_TESTITEM,
].forEach((eachItem) => {
  ignoreList.push(`${eachItem}${constants.COMBINATIONS_SUFFIX}`);
  ignoreList.push(`${eachItem}${constants.ADD_SUFFIX}`);
});

const isSubArray = (ArrayOne, ArrayTwo) => {
  // if array two is sub array of array one
  if (ArrayOne && ArrayTwo) {
    return ArrayTwo.every((val) => ArrayOne.includes(val));
  }
};

export const validTestPlan = (testPlanObject) => {
  let validResult = true;
  let message = "";

  Object.keys(testPlanObject).forEach((eachTestPlan) => {
    // eachTestPlan -> power_table
    if (!ignoreList.includes(eachTestPlan)) {
      if (
        !(eachTestPlan in validFormJson) ||
        (testPlanObject[eachTestPlan] &&
          isSubArray(
            Object.keys(testPlanObject[eachTestPlan]),
            validFormJson[eachTestPlan]
          ))
      ) {
        Object.keys(testPlanObject[eachTestPlan]).forEach((eachItem) => {
          if (eachItem === constants.TEST_PLAN_COUNTRY) {
            if (testPlanObject[eachTestPlan][eachItem].length === 0) {
              validResult = false;
              message = `Did not select any value for ${eachTestPlan} ${eachItem}`;
            }
          }
          if (eachItem === constants.TEST_PLAN_TECH) {
            // tech
            const techObject = testPlanObject[eachTestPlan][eachItem];
            Object.keys(techObject).forEach((eachTech) => {
              if (eachTech === WIFI_KEY) {
                if (
                  !isSubArray(
                    Object.keys(techObject[eachTech]),
                    eachTestPlan === ACTIVE_KEY ? activeWifi : wifiCheckList
                  )
                ) {
                  validResult = false;
                  message = `Need to select all sub dropdown for ${eachTestPlan} ${eachItem}`;
                }
              }
            });
          }
        });
      } else {
        // did no select all sub dropdown
        validResult = false;
        message = `Did not select all sub dropdown for ${eachTestPlan}`;
      }
      if (eachTestPlan in addCheckList) {
        if (
          !(addCheckList[eachTestPlan] in testPlanObject) ||
          testPlanObject[addCheckList[eachTestPlan]] === {}
        ) {
          validResult = false;
          message = `Select options but did not add plan for ${eachTestPlan}`;
        }
      }
    }
  });
  return { validResult, message };
};

export const validAssignment = (assignmentsList) => {
  let valid = false;
  if (assignmentsList && Array.isArray(assignmentsList)) {
    if (assignmentsList.length > 0) {
      assignmentsList.forEach((eachAssignment) => {
        if (constants.ASSIGN_DSN_KEY in eachAssignment) {
          if (eachAssignment[constants.ASSIGN_DSN_KEY].length > 0) {
            valid = true;
          }
        }
      });
    }
  }

  if (valid === false) {
    window.alert("You must select Assignment DSN + LAB + FIRMWARE");
  }
  return valid;
};
