import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  Button,
  Input,
  FormField,
  Select,
  TokenGroup,
} from "@amzn/awsui-components-react/polaris";
import constants, {
  DROPDOWN_NAMING_COLLECTION,
} from "../../../config/constants";
import {
  convertLabMap,
  getKeyByValue,
  mergeAssignArray,
  existedDSN,
} from "./assign-lab-helper-utils";
import { setUserRequestForm } from "../../../redux/actions/request-form-action";
import { capitalizeFirstLetter } from "../../../utils/general-utils";
import "./assign-lab-dsn-style.css";

interface StateProps {
  requestFormReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  labOptions: [] as any[],
  labSelected: "",
  dsn: "",
  dsnError: "",
  firmware: "",
});

class AssignInput extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  // constructor(props) {
  //   super(props);
  // }

  // add value to reducer form
  _assignToLab = () => {
    const { dsn, firmware, labSelected } = this.state;
    const { userSelectForm, labList } = this.props.requestFormReducer;
    this._validate("", dsn);

    if (this._canAdd()) {
      const updateSelected = { ...userSelectForm };
      const labLookup = convertLabMap(labList);
      const currentLabKey = getKeyByValue(labLookup, labSelected);

      const addedObject = {
        [constants.ASSIGN_LAB_KEY]: currentLabKey,
        [constants.ASSIGN_DSN_KEY]: [
          {
            id: dsn,
            firmware: firmware,
          },
        ],
      };

      // assignArray
      updateSelected[constants.REQUEST_FORM_ASSIGN_KEY] = mergeAssignArray(
        updateSelected[constants.REQUEST_FORM_ASSIGN_KEY],
        addedObject
      );
      this.props.dispatch(setUserRequestForm(updateSelected));
    }
  };

  _inputOnChange = (event: any) => {
    const currentValue: string = event.detail.value;
    const key = event.target.id;
    this._validate(key, currentValue);
    this.setState({
      [key]: currentValue,
    });
  };

  // bool check for adding to reducer
  _canAdd = () => {
    const { dsn } = this.state;
    const { userSelectForm } = this.props.requestFormReducer;
    // valid for new input value, must not existed in submitted form
    const existedDSNArray = existedDSN(
      userSelectForm[constants.REQUEST_FORM_ASSIGN_KEY]
    );

    if (existedDSNArray.includes(dsn)) {
      return false;
    } else {
      return true;
    }
  };

  _validate = (key: any, currentValue: string) => {
    const { dsn } = this.state;
    const currentDSN = key === constants.DSN_KEY ? currentValue : dsn;
    const { userSelectForm } = this.props.requestFormReducer;
    // valid for new input value, must not existed in submitted form

    // check existe DSN
    const existedDSNArray = existedDSN(
      userSelectForm[constants.REQUEST_FORM_ASSIGN_KEY]
    );
    if (existedDSNArray.includes(currentDSN)) {
      this.setState({
        dsnError: "Already assigned this DSN to a lab",
      });
    } else {
      this.setState({
        dsnError: "",
      });
    }
  };

  _selectOnChange = ({
    detail: {
      selectedOption: { id },
    },
  }) => {
    this.setState({
      labSelected: id,
    });
  };

  _onAddressDismiss = (event: any) => {
    const { userSelectForm } = this.props.requestFormReducer;
    const currentSetting = { ...userSelectForm };
    if (currentSetting.assignments) {
      const currentList = currentSetting.assignments[parseInt(event.target.id)];
      if (currentList) {
        currentSetting.assignments[parseInt(event.target.id)][
          constants.ASSIGN_DSN_KEY
        ] = [
          ...currentList[constants.ASSIGN_DSN_KEY].slice(
            0,
            event.detail.itemIndex
          ),
          ...currentList[constants.ASSIGN_DSN_KEY].slice(
            event.detail.itemIndex + 1
          ),
        ];

        this.props.dispatch(setUserRequestForm(currentSetting));
      }
    }
  };

  render() {
    const { labList, labListLoadingStatus, userSelectForm } =
      this.props.requestFormReducer;
    const { dsn, firmware, dsnError, labSelected } = this.state;
    const labLookup = convertLabMap(labList);

    const assignCollectList = userSelectForm.assignments
      ? userSelectForm.assignments
      : [];

    const assignedList = assignCollectList.map((eachItem, index) => {
      return (
        <div key={index} className={"dsn-token-group"}>
          <div className="dsn-token-label">{labLookup[eachItem.lab_id]}:</div>
          <div className="dsn-token-values">
            <TokenGroup
              id={index}
              items={
                eachItem.dsn_list === undefined
                  ? []
                  : eachItem.dsn_list.map((object) => {
                      return {
                        id: `${object.id}-${object.firmware}`,
                        label: `${object.id}-${object.firmware}`,
                      };
                    })
              }
              onDismiss={this._onAddressDismiss}
            ></TokenGroup>
          </div>
        </div>
      );
    });

    return (
      <div>
        <div className={"dsn-to-lab"}>
          {/* dsn */}
          <FormField
            label={`${DROPDOWN_NAMING_COLLECTION.dsn}`}
            errorText={dsnError}
            stretch={true}
          >
            <div className={"dsn-to-lab-element"}>
              <Input
                id={"dsn"}
                placeholder={DROPDOWN_NAMING_COLLECTION.dsn}
                value={dsn}
                onChange={this._inputOnChange}
              ></Input>
            </div>
          </FormField>

          {/* lab select */}
          <FormField label={`${DROPDOWN_NAMING_COLLECTION.lab}`} stretch={true}>
            <div className={"dsn-to-lab-element"}>
              <Select
                id={"lab"}
                empty="No options"
                placeholder={"Lab List"}
                selectedLabel="Selected"
                options={
                  labList && Array.isArray(labList)
                    ? labList.map((eachLab) => {
                        return { id: eachLab.name, label: eachLab.name };
                      })
                    : []
                }
                statusType={
                  labListLoadingStatus === constants.LOADING_LOAD
                    ? constants.SELECT_STATUS_TYPE_LOADING
                    : null
                }
                selectedOption={
                  labSelected
                    ? {
                        id: labSelected,
                        label: capitalizeFirstLetter(labSelected),
                      }
                    : null
                }
                onChange={this._selectOnChange}
                filteringType="auto"
              ></Select>
            </div>
          </FormField>
          {/* firmware */}
          <FormField
            label={<span>{DROPDOWN_NAMING_COLLECTION.firmware}</span>}
            stretch={true}
          >
            <div className={"dsn-to-lab-element"}>
              <Input
                id={"firmware"}
                placeholder={DROPDOWN_NAMING_COLLECTION.firmware}
                value={firmware}
                onChange={this._inputOnChange}
              ></Input>
            </div>
          </FormField>
          {/* add to token */}
          <div className={"dsn-to-lab-add-btn"}>
            <Button
              disabled={!(dsn && firmware && labSelected)}
              onClick={this._assignToLab}
            >
              Add
            </Button>
          </div>
        </div>
        {assignedList}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(AssignInput);
