import React from "react";
import constants from "../../../config/constants";
import styled from "styled-components";

const DeleteBtnIcon = styled.i`
  cursor: pointer;
  &:hover {
    color: grey;
  }
  &:active {
    transform: translateY(1px);
  }
`;
/*
    generate status icon
    given status, and message
    will show message when status is failed

    loading => spinner
    success => green check mark
    fail => red cross mark
*/
export const getIcon = (status: number, message: string = "") => {
  if (status === constants.LOADING_SUCCESS) {
    return (
      // file name check succeeds
      <i className="fa fa-check" style={{ color: "green" }} />
    );
  }
  if (status === constants.LOADING_FAIL) {
    // file name check fails
    return (
      <span style={{ color: "red" }}>
        <i className="fa fa-times" style={{ color: "red" }} />
        &nbsp;error: &nbsp;
        {message}
      </span>
    );
  }
  // file name is checking ...
  return <i className="fa fa-sync fa-spin" style={{ fontSize: "18px" }} />;
};

// delete button component
export const deleteButton = (onClick: (filename: string) => void, filename: string) => {
  return (
    <DeleteBtnIcon
      className="fas fa-trash-alt"
      onClick={() => {
        onClick(filename);
      }}
    ></DeleteBtnIcon>
  );
};
