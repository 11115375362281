import constants, { UI_MAP_LOOKUP } from "../../../config/constants";

// remove empty key value
export const removeEmpty = (obj) => {
  Object.keys(obj).forEach(
    (k) =>
      (obj[k] && typeof obj[k] === "object" && removeEmpty(obj[k])) ||
      (!obj[k] && obj[k] !== undefined && obj[k] !== {} && delete obj[k])
  );
  return obj;
};

export const flatNestedObject = (testDetail) => {
  const result = {};

  if (testDetail && Object.keys(testDetail)) {
    Object.keys(testDetail).forEach((eachKey) => {
      if (typeof testDetail[eachKey] !== "boolean") {
        const testTypeObj = testDetail[eachKey];

        if (UI_MAP_LOOKUP.uiType in testTypeObj) {
          // first level with key and value
          result[eachKey] = testTypeObj;
        } else {
          Object.keys(testTypeObj).forEach((eachTech) => {
            if (testTypeObj[eachTech]) {
              if (UI_MAP_LOOKUP.uiType in testTypeObj[eachTech]) {
                result[`${eachKey}${constants.TEST_COMBINE_KEY}${eachTech}`] =
                  testTypeObj[eachTech];
              } else {
                // tech level
                Object.keys(testTypeObj[eachTech]).forEach((eachItem) => {
                  if (testTypeObj[eachTech][eachItem]) {
                    result[
                      `${eachKey}${constants.TEST_COMBINE_KEY}${eachTech}${constants.TEST_COMBINE_KEY}${eachItem}`
                    ] = testTypeObj[eachTech][eachItem];
                  }
                });
              }
            }
          });
        }
      }
    });
  }
  return result;
};

export const mergeTestPlan = (existedObject, testType, mergeObject) => {
  let mergedObject = { ...existedObject };
  mergedObject[testType] = { ...mergedObject[testType], ...mergeObject };

  return mergedObject;
};

export const splitFieldByKey = (inputObject) => {
  const formatArray: Array<any> = [];
  if (inputObject) {
    Object.keys(inputObject).forEach((eachKey) => {
      if (!eachKey.includes(constants.TEST_COMBINE_KEY)) {
        formatArray.push(eachKey);
      } else {
        const splitedKeyArray = eachKey.split(constants.TEST_COMBINE_KEY);
        if (splitedKeyArray.length === 3) {
          const techLvl = `${splitedKeyArray[0]}${constants.TEST_COMBINE_KEY}${splitedKeyArray[1]}`;
          formatArray.push(techLvl);
        } else {
          formatArray.push(eachKey);
        }
      }
    });
  }
  return Array.from(new Set(formatArray));
};

export const filterTechKeys = (formatTechLevel: any, key: string) => {
  const resultObj = {};

  if (formatTechLevel) {
    Object.keys(formatTechLevel).forEach((eachTechKey) => {
      if (eachTechKey.includes(key)) {
        resultObj[eachTechKey] = formatTechLevel[eachTechKey];
      }
    });
  }
  return resultObj;
};
