export const Breadcrumb = [
  { text: "Home", href: "/" },
  { text: "Third Party Lab", href: "/third-party-lab" },
];

// using current input string find the lab info object from lab array
export const getCurrentLabData = (inputString, labArray) => {
  let resObj = {};
  if (Array.isArray(labArray)) {
    labArray.forEach((eachObj) => {
      if (eachObj.name === inputString) {
        resObj = eachObj;
      }
    });
  }
  return resObj;
};
