import constants from "../../config/constants";
import { testcasesTypes } from "../types/testcases-type";
const initialState = {
  loadingStatus: constants.LOADING_BEFORE_START,
  data: [],
  headers: [],
  message: "",
};

export const testcasesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case testcasesTypes.GET_TESTCASES:
      return {
        ...initialState,
        loadingStatus: constants.LOADING_LOAD,
      };
    case testcasesTypes.GET_TESTCASES_COMMIT:
      return {
        loadingStatus: constants.LOADING_SUCCESS,
        data: action.payload.test_cases
          ? getTestCases(action.payload.test_cases)
          : [],
        headers: action.payload.headers
          ? getHeaders(action.payload.headers)
          : [],
      };
    case testcasesTypes.GET_TESTCASES_ROLLBACK:
      return {
        ...initialState,
        loadingStatus: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
};

const getTestCases = (test_cases) => {
  let testCaseArray: Array<any> = [];
  for (var testType in test_cases) {
    testCaseArray.push(...test_cases[testType]);
  }
  return testCaseArray;
};

const getHeaders = (headers) => {
  if ("request_id" in headers) {
    delete headers["request_id"];
  }
  if ("assignment_testcategory_id" in headers) {
    delete headers["assignment_testcategory_id"];
  }
  if ("test_category" in headers) {
    delete headers["test_category"];
  }
  return headers;
};
