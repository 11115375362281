import requestStatusType from "../types/request-status-type";
import constants from "../../config/constants";
import { changeDateFormat } from "../../utils/date-utils";

const initialState = {
  requestStatusLoading: constants.LOADING_LOAD,
  requestStatus: [],
  requestStatusMessage: "",

  requestStatusDetailLoading: constants.LOADING_LOAD,
  requestStatusDetail: [],
  requestStatusDetailMessage: "",
};

const requestStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    // case requestStatusType.SET_REQUEST_DETAIL:
    //   return {
    //     ...state,
    //     requestDetail: action.requestDetail,
    //   };
    // get request status
    case requestStatusType.REQUEST_STATUS_CALL:
      return {
        ...state,
        requestStatusLoading: constants.LOADING_LOAD,
      };
    case requestStatusType.REQUEST_STATUS_CALL_COMMIT:
      return {
        ...state,
        requestStatusLoading: constants.LOADING_SUCCESS,
        requestStatus: Array.isArray(action.payload.request)
          ? formatStatusTableData(action.payload.request)
          : [],
      };
    case requestStatusType.REQUEST_STATUS_CALL_ROLLBACK:
      return {
        ...state,
        requestStatusLoading: constants.LOADING_FAIL,
        requestStatusMessage: action.payload.message,
      };

    // get request status detail
    case requestStatusType.REQUEST_STATUS_DETAIL_CALL:
      return {
        ...state,
        requestStatusDetailLoading: constants.LOADING_LOAD,
      };
    case requestStatusType.REQUEST_STATUS_DETAIL_CALL_COMMIT:
      return {
        ...state,
        requestStatusDetailLoading: constants.LOADING_SUCCESS,
        requestStatusDetail: action.payload.assignment,
      };
    case requestStatusType.REQUEST_STATUS_DETAIL_CALL_ROLLBACK:
      return {
        ...state,
        requestStatusDetailLoading: constants.LOADING_FAIL,
        requestStatusDetailMessage: action.payload.message,
      };

    default:
      return state;
  }
};

export default requestStatusReducer;

const formatStatusTableData = (data: any) => {
  data.forEach((item, index) => {
    item.index = index;
    item.create_time = changeDateFormat(item.create_time);
    item.update_time = changeDateFormat(item.update_time);
  });

  return data;
};
