import { RouteComponentProps, withRouter } from "react-router-dom";
import { Navbar as LENSNavbar } from "@amzn/lens-react-ui";
import {
  menuItems,
  helpItem,
  analyticsItem,
  getUserItem,
} from "./navbar-config";
import { username } from "../../authentication/cognito-auth";

type Props = RouteComponentProps<any>;

const Navbar = (props: Props) => (
  <LENSNavbar
    title="WaaS"
    user={getUserItem(username)}
    menuItems={menuItems}
    analytics={analyticsItem}
    help={helpItem}
    routerHistory={props.history}
  />
);

export default withRouter(Navbar);
