import React, { Component } from "react";
// import { Dispatch } from "redux";
import { Input, FormField } from "@amzn/awsui-components-react/polaris";
import { capitalizeFirstLetter } from "../../../../utils/general-utils";

interface StateProps {
  //   requestStatusReducer: any;
}

// declare prop check
type Props = {
  friendlyName: string;
  helpText: string;
  fieldValue: string;
  handleOnChange: (e: any) => void;
  fieldProps: object;
} & StateProps;

class InputField extends Component<Props> {
  render() {
    const { friendlyName, helpText, fieldValue, handleOnChange, fieldProps } =
      this.props;
    return (
      <div className="test-plan-field-input">
        <FormField
          label={
            friendlyName !== "" && `${capitalizeFirstLetter(friendlyName)} :`
          }
          stretch={true}
        >
          <div className="test-plan-field-element">
            <Input
              id={helpText}
              type={"type" in fieldProps ? fieldProps["type"] : "text"}
              className="form-control form-control-sm"
              value={fieldValue}
              onChange={handleOnChange}
              inputmode={"numeric"}
            />
          </div>
        </FormField>
      </div>
    );
  }
}

export default InputField;
