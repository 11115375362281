import constants from "../../../config/constants";
export const convertLabMap = (labList) => {
  // labList = [{id: "1", name: "test_lab"}, {id: "2", name: "test_lab_2"}]
  const resultMap = {};
  if (labList && Array.isArray(labList)) {
    labList.forEach((eachObject) => {
      resultMap[eachObject.id] = eachObject.name;
    });
  }

  return resultMap;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

// merge all dsn in array<object> to an array<string> for valid usage
export const existedDSN = (assignArray) => {
  let returnArray: Array<string> = [];
  if (assignArray && Array.isArray) {
    assignArray.forEach((eachObject) => {
      returnArray = returnArray.concat(
        eachObject[constants.ASSIGN_DSN_KEY].map((eachObject) => eachObject.id)
      );
    });
  }
  return returnArray;
};

export const mergeAssignArray = (existedArray, mergeObject) => {
  // let mergedArray = [...existedArray];
  let mergedArray = [...existedArray];

  const labKeyArray = mergedArray.map((eachObject) =>
    eachObject[constants.ASSIGN_LAB_KEY].toString()
  );
  const currentLabKey = mergeObject[constants.ASSIGN_LAB_KEY];

  if (labKeyArray.includes(currentLabKey)) {
    mergedArray.forEach((eachObject, index) => {
      if (eachObject[constants.ASSIGN_LAB_KEY].toString() === currentLabKey) {
        mergedArray[index][constants.ASSIGN_DSN_KEY] = [
          ...mergedArray[index][constants.ASSIGN_DSN_KEY],
          ...mergeObject[constants.ASSIGN_DSN_KEY],
        ];
      }
    });
  } else {
    mergedArray.push(mergeObject);
  }

  return mergedArray;
};
