import { SideNavigationProps } from "@amzn/awsui-components-react-v3/polaris/side-navigation";
import { config } from "../../config/config";
import constants, { AUTH_USER_TYPE } from "../../config/constants";

// currently amazon user will have all permission to view lab parts
const sidebarUserItems: SideNavigationProps.Item[] = [
  {
    type: "link",
    text: "Home",
    href: "/",
  },
  {
    type: "link",
    text: "Request form",
    href: "/request-form",
  },
  {
    type: "link",
    text: "Request Status",
    href: "/request-status",
  },
  {
    type: "link",
    text: "Third Party Lab",
    href: "/third-party-lab",
  },
  {
    type: "link",
    text: "Upload",
    // href: "/upload",
    href: `/upload/${constants.INITIATIVE}`,
  },
  { type: "divider" },
  {
    type: "link",
    text: "Engineering Link",
    href: config.ENGINEERING_WEBSITE,
    external: true,
  },
  { type: "divider" },
  {
    type: "link",
    text: "Issue SIM",
    href: "https://sim.amazon.com/issues/create?template=94f5b1b7-59a8-4195-bdc9-15debfda57dc",
    external: true,
  },
];

const sidebarlabItems: SideNavigationProps.Item[] = [
  {
    type: "link",
    text: "Home",
    href: "/",
  },
  // {
  //   type: "link",
  //   text: "Third Party Lab",
  //   href: "/third-party-lab",
  // },
  {
    type: "link",
    text: "Upload",
    // href: "/upload",
    href: `/upload/${constants.INITIATIVE}`,
  },
  { type: "divider" },
  {
    type: "link",
    text: "Engineering Link",
    href: config.ENGINEERING_WEBSITE,
    external: true,
  },
  { type: "divider" },
  {
    type: "link",
    text: "Issue SIM",
    href: "https://sim.amazon.com/issues/create?template=94f5b1b7-59a8-4195-bdc9-15debfda57dc",
    external: true,
  },
];

export const getSidebarItems = (userType) => {
  let user_type_item: SideNavigationProps.Item[] = [];

  if (userType === AUTH_USER_TYPE.vendor) {
    user_type_item = sidebarlabItems;
  } else {
    user_type_item = sidebarUserItems;
  }

  return user_type_item;
};
