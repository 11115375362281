import constants from "../../../../config/constants";

const SEPARATOR: String = ", ";

export const desenseCombineKeyHandler = (testPlan) => {
  let combineList: string[] = [];
  if (
    typeof testPlan !== "object" ||
    !(constants.TEST_PLAN_DESENSE_TESTITEM in testPlan)
  ) {
    return combineList;
  }
  const testPlanItemObject = testPlan[constants.TEST_PLAN_DESENSE_TESTITEM];
  return _generateDesenseCombinations(testPlanItemObject, combineList);
};

function _generateDesenseCombinations(
  testPlanItemObject,
  combineList: string[]
) {
  /**
   * Generates combinations of selected tags by adding to list each item
   * appended to each (combine list) item of previous Key level
   * ex: tags = {
   *   antenna: ['ant0', 'ant1']
   *   band: ['2g']
   *   subsystem: ['cpu', 'ddr']
   * }
   * yields:
   * 1: ['ant0', 'ant1']
   * 2: ['ant0, 2g', 'ant1, 2g']
   * 3: ['ant0, 2g, cpu', 'ant1, 2g, cpu', 'ant0, 2g, ddr', 'ant1, 2g, ddr']
   */
  Object.keys(testPlanItemObject)
    .sort()
    .forEach((eachKey) => {
      // eachKey -> antenna, band, subsystem
      let newList: string[] = [];
      testPlanItemObject[eachKey].forEach((eachItem: string) => {
        // eachItem -> tags/items for keys i.e. antenna: ant1, ant0
        if (combineList.length === 0) {
          newList.push(eachItem);
        } else {
          combineList.forEach((eachCombineListItem) => {
            newList.push(`${eachCombineListItem}${SEPARATOR}${eachItem}`);
          });
        }
      });
      combineList = newList;
    });
  return combineList;
}
