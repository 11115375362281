import authzType from "../types/authz-type";
import constants from "../../config/constants";

const initialState = {
  // authz call
  authz: 0,
  // permission control placeholder
  [constants.AUTH_PERMISSION_KEY]: {
    userType: "",
    userRole: "",
    userLab: "",
  },
};

const authzReducer = (state = initialState, action) => {
  switch (action.type) {
    case authzType.AUTHZ_CALL_COMMIT:
      return {
        ...state,
        authz: 1,
        [constants.AUTH_PERMISSION_KEY]: {
          userType: action.payload.user_type,
          userRole: action.payload.role,
          userLab: action.payload.lab,
        },
      };
    case authzType.AUTHZ_CALL_ROLLBACK:
      return {
        ...state,
        authz: -1,
      };
    default:
      return state;
  }
};

export default authzReducer;
