import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import constants from "../../../../config/constants";
import { WIFI_PLUS_KEY } from "./rvr-combine-util";
import { Button } from "@amzn/awsui-components-react/polaris";
import { setUserRequestForm } from "../../../../redux/actions/request-form-action";
// import { capitalizeFirstLetter } from "../../../utils/general-utils";
import "./rvr-combine-key-style.css";

interface StateProps {
  requestFormReducer: any;
}

// declare prop check
type Props = { dispatch: Dispatch<any> } & StateProps;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({});

// this add combine key component is unique for RvR compare with Desense and Compliance
class RvRFinalCombineKeys extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  onClickRemove = (event) => {
    const { userSelectForm } = this.props.requestFormReducer;
    let updateSelected = { ...userSelectForm };

    const removeKey = event.target.id;

    if (
      constants.TEST_CATEGORY_KEY_RVR in
      updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY]
    ) {
      // if exist
      delete updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][
        constants.TEST_CATEGORY_KEY_RVR
      ][removeKey];
    }
    this.props.dispatch(setUserRequestForm(updateSelected));
  };

  render() {
    const { userSelectForm } = this.props.requestFormReducer;
    const rvrCombineObj =
      userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY] &&
      constants.TEST_CATEGORY_KEY_RVR in
        userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY]
        ? userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY][
            constants.TEST_CATEGORY_KEY_RVR
          ]
        : {};

    const mappedCombineKey = Object.keys(rvrCombineObj).map(
      (eachKey, index) => {
        const currentObj = rvrCombineObj[eachKey];
        const splitedArray = eachKey.split(constants.TEST_COMBINE_KEY);
        const currentChannel =
          Array.isArray(splitedArray) && splitedArray.length > 1
            ? splitedArray[0]
            : eachKey;

        const currentLink =
          Array.isArray(splitedArray) && splitedArray.length > 1
            ? splitedArray[1]
            : eachKey;

        return (
          <div key={index} className={"each-rvr-combine"}>
            <div className={"each-rvr-combine-remove-btn"}>
              <Button id={eachKey} onClick={this.onClickRemove}>
                Remove
              </Button>
            </div>
            <div className={"rvr-combine-inner-key-label"}>
              Channel: {currentChannel}
            </div>
            <div className={"rvr-combine-inner-key-label"}>
              {" "}
              Link: {currentLink}
            </div>
            <hr />
            {constants.RVR_WIFI_ONLY in currentObj && (
              <div>
                Wifi Only:{" "}
                {currentObj[constants.RVR_WIFI_ONLY] ? "True" : "False"}
                <hr />
              </div>
            )}
            {WIFI_PLUS_KEY in currentObj && (
              <div>
                {currentObj[WIFI_PLUS_KEY].map((ele, wifi_plus_index) => {
                  return (
                    <div key={wifi_plus_index} className={"rvr-each-wifi-plus"}>
                      {ele}
                    </div>
                  );
                })}
              </div>
            )}
            <br />
          </div>
        );
      }
    );

    return (
      <div className={"rvr-confirmed-combine-container"}>
        {mappedCombineKey}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(RvRFinalCombineKeys);
