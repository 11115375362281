import constants from "../../../../config/constants";

const SEPARATOR: String = ", ";
const MIN_INPUT: string = "min-frequency";
const MAX_INPUT: string = "max-frequency";
const INTERVAL: string = "interval";
const PSEUDO_ACTIVE_COMBINE_SKIP_KEYS = ["technologies", "channel"];

export const combineKeyHandler = (testPlan, requestFormKey) => {
  let combineList: string[] = [];
  if (typeof testPlan !== "object" || !(requestFormKey in testPlan)) {
    return combineList;
  }

  const testPlanItemObject = testPlan[requestFormKey];
  if (requestFormKey === constants.TEST_PLAN_PASSIVE_TESTITEM) {
    return _generatePassiveCombinations(testPlanItemObject, combineList);
  }
  if (requestFormKey === constants.TEST_PLAN_PSEUDO_ACTIVE_TESTITEM) {
    return _generateCombinations(
      testPlanItemObject,
      combineList,
      PSEUDO_ACTIVE_COMBINE_SKIP_KEYS
    );
  }
  return _generateCombinations(testPlanItemObject, combineList);
};

function _generateCombinations(
  testPlanItemObject,
  combineList: string[],
  skipKeys: string[] | undefined = undefined
) {
  const updateNewList = (combineList, newList, item) => {
    if (combineList.length === 0) {
      newList.push(item);
    } else {
      combineList.forEach((eachCombineListItem) => {
        // if (!skipKeys || !skipKeys?.includes(eachCombineListItem)) {

        // }
        newList.push(`${eachCombineListItem}${SEPARATOR}${item}`);
      });
    }
    return newList;
  };

  Object.keys(testPlanItemObject)
    .sort()
    .forEach((eachKey) => {
      if (skipKeys === undefined || !skipKeys.includes(eachKey)) {
        let newList: string[] = [];
        if (Array.isArray(testPlanItemObject[eachKey])) {
          testPlanItemObject[eachKey].forEach((eachItem: string) => {
            newList = updateNewList(combineList, newList, eachItem);
          });
        } else {
          newList = updateNewList(
            combineList,
            newList,
            `${eachKey}: ${testPlanItemObject[eachKey]}`
          );
        }
        combineList = newList;
      }
    });

  return combineList;
}

function _generatePassiveCombinations(
  testPlanItemObject,
  combineList: string[]
) {
  const min: number = testPlanItemObject[MIN_INPUT];
  const max: number = testPlanItemObject[MAX_INPUT];
  const interval = testPlanItemObject[INTERVAL];

  if (min !== undefined && max !== undefined && interval !== undefined) {
    return _generateRange(min, max, interval);
  }
  return combineList;
}

export function _generateRange(min: number, max: number, interval: number) {
  min = Number(min);
  max = Number(max);
  interval = Number(interval);
  const range: number[] = [];
  if (interval <= max - min)
    for (let i = min; i <= max; i = i + interval) {
      range.push(i);
    }
  return range;
}
