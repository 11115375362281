import React from "react";
import "./loading-indicators.css";

export const RunningMan = () => (
  <div className="running">
    <div className="outer">
      <div className="body">
        <div className="arm behind"></div>
        <div className="arm front"></div>
        <div className="leg behind"></div>
        <div className="leg front"></div>
      </div>
    </div>
  </div>
);
