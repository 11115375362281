import constants from "../../config/constants";
import {
  assignmentTestCategoriesTypes,
  labAssignmentTypes,
  updateAssignmentTypes,
} from "../types/assignment-type";
const labAssignmentInitialState = {
  loadingStatus: constants.LOADING_BEFORE_START,
  data: [],
  message: "",
};
const updateAssignmentInitialState = {
  loadingStatus: constants.LOADING_BEFORE_START,
};
const assignmentTestCategoriesInitialState = {
  loadingStatus: constants.LOADING_BEFORE_START,
  data: [],
  message: "",
};

export const labAssignmentReducer = (
  state = labAssignmentInitialState,
  action: any
) => {
  switch (action.type) {
    case labAssignmentTypes.RESET_LAB_ASSIGNMENT:
      return { ...labAssignmentInitialState };
    case labAssignmentTypes.GET_LAB_ASSIGNMENT:
      return {
        ...labAssignmentInitialState,
        loadingStatus: constants.LOADING_LOAD,
      };
    case labAssignmentTypes.GET_LAB_ASSIGNMENT_COMMIT:
      return {
        loadingStatus: constants.LOADING_SUCCESS,
        data: action.payload.assignment ? action.payload.assignment : [],
      };
    case labAssignmentTypes.GET_LAB_ASSIGNMENT_ROLLBACK:
      return {
        ...labAssignmentInitialState,
        loadingStatus: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
};

export const updateAssignmentReducer = (
  state = updateAssignmentInitialState,
  action: any
) => {
  switch (action.type) {
    case updateAssignmentTypes.UPDATE_ASSIGNMENT:
      return {
        loadingStatus: constants.LOADING_LOAD,
      };
    case updateAssignmentTypes.UPDATE_ASSIGNMENT_COMMIT:
      return {
        loadingStatus: constants.LOADING_SUCCESS,
      };
    case updateAssignmentTypes.UPDATE_ASSIGNMENT_ROLLBACK:
      return {
        loadingStatus: constants.LOADING_FAIL,
      };
    case updateAssignmentTypes.RESET_UPDATE_ASSIGNMENT_ASSIGNMENT:
      return {
        ...updateAssignmentInitialState,
      };
    default:
      return state;
  }
};

export const assignmentTestCategoriesReducer = (
  state = assignmentTestCategoriesInitialState,
  action: any
) => {
  switch (action.type) {
    case assignmentTestCategoriesTypes.GET_ASSIGNMENT_TEST_CATEGORIES:
      return {
        ...assignmentTestCategoriesInitialState,
        loadingStatus: constants.LOADING_LOAD,
      };
    case assignmentTestCategoriesTypes.GET_ASSIGNMENT_TEST_CATEGORIES_COMMIT:
      return {
        loadingStatus: constants.LOADING_SUCCESS,
        data: action.payload.assignment ? action.payload.assignment : [],
      };
    case assignmentTestCategoriesTypes.GET_ASSIGNMENT_TEST_CATEGORIES_ROLLBACK:
      return {
        ...assignmentTestCategoriesInitialState,
        loadingStatus: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
};
