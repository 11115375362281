import React from "react";
// sub components
import { getIcon } from "./file-list";
import { Alert, Button, Box, Modal, SpaceBetween } from "@amzn/awsui-components-react-v3/polaris";
// constans
import constants from "../../../config/constants";

const UploadingModal = ({ showModal, onDismiss, onClose, uploadItems }) => {
  const _checkUploadingStatus = () => {
    if (uploadItems.some((item) => item.uploadStatus === constants.LOADING_LOAD)) {
      return 0;
    }

    if (uploadItems.some((item) => item.uploadStatus === constants.LOADING_FAIL)) {
      return -1;
    }
    return 1;
  };

  const uploadingStatus = _checkUploadingStatus();

  return (
    <Modal
      header="Uploading Files"
      visible={showModal}
      onDismiss={onDismiss}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={uploadingStatus === constants.LOADING_LOAD}
              onClick={onClose}
            >
              Done
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        {/* text */}
        {uploadingStatus === constants.LOADING_LOAD && <div>Uploading... Do Not Close!</div>}
        {uploadingStatus === constants.LOADING_SUCCESS && (
          <Alert type="success" header="Success">
            <div>Files are uploaded to s3 and waiting to be parsed.</div>
            <div>The data will be avaliable in 1~2 minutes.</div>
            <br />
            <div>You can close this window now.</div>
          </Alert>
        )}
        {uploadingStatus === constants.LOADING_FAIL && (
          <Alert header="Issues occurred during uploading" type="error">
            Check below information
            {uploadItems.map(({ filename, uploadStatus, message }, index: number) => {
              if (uploadStatus === constants.LOADING_FAIL) {
                return <div key={index}>{`${filename}: ${message}`}</div>;
              } else {
                return null;
              }
            })}
          </Alert>
        )}
        <br />

        {/* icon */}
        {uploadingStatus === constants.LOADING_LOAD && (
          <React.Fragment>
            <div
              className="spinner-border text-secondary"
              role="status"
              style={{ width: "50px", height: "50px" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
            <br />
          </React.Fragment>
        )}

        {/* file list */}
        <br />
        <div style={{ width: "90%" }}>
          {uploadItems.map(({ filename, uploadStatus, message }, index: number) => (
            <li key={index} style={{ fontSize: "14px" }}>
              {filename}&nbsp;
              <span style={{ marginLeft: "20px" }}>{getIcon(uploadStatus, message)}</span>
            </li>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default UploadingModal;
