import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
// sub components
import NotFoundPage from "./containers/not-found/not-found-page";
// import WaaSBranding from "../landing/landing-branding";
import RequestForm from "./containers/request-form/request-form";
import ThirdPartyLab from "./containers/third-party-lab/third-party-lab";
import TestCategories from "./containers/third-party-lab/test-categories/test-categories";
import TestCases from "./containers/third-party-lab/testcases/testcases";
import RequestStatus from "./containers/request-status/request-status";
import RequestDetail from "./containers/request-detail/request-detail";
// upload
import PreUploadPage from "./containers/upload/pre-upload-page";
import UploadPage from "./containers/upload/upload-page";
// logout
import SignOut from "./containers/signout";
import constants, { AUTH_USER_TYPE } from "./config/constants";
import AuthControl from "./containers/sample-view/auth-control";
// landing
import LandingPage from "./containers/landing/landing-page";

const MainRouter = () => {
  const { authnReducer, authzReducer } = useSelector((state: any) => ({
    authnReducer: state.authnReducer,
    authzReducer: state.authzReducer,
  }));
  const { authnState } = authnReducer;
  const userType = authzReducer[constants.AUTH_PERMISSION_KEY]
    ? authzReducer[constants.AUTH_PERMISSION_KEY][constants.AUTH_USERTYPE_KEY]
    : null;

  if (authnState === constants.UNAUTHENTICATED) {
    return (
      <Switch>
        <Redirect from="/signin" exact to="/landing" />
        <Route path="/landing" exact component={LandingPage} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path="/landing" exact component={LandingPage} />
      <Route path="/auth" exact component={AuthControl} />
      <Route
        path={constants.WEBSITE_PATH_COGNITO}
        exact
        component={
          userType === AUTH_USER_TYPE.vendor ? ThirdPartyLab : RequestStatus
        }
      />

      <Route
        path="/home"
        exact
        component={
          userType === AUTH_USER_TYPE.vendor ? ThirdPartyLab : RequestStatus
        }
      />
      <Route path="/not-found" exact component={NotFoundPage} />
      <Route path="/request-form" exact component={RequestForm} />
      <Route path="/request-status" exact component={RequestStatus} />
      <Route path="/third-party-lab" exact component={ThirdPartyLab} />
      <Route
        path="/third-party-lab/assignments/:assignment"
        exact
        component={TestCategories}
      />
      <Route
        path="/third-party-lab/assignments/:assignment/test_categories/:test_category"
        exact
        component={TestCases}
      />
      <Route
        path="/:assignment_id/:dsn/:lab/request-detail"
        exact
        component={RequestDetail}
      />
      <Route path="/upload" exact component={PreUploadPage} />
      <Route path="/upload/:lensProjectCodename" exact component={UploadPage} />
      <Route path="/signout" exact component={SignOut} />
      <Redirect from="/branding" exact to="/landing" />
      <Redirect from="/3rd-lab" exact to="/third-party-lab" />
      <Redirect from="/" exact to="/home" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default MainRouter;
