import requestStatusType from "../types/request-status-type";
import endpoints from "../../config/endpoints";
import { config } from "../../config/config";
import {
  METRIC_INFO,
  USER_REQUEST_METRICS_STATUS,
} from "../../config/constants";

// demo authz call
export const getRequestStatus = (username) => ({
  type: requestStatusType.REQUEST_STATUS_CALL,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.BASE_URL + endpoints.requestStatusEndpoint(username),
        method: "GET",
        // json: { username: username, bindle_type: bindleType },
      },
      commit: {
        type: requestStatusType.REQUEST_STATUS_CALL_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.REQUEST_STATUS,
          status: USER_REQUEST_METRICS_STATUS.SUCCESS,
        },
      },
      rollback: {
        type: requestStatusType.REQUEST_STATUS_CALL_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.REQUEST_STATUS,
          status: USER_REQUEST_METRICS_STATUS.FAIL,
        },
      },
    },
  },
});

export const getRequestStatusDetail = (assignmentId) => ({
  type: requestStatusType.REQUEST_STATUS_DETAIL_CALL,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url:
          config.BASE_URL + endpoints.getAssignmentStatusDetail(assignmentId),
        method: "GET",
        // json: { username: username, bindle_type: bindleType },
      },
      commit: {
        type: requestStatusType.REQUEST_STATUS_DETAIL_CALL_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.REQUEST_DETAIL_STATUS,
          status: USER_REQUEST_METRICS_STATUS.FAIL,
        },
      },
      rollback: {
        type: requestStatusType.REQUEST_STATUS_DETAIL_CALL_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.REQUEST_DETAIL_STATUS,
          status: USER_REQUEST_METRICS_STATUS.FAIL,
        },
      },
    },
  },
});

// export const setRequestDetail = (requestDetail) => ({
//   type: requestStatusType.SET_REQUEST_DETAIL,
//   requestDetail,
// });
