import React, { Component } from "react";
import { Multiselect, FormField } from "@amzn/awsui-components-react/polaris";
// import { capitalizeFirstLetter } from "../../../../utils/general-utils";

interface StateProps {}

// declare prop check
type Props = {
  friendlyName: string;
  helpText: string;
  fieldValueArray: Array<any>;
  selectListContains: Array<any>;
  handleOnChange: (e: any) => void;
} & StateProps;

class MultiSelectField extends Component<Props> {
  render() {
    const {
      friendlyName,
      helpText,
      fieldValueArray,
      handleOnChange,
      selectListContains,
    } = this.props;

    const selectOptions =
      selectListContains && Array.isArray(selectListContains)
        ? selectListContains.map((eachKey) => {
            return {
              id: eachKey,
              label: eachKey,
            };
          })
        : [];

    return (
      <div className="test-plan-field-multi-select">
        <FormField
          // label={`${friendlyName} :`}
          label={friendlyName !== "" && `${friendlyName} :`}
          stretch={true}
        >
          <div className="test-plan-field-element">
            <Multiselect
              id={helpText}
              options={selectOptions}
              empty="No options"
              placeholder={friendlyName !== "" ? friendlyName : helpText}
              checkboxes={true}
              selectedOptions={
                fieldValueArray
                  ? fieldValueArray.map((eachSelect) => {
                      return { id: eachSelect, label: eachSelect };
                    })
                  : []
              }
              onChange={handleOnChange}
              selectedLabel="Selected"
              filteringType="auto"
            ></Multiselect>
          </div>
        </FormField>
      </div>
    );
  }
}

export default MultiSelectField;
