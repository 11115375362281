import React from "react";
import { Icon } from "@amzn/awsui-components-react-v3";
import constants from "../../../../config/constants";

const ValidDetailHelpView = () => {
  return (
    <div>
      <div>
        Compliance Lookup test plan (or master test plan) validation is defined
        by compliance team{" "}
      </div>
      {/* <div>
        This validation shows based on selection combine which kind of test
        plans we will test and which selection combine we will ignore. you don't
        need to change or confirm anything for this validation session
        Combinations are filtered with the Lookup to present all valid testcases
        on the form.
      </div> */}
      <br />
      <div>
        Compliance user selection are filtered with the Lookup information to
        present all <span className={"compliance-valid-plan-text"}>valid</span>{" "}
        testcases on the form & Create request submit only selected combine test
        plans in{" "}
        <span className={"compliance-valid-plan-text"}>Valid Plan</span> for
        later testing
      </div>
      <br />
      <hr />
      <div>
        For any questions about this validation please reach out us via{" "}
        <a
          href={constants.SIM_TICKET_BASE_URL}
          target="_blank"
          rel="noreferrer"
        >
          SIM ticket&nbsp;
          <Icon name="external" size="normal" variant="link" />
        </a>{" "}
        or <span style={{ color: "#0273BB" }}>wise-dev@amazon.com</span>
      </div>
    </div>
  );
};

export default ValidDetailHelpView;
