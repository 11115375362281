import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import constants, {
  CLEAN_DROPDOWN_DICT,
  DROPDOWN_NAMING_COLLECTION,
  TEST_CATEGORY_NAME_MAP,
  TEST_PLAN_SUBMIT_CHECK_LIST,
  PROGRESS_COLOR,
} from "../../config/constants";
import {
  Select,
  Tabs,
  Button,
  Input,
  FormField,
  Spinner,
  Alert,
  Icon,
} from "@amzn/awsui-components-react/polaris";
import { config as appConfig } from "../../config/config";
// import { StatusIndicator } from "@amzn/awsui-components-react/polaris/";
import { capitalizeFirstLetter } from "../../utils/general-utils";
import {
  createRequestForm,
  setUserRequestForm,
} from "../../redux/actions/request-form-action";
import { getProjectBuildInfo } from "../../redux/actions/project-build-info-action";
import {
  handleLvlOptions,
  getSublvlOptions,
  formatOptions,
  getTabLael,
  formatTestPlan,
  removeNoNeedKeys,
} from "./multi-input-component/request-form-utils";
import {
  validTestPlan,
  validAssignment,
} from "./multi-input-component/valid-json";
import AssignInput from "./assign-lab-dsn/assign-lab-dsn";
import TestPlanComponent from "./test-plan/test-plan";
import { username } from "../../authentication/cognito-auth";

import "./multi-input-component/request-form-style.css";

interface StateProps {
  requestFormReducer: any;
  infoProjectBuildReducer: any;
}

type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;
// type State = typeof initialState;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  projectOptions: [] as any[],
  // build-version
  buildOptions: [] as any[],
  projectLoadingStatus: constants.LOADING_BEFORE_START,
  buildLoadingStatus: constants.LOADING_BEFORE_START,
  // all projects
  project: "",
  // build_version
  build: "",
  version: "",
  config: "",

  activeTabId: "0",
});

// root file to handle test-plan tabs and json form as RequestFormComponent
class RequestFormComponent extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  componentDidMount() {
    this.props.dispatch(getProjectBuildInfo());
    // init set username in form
    const { userSelectForm } = this.props.requestFormReducer;
    const updateSelected = { ...userSelectForm };
    updateSelected["username"] = username;
    this.props.dispatch(setUserRequestForm(updateSelected));
  }

  _dataOnChange = (event: any) => {
    // event.detail {selectedId: "test", selectedOption: {…}}
    const currentValue: string = event.detail.selectedOption.id;
    const key = event.target.id;
    // dynamic add state

    const keyArray = Object.values(CLEAN_DROPDOWN_DICT);
    // checks user changes & clear keys

    const currentCleanArray = keyArray.slice(keyArray.indexOf(key) + 1);
    this._clearSelectionArray(currentCleanArray);

    this.setState({
      [key]: currentValue,
    });

    this._setSelected(key, currentValue);
  };

  _inputOnChange = ({ detail: { value } }) => {
    // only for config input
    this.setState({
      config: value,
    });
    this._setSelected("config", value);
  };

  // set in reducer
  _setSelected = (key: any, currentValue: string) => {
    const { userSelectForm } = this.props.requestFormReducer;
    const { infoCollection } = this.props.infoProjectBuildReducer;
    const { project, build, version } = this.state;

    let assignValue = currentValue;
    const updateSelected = { ...userSelectForm };
    updateSelected[key] = assignValue;

    // also update id after user select version
    if (key === CLEAN_DROPDOWN_DICT.version) {
      if (infoCollection[project]) {
        updateSelected["build_id"] =
          infoCollection[project][`${build}_${version}`];
      } else {
        updateSelected["build_id"] = "";
      }
    }

    this.props.dispatch(setUserRequestForm(updateSelected));
  };

  // array to clean set
  _clearSelectionArray = (cleanArray: Array<string>) => {
    if (Array.isArray(cleanArray)) {
      cleanArray.forEach((key) => {
        this._clearSelection(key);
      });
    }
  };

  _clearSelection = (key: string) => {
    // clear selections
    this.setState({
      [key]: null,
    });
    this._setSelected(key, "");
  };

  _submitRequest = () => {
    // submit request
    const { userSelectForm } = this.props.requestFormReducer;

    if (userSelectForm) {
      // if TEST_PLAN_SUBMIT_CHECK_LIST a subarray for reducer form keys
      const containsAllKeys = TEST_PLAN_SUBMIT_CHECK_LIST.every((val) =>
        Object.keys(userSelectForm).includes(val)
      );
      const formattedJson = formatTestPlan(userSelectForm);
      const validAssign = validAssignment(
        formattedJson[constants.REQUEST_FORM_ASSIGN_KEY]
      );
      const { validResult, message } = validTestPlan(
        formattedJson[constants.REQUEST_FORM_TEST_PLAN_KEY]
      );

      if (containsAllKeys && validResult && validAssign) {
        this.props.dispatch(createRequestForm(removeNoNeedKeys(formattedJson)));
        this._cleanUpAfterSubmit();
      } else {
        const baseKeyMessage = containsAllKeys
          ? ""
          : "You need to input all values in Request device & DSN and lab info";
        const testplanMessage = validResult ? "" : message;
        if (!containsAllKeys || !validResult) {
          window.alert(
            `${baseKeyMessage}
             ${testplanMessage}`
          );
        }
      }
    }
  };

  _cleanUpAfterSubmit = () => {
    // const { userSelectForm } = this.props.requestFormReducer;
    // let cleanUpJson = { ...userSelectForm };
    // cleanUpJson[constants.REQUEST_FORM_TEST_PLAN_KEY] = {};
    const cleanUpJson = {
      username: username,
      [constants.REQUEST_FORM_TEST_PLAN_KEY]: {},
      [constants.REQUEST_FORM_ASSIGN_KEY]: [],
    };

    this.setState({
      project: "",
      build: "",
      version: "",
      config: "",
    });
    this.props.dispatch(setUserRequestForm(cleanUpJson));
  };

  _onChangeTab = ({ detail: { activeTabId } }) => {
    this.setState({
      activeTabId: activeTabId,
    });
  };

  render() {
    const { requestForm, createRequestLoadingStatus, createRequestMessage } =
      this.props.requestFormReducer;
    const { infoCollection, infoLoadingStatus } =
      this.props.infoProjectBuildReducer;
    const { project, build, version, config } = this.state;
    // config for dropdown
    const mapObject = {
      project: {
        placeholder: DROPDOWN_NAMING_COLLECTION.project,
        options: formatOptions(Object.keys(infoCollection)),
        status: infoLoadingStatus,
        select: project,
      },
      build: {
        placeholder: DROPDOWN_NAMING_COLLECTION.build,
        options: handleLvlOptions(
          project ? Object.keys(infoCollection[project]) : []
        ),
        status: infoLoadingStatus,
        select: build,
      },
      version: {
        placeholder: DROPDOWN_NAMING_COLLECTION.version,
        options: getSublvlOptions(
          project ? Object.keys(infoCollection[project]) : [],
          build
        ),
        status: infoLoadingStatus,
        select: version,
      },
    };

    // map dropdown, based on mapObject above
    const mappedDropdown = Object.keys(mapObject).map((eachKey, index) => {
      return (
        <div key={index} className={"dropdown-element"}>
          <Select
            id={eachKey}
            empty="No options"
            placeholder={mapObject[eachKey].placeholder}
            selectedLabel="Selected"
            options={mapObject[eachKey].options}
            statusType={
              mapObject[eachKey].status === constants.LOADING_LOAD
                ? constants.SELECT_STATUS_TYPE_LOADING
                : null
            }
            selectedOption={
              mapObject[eachKey].select
                ? {
                    id: mapObject[eachKey].select,
                    label: capitalizeFirstLetter(mapObject[eachKey].select),
                  }
                : null
            }
            onChange={this._dataOnChange}
            filteringType="auto"
          ></Select>
        </div>
      );
    });

    // config input
    const configInput = (
      <div className={"form-input-element"}>
        <FormField
          label={<span>{DROPDOWN_NAMING_COLLECTION.config} </span>}
          stretch={true}
        >
          <Input
            id={"config"}
            placeholder={DROPDOWN_NAMING_COLLECTION.config}
            value={config}
            onChange={this._inputOnChange}
          ></Input>
        </FormField>
      </div>
    );

    // multi request form json handles here
    const requestFormTabs =
      requestForm &&
      requestForm.map((eachObject, index) => {
        // Handles requested from server side with return
        // {formOptions: "": string, testCategory:"": string }
        // Rules in nested json, in lvl 3 better dont using _ since we are using that char as split key
        const jsonParsedData = JSON.parse(`${eachObject.formOptions}`);

        return {
          label: TEST_CATEGORY_NAME_MAP[eachObject.testCategory]
            ? TEST_CATEGORY_NAME_MAP[eachObject.testCategory]
            : eachObject.testCategory,
          id: index.toString(),
          content: (
            <div>
              <TestPlanComponent
                testCategory={eachObject.testCategory}
                formatUI={jsonParsedData}
              ></TestPlanComponent>
            </div>
          ),
        };
      });

    const currentTabLabel = getTabLael(requestFormTabs, this.state.activeTabId);
    return (
      <div>
        <div className="awsui-util-container">
          <div className="awsui-util-container-header">
            <h2>Request Form</h2>
          </div>

          <div className={"form-device-name-dsn"}>
            <div className={"form-device-name-dropdown"}>
              <div className={"form-device-label-text"}>
                {" "}
                Request Device and DSN
              </div>
              <div className={"dropdown-list"}>{mappedDropdown}</div>
            </div>
            <div className={"form-device-id-dropdown"}>{configInput}</div>
          </div>
          <div style={{ width: "50%" }}>
            <Alert header="Can't find project information?">
              Please go to&nbsp;
              <a
                href={appConfig.ENGINEERING_WEBSITE}
                target="_blank"
                rel="noreferrer"
              >
                Engineering Website&nbsp;
                <Icon name="external" size="normal" variant="link" />
              </a>
              &nbsp;to create project/build/version.
            </Alert>
          </div>
          <div className={"form-device-id-dropdown"}>
            <AssignInput />
          </div>

          <Tabs
            tabs={Array.isArray(requestFormTabs) ? requestFormTabs : []}
            activeTabId={this.state.activeTabId}
            onChange={this._onChangeTab}
          ></Tabs>
          <div className="awsui-util-container-footer form-footer">
            <span>
              {`Test Plan ${currentTabLabel} `}

              {createRequestLoadingStatus === constants.LOADING_FAIL && (
                <span style={{ color: PROGRESS_COLOR.fail }}>
                  {/* <StatusIndicator type="error"> */}
                  {createRequestMessage}
                  {/* </StatusIndicator> */}
                </span>
              )}
              {createRequestLoadingStatus === constants.LOADING_LOAD && (
                <Spinner size="normal" />
              )}
              {createRequestLoadingStatus === constants.LOADING_SUCCESS && (
                // true && (
                <span style={{ color: PROGRESS_COLOR.pass }}>
                  Success - view your request
                  <span>
                    {" "}
                    in{" "}
                    <a
                      href={`/request-status`}
                      // target="_blank"
                      // rel="noreferrer"
                    >
                      Request Status{" "}
                      <Icon name="external" size="normal" variant="link" />
                    </a>{" "}
                    around 1 or 2 mins
                  </span>
                </span>
                // </StatusIndicator>
              )}
            </span>
            <div className={"request-form-submit-btn"}>
              <Button
                variant="primary"
                // disabled={canSubmit}
                onClick={this._submitRequest}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
    infoProjectBuildReducer: state.infoProjectBuildReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(RequestFormComponent);
