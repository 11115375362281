import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import {
  assignmentTestCategoriesTypes,
  labAssignmentTypes,
  updateAssignmentTypes,
} from "../types/assignment-type";

export const getLabAssignments = (labId: number) => ({
  type: labAssignmentTypes.GET_LAB_ASSIGNMENT,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.getLabAssignments(labId),
        method: "GET",
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: labAssignmentTypes.GET_LAB_ASSIGNMENT_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: labAssignmentTypes.GET_LAB_ASSIGNMENT_ROLLBACK,
      },
    },
  },
});

export const updateAssignment = (assignmentId: number, values: any) => ({
  type: updateAssignmentTypes.UPDATE_ASSIGNMENT,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL + endpoints.getAssignmentTestCategories(assignmentId),
        method: "PUT",
        json: values,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: updateAssignmentTypes.UPDATE_ASSIGNMENT_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: updateAssignmentTypes.UPDATE_ASSIGNMENT_ROLLBACK,
      },
    },
  },
});

export const resetLabAssignments = () => ({
  type: labAssignmentTypes.RESET_LAB_ASSIGNMENT,
});

export const resetUpdateAssignments = () => ({
  type: updateAssignmentTypes.RESET_UPDATE_ASSIGNMENT_ASSIGNMENT,
});

export const getAssignmentTestCategories = (assignmentId: number) => ({
  type: assignmentTestCategoriesTypes.GET_ASSIGNMENT_TEST_CATEGORIES,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL + endpoints.getAssignmentTestCategories(assignmentId),
        method: "GET",
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: assignmentTestCategoriesTypes.GET_ASSIGNMENT_TEST_CATEGORIES_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: assignmentTestCategoriesTypes.GET_ASSIGNMENT_TEST_CATEGORIES_ROLLBACK,
      },
    },
  },
});
