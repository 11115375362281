import React, { Component } from "react";
// import { Dispatch } from "redux";
import { connect } from "react-redux";
import constants, { TEST_TYPE_MAP } from "../../../config/constants";
import TestPlanWrapUp from "./test-plan-wrap-component";
import RvRCombineKeys from "./rvr-addon/rvr-combine-key";
import DesenseCombineKeys from "./desense-addon/desense-combine-key";

// import { capitalizeFirstLetter } from "../../../utils/general-utils";
import "./test-plan-style.css";

interface StateProps {
  requestFormReducer: any;
}

// declare prop check
type Props = {
  formatUI: any;
  testCategory: string;
} & StateProps;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({});

class TestPlanComponent extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  render() {
    const { formatUI, testCategory } = this.props;

    const mapTestPlan = formatUI
      ? Object.keys(formatUI).map((eachTest, index) => {
          // each Test -> passive, active-pseudo-active, power_table
          return (
            <div key={index} className={"test-plan-group"}>
              <div className={"test-plan-name"}>
                {TEST_TYPE_MAP[eachTest] ? TEST_TYPE_MAP[eachTest] : eachTest}:
              </div>
              <div className={"test-plan-detail"}>
                <TestPlanWrapUp
                  testDetail={formatUI[eachTest]}
                  testType={eachTest}
                />
              </div>
            </div>
          );
        })
      : null;
    return (
      // TODO: move test categories to array, condense ifs
      <div>
        {testCategory === constants.TEST_CATEGORY_KEY_RVR && (
          <div>
            <div className={"test-plan-main"}>{mapTestPlan}</div>
            <div>
              <RvRCombineKeys />
            </div>
          </div>
        )}
        {testCategory === constants.TEST_CATEGORY_KEY_DESENSE && (
          <div>
            <div className={"test-plan-main"}>{mapTestPlan}</div>
            <div>
              {" "}
              <DesenseCombineKeys />
            </div>
          </div>
        )}
        {testCategory === constants.TEST_CATEGORY_KEY_ANTENNA && (
          <div>
            <div className={"test-plan-main"}>{mapTestPlan}</div>
          </div>
        )}
        {testCategory !== constants.TEST_CATEGORY_KEY_RVR &&
          testCategory !== constants.TEST_CATEGORY_KEY_DESENSE &&
          testCategory !== constants.TEST_CATEGORY_KEY_ANTENNA && (
            <div className={"test-plan-main"}>{mapTestPlan}</div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(TestPlanComponent);
