import React, { useState, useEffect } from "react";
import { SpaceBetween, BreadcrumbGroup } from "@amzn/awsui-components-react-v3";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../layout/layout";
import LENSProjectVerification from "./lens-project-verification";
import FileExplanation from "./file-explanation/file-explanation";
import UploadPanel from "./upload-core/upload-panel";
import UploadHistoryTable from "./upload-history-table/table";
// ajax
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { postData, Response } from "../../utils/cognito-fetch";
import { username } from "../../authentication/cognito-auth";
// constants, interface
import constants, { METRIC_INFO } from "../../config/constants";

const UPLOAD_HISTORY_ITEM_SIZE = 5000;

const UploadPage = () => {
  // get LENS Project codename (initiative) from url param
  const { lensProjectCodename } = useParams();
  const history = useHistory();

  // initial LENS project check
  const [lensProjectValidity, setLensProjectValidity] = useState<
    boolean | null
  >(null);
  const [lensProjectShownName, setLensProjectShownName] = useState("");

  // upload history states
  const [uploadHistory, setUploadHistory] = useState<
    Array<{ [key: string]: any }>
  >([]);
  const [uploadHistoryLoadingStatus, setUploadHistoryLoadingStatus] = useState(
    constants.LOADING_LOAD
  );
  const [uploadHistoryError, setUploadHistoryError] = useState("");

  // Ajax func: get upload history
  const _getUploadHistoryData = React.useCallback(
    (syncMode: boolean = false) => {
      if (!syncMode) {
        setUploadHistoryLoadingStatus(constants.LOADING_LOAD);
      }

      postData(
        config.UPLOAD_BASE_URL + endpoints.uploadHistoryEndpoint(),
        {
          initiative: lensProjectCodename.toLowerCase(),
          user_name: username, // TODO: use individual for now, will update when integrating with permission
          page_size: UPLOAD_HISTORY_ITEM_SIZE,
        },
        { initiative: lensProjectCodename, feature: METRIC_INFO.UPLOAD }
      )
        .then((res: Response) => {
          if (res.ok) {
            setUploadHistory(res.json?.data);
            setUploadHistoryLoadingStatus(constants.LOADING_SUCCESS);
          } else {
            setUploadHistoryLoadingStatus(constants.LOADING_FAIL);
            setUploadHistoryError(res.json?.message || res.status_text);
          }
        })
        .catch((e) => {
          setUploadHistoryLoadingStatus(constants.LOADING_FAIL);
          setUploadHistoryError(e.toString());
        });
    },
    [lensProjectCodename]
  );

  // Effect: get upload history
  useEffect(() => {
    if (lensProjectValidity) {
      _getUploadHistoryData();

      // set inteval to periodically get upload history
      const interval = setInterval(() => {
        _getUploadHistoryData(true);
      }, 30000);
      return () => clearInterval(interval);
    }
  }, [lensProjectValidity, _getUploadHistoryData]);

  return (
    <Layout>
      <LENSProjectVerification
        lensProjectCodename={lensProjectCodename}
        lensProjectValidity={lensProjectValidity}
        setLensProjectValidity={setLensProjectValidity}
        setLensProjectShownName={setLensProjectShownName}
      >
        <SpaceBetween direction="vertical" size="l">
          <BreadcrumbGroup
            items={[
              { text: "Upload", href: "/upload" },
              {
                text: `${lensProjectShownName || lensProjectCodename}`,
                href: `/upload/${lensProjectCodename}`,
              },
            ]}
            ariaLabel="Breadcrumbs"
            onFollow={(e) => {
              e.preventDefault();
              history.push(e.detail.href);
            }}
          />
          <FileExplanation lensProjectCodename={lensProjectCodename} />
          <UploadPanel
            lensProjectCodename={lensProjectCodename}
            getUploadHistory={_getUploadHistoryData}
          />
          <UploadHistoryTable
            data={uploadHistory}
            getData={_getUploadHistoryData}
            loading={uploadHistoryLoadingStatus === constants.LOADING_LOAD}
            error={
              uploadHistoryLoadingStatus === constants.LOADING_FAIL
                ? uploadHistoryError
                : ""
            }
          />
        </SpaceBetween>
      </LENSProjectVerification>
    </Layout>
  );
};

export default UploadPage;
