import React from "react";
import { AppLayout } from "@amzn/awsui-components-react-v3";
import { useLocation } from "react-router-dom";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/sidebar";
import { Footer } from "@amzn/lens-react-ui";
import constants from "../../config/constants";
import "./layout.css";

const SIDEBAR_WIDTH = 200;

const Layout = (props) => {
  const location = useLocation();
  const [sidebarWidth, setSidebarWidth] = React.useState(SIDEBAR_WIDTH);

  /*
  if the pathname is for common upload except the upload for WaaS,
  hide the sidebar.
  */
  React.useEffect(() => {
    if (
      location.pathname.includes("/upload") &&
      !location.pathname.includes(`/upload/${constants.INITIATIVE}`)
    ) {
      return setSidebarWidth(0);
    }
    setSidebarWidth(SIDEBAR_WIDTH);
  }, [location.pathname]);

  return (
    <div>
      <Navbar />
      <AppLayout
        id="custom-main-layout"
        content={
          <React.Fragment>
            <div className="main-content">{props.children}</div>
            <Footer />
          </React.Fragment>
        }
        navigation={<Sidebar />}
        toolsHide={true}
        navigationWidth={sidebarWidth}
      />
    </div>
  );
};

export default Layout;
