import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import constants from "../../config/constants";
import { Dispatch } from "redux";
import { graphqlCall } from "../../utils/graphql-api";
import {
  setResponseRequestForm,
  setLabList,
  setLookupTestPlan,
} from "../../redux/actions/request-form-action";
import Layout from "../layout/layout";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import RequestFormComponent from "./request-form-root";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface StateProps {
  requestFormReducer: any;
}
type Props = { dispatch: Dispatch<any> } & typeof defaultProps &
  StateProps &
  RouteComponentProps<any>;
type State = typeof initialState;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  labsLoadingStatus: constants.LOADING_LOAD,
  labs: null,
  formOptionsLoadingStatus: constants.LOADING_LOAD,
  requestFormOptions: null,
  lookupTestPlan: null,
  lookupTestPlanLoadingStatus: constants.LOADING_LOAD,
});

// Main request form for page request form
class RequestForm extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  componentDidMount() {
    // 1) get formOptions list
    const requestFormOptionsQuery = `
    query {
      requestFormOptions {
        testCategory
        formOptions
      }
    }
  `;
    graphqlCall(requestFormOptionsQuery).then(({ json, ok }) => {
      if (ok && json.requestFormOptions) {
        this.setState({
          requestFormOptions: json.requestFormOptions,
          formOptionsLoadingStatus: constants.LOADING_SUCCESS,
        });

        this.props.dispatch(
          setResponseRequestForm(this.state.requestFormOptions)
        );
      }
    });

    // 1) get lab list
    const labsQuery = `
    query {
      labs {
        id
        name
      }
    }
  `;
    graphqlCall(labsQuery).then(({ json, ok }) => {
      if (ok && json.labs) {
        this.setState({
          labs: json.labs,
          labsLoadingStatus: constants.LOADING_SUCCESS,
        });
        if (this.state.labs && Array.isArray(this.state.labs)) {
          this.props.dispatch(setLabList(this.state.labs));
        }
      }
    });

    // 2）
    const lookTableQuery = `
    query {
      complianceLut {
        id
        country
        technology
        mode
        bandwidth
        antenna
      }
    }
  `;
    graphqlCall(lookTableQuery).then(({ json, ok }) => {
      if (ok && json.complianceLut) {
        this.setState({
          lookupTestPlan: json.complianceLut,
          lookupTestPlanLoadingStatus: constants.LOADING_SUCCESS,
        });

        if (
          this.state.lookupTestPlan &&
          Array.isArray(this.state.lookupTestPlan)
        ) {
          this.props.dispatch(setLookupTestPlan(this.state.lookupTestPlan));
        }
      } else {
        window.alert("no lookup table data");
      }
    });
  }

  render() {
    const { formOptionsLoadingStatus } = this.state;

    return (
      <Layout>
        <BreadcrumbGroup
          items={[
            { text: "Home", href: "/" },
            { text: "request form", href: "/request-form" },
          ]}
          onFollow={(e) => {
            e.preventDefault();
            this.props.history.push(e.detail.href);
          }}
        />
        <br />
        {formOptionsLoadingStatus === constants.LOADING_LOAD && (
          <Spinner size="large" />
        )}
        {formOptionsLoadingStatus === constants.LOADING_SUCCESS && (
          <RequestFormComponent />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};
export default withRouter(connect<StateProps>(mapStateToProps)(RequestForm));
