import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Button } from "@amzn/awsui-components-react/polaris";
import constants, { TEST_PLAN_PSEUDO_ACTIVE_KEYS } from "../../../../config/constants";
import { setUserRequestForm } from "../../../../redux/actions/request-form-action";
import "../combine-addon/combine-key-style.css"
import PseudoActiveFinalCombineKeys from "./pseudo-active-key-final"
interface StateProps {
  requestFormReducer: any;
}

// declare prop check
type Props = { dispatch: Dispatch<any> } & StateProps;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  tokenItem: [],
});

// this add combine key component is unique for RvR compare with Desense and Compliance
class PseudoActiveAddPlan extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _onClickAdd = () =>{
    // add current combination to comfirmed keys
    const { userSelectForm } = this.props.requestFormReducer;

    const testplan = userSelectForm[constants.REQUEST_FORM_TEST_PLAN_KEY];
    const selections = testplan[
      `${constants.TEST_PLAN_PSEUDO_ACTIVE_TESTITEM}`
    ]
    if (!this._checkValidSelections(selections)) {
        window.alert(
      "You need to select all Technologies, Channel, Set-power, Antenna and Type fields to add a plan"
    );
    } else {
        const combinations = testplan[
        `${constants.TEST_PLAN_PSEUDO_ACTIVE_TESTITEM}${constants.COMBINATIONS_SUFFIX}`
        ]
        let addedPlan = testplan[
        `${constants.TEST_PLAN_PSEUDO_ACTIVE_TESTITEM}${constants.ADD_SUFFIX}`
        ]

        if (combinations) {
            const channels: [] = selections[TEST_PLAN_PSEUDO_ACTIVE_KEYS.channel].sort((a, b) => (a - b));
            const channelsStr = channels.join(constants.CHANNEL_COMBINE_SEPARATOR)
            const key = `${selections[TEST_PLAN_PSEUDO_ACTIVE_KEYS.technologies]}${constants.TEST_COMBINE_KEY}${channelsStr}`
            if (!addedPlan) {
                addedPlan = {
                    [key]: combinations
                }
            } else {
                addedPlan = {
                    ...addedPlan,
                    [key]: key in addedPlan ? Array.from(new Set([...addedPlan[key],...combinations])) : combinations
                }
            }
            
            let updateSelected = { ...userSelectForm };
            updateSelected[constants.REQUEST_FORM_TEST_PLAN_KEY][
                `${constants.TEST_PLAN_PSEUDO_ACTIVE_TESTITEM}${constants.ADD_SUFFIX}`
            ] = addedPlan;
            this.props.dispatch(setUserRequestForm(updateSelected));
        }
    }
    
  }
  _checkValidSelections = (selections) => {
    if (selections === undefined) {
        return false;
    }
    let valid = true;
    Object.keys(TEST_PLAN_PSEUDO_ACTIVE_KEYS).forEach((key) => {
        if (!(key in selections)) {
            valid = false;
        }
    })
    return valid;
  }
  render() {
    return (
        <React.Fragment>
        <div className={"confim-combine-btn"}>
            <Button onClick={this._onClickAdd}>Add plan</Button>
        </div>
        <div className={"confim-keys-session"}>Confirmed combine:</div>
        <div className={"rvr-combine-key-tokens"}>
          <PseudoActiveFinalCombineKeys />
        </div>
        </React.Fragment>
      
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(PseudoActiveAddPlan);
