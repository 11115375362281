import { METRIC_INFO } from "../config/constants";

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getLogData = (
  feature: string,
  test_category: any = null,
  category: any = null,
  project: any = null
) => {
  const data: {} = { initiative: METRIC_INFO.INITIATIVE, feature };
  if (typeof test_category === "string" && test_category.length > 0)
    data["testcategory"] = test_category; // need to remove _, otherwise can't match in Kibana
  if (typeof category === "string" && category.length > 0)
    data["category"] = capitalizeFirstLetter(category);
  if (typeof project === "string" && project.length > 0)
    data["project"] = capitalizeFirstLetter(project);

  return data;
};
