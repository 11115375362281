import React, { useState, useEffect } from "react";
import { StatusIndicator } from "@amzn/awsui-components-react-v3";
// ajax
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { getData, Response } from "../../utils/cognito-fetch";
// constants
import constants, { METRIC_INFO } from "../../config/constants";

const LENSProjectVerification = ({
  lensProjectCodename,
  lensProjectValidity,
  setLensProjectValidity,
  setLensProjectShownName,
  children,
}) => {
  const [LoadingStatus, setLoadingStatus] = useState(constants.LOADING_LOAD);
  const [lensProjectError, setLensProjectError] = useState("");

  // Ajax: check the lens project codename validity
  useEffect(() => {
    setLoadingStatus(constants.LOADING_LOAD);

    getData(config.UPLOAD_BASE_URL + endpoints.getSupportedLENSProjectsEndpoint(), {
      initiative: METRIC_INFO.INITIATIVE,
      feature: METRIC_INFO.UPLOAD,
    })
      .then((res: Response) => {
        if (res.ok) {
          const lensProjectList = res.json?.data || [];
          if (
            lensProjectList.some(
              (lensProject) => lensProject.codename === lensProjectCodename.toLowerCase()
            )
          ) {
            setLensProjectValidity(true);
            setLensProjectShownName(
              lensProjectList.find(
                (lensProject) => lensProject.codename === lensProjectCodename.toLowerCase()
              ).name
            );
          } else {
            setLensProjectValidity(false);
          }
          setLoadingStatus(constants.LOADING_SUCCESS);
        } else {
          setLoadingStatus(constants.LOADING_FAIL);
          setLensProjectError(res.json?.message || res.status_text);
        }
      })
      .catch((e) => {
        setLoadingStatus(constants.LOADING_FAIL);
        setLensProjectError(e.toString());
      });
  }, [lensProjectCodename, setLensProjectValidity, setLensProjectShownName]);

  if (LoadingStatus === constants.LOADING_LOAD) {
    return <StatusIndicator type="loading" />;
  }

  if (LoadingStatus === constants.LOADING_LOAD) {
    <StatusIndicator type="error">{lensProjectError}</StatusIndicator>;
  }

  if (!lensProjectValidity) {
    return <StatusIndicator type="error">Not supported project.</StatusIndicator>;
  }

  return children;
};

export default LENSProjectVerification;
