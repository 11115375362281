export const defaultPageSize = 20;

const sort = (sortState, id, header) => {
  const isColumnSorted = sortState.sortingColumn === id;
  const ascending = !sortState.sortingDescending;
  return `${header}, ${
    isColumnSorted
      ? `sorted ${ascending ? "ascending" : "descending"}`
      : "not sorted"
  }.`;
};

//define columns
export const columnDefinitions = (headers) => {
  let columns: Array<any> = [];
  let skipHeaders = new Set();
  if (headers["test_case_id"]) {
    skipHeaders.add("test_case_id");
    columns.push({
      id: "test_case_id",
      header: "Test Case Id",
      cell: (e) => e["test_case_id"],
      label: (sortState) => {
        return sort(sortState, "test_case_id", "Test Case Id");
      },
      minWidth: "100px",
      visible: true,
    });
  }
  if (headers["country"]) {
    skipHeaders.add("country");
    columns.push({
      id: "country",
      header: "Country",
      cell: (e) => e["country"],
      label: (sortState) => {
        return sort(sortState, "country", "Country");
      },
      minWidth: "100px",
      visible: true,
    });
  }
  if (headers["technology"]) {
    skipHeaders.add("technology");
    columns.push({
      id: "technology",
      header: "Technology",
      cell: (e) => e["technology"],
      label: (sortState) => {
        return sort(sortState, "technology", "Technology");
      },
      minWidth: "100px",
      visible: true,
    });
  }
  if (headers["status"]) {
    skipHeaders.add("status");
    columns.push({
      id: "status",
      header: "Status",
      cell: (e) => e["status"],
      label: (sortState) => {
        return sort(sortState, "status", "Status");
      },
      minWidth: "100px",
      visible: true,
    });
  }
  for (let key in headers) {
    if (!skipHeaders.has(key))
      columns.push({
        id: key,
        header: headers[key],
        cell: (e) => e[key],
        label: (sortState) => {
          return sort(sortState, key, headers[key]);
        },
        minWidth: "100px",
        visible: true,
      });
  }
  return columns;
};

// define default sorting column
export const defaultSortingColumn = "test_case_id";

// define sortable columns
export const sortableColumns = (headers) => {
  let columns: Array<any> = [];
  let skipHeaders = new Set();
  if (headers["test_case_id"]) {
    skipHeaders.add("test_case_id");
    columns.push({
      id: "test_case_id",
      key: "test_case_id",
    });
  }
  if (headers["country"]) {
    skipHeaders.add("country");
    columns.push({
      id: "country",
      key: "country",
    });
  }
  if (headers["technology"]) {
    skipHeaders.add("technology");
    columns.push({
      id: "technology",
      key: "technology",
    });
  }
  if (headers["status"]) {
    skipHeaders.add("status");
    columns.push({
      id: "status",
      key: "status",
    });
  }
  for (let key in headers) {
    if (!skipHeaders.has(key)) {
      columns.push({
        id: key,
        field: key,
      });
    }
  }
  return columns;
};

// define visiable columns
export const visibleColumns = (headers) => {
  let columns: Array<any> = [];
  let skipHeaders = new Set();
  if (headers["test_case_id"]) {
    skipHeaders.add("test_case_id");
    columns.push({
      id: "test_case_id",
      label: headers["Test Case Id"],
      visible: true,
    });
  }
  if (headers["country"]) {
    skipHeaders.add("country");
    columns.push({
      id: "country",
      label: headers["Country"],
      visible: true,
    });
  }
  if (headers["technology"]) {
    skipHeaders.add("technology");
    columns.push({
      id: "technology",
      label: headers["Technology"],
      visible: true,
    });
  }
  if (headers["status"]) {
    skipHeaders.add("status");
    columns.push({
      id: "status",
      label: headers["Status"],
      visible: true,
    });
  }
  for (let key in headers) {
    if (!skipHeaders.has(key)) {
      columns.push({
        id: key,
        label: headers[key],
        visible: true,
      });
    }
  }
  return [
    {
      label: "Properties",
      options: columns,
    },
  ];
};

// define filtering options
export const filteringOptions = (headers) => {
  let columns: Array<any> = [];
  let skipHeaders = new Set();
  if (headers["test_case_id"]) {
    skipHeaders.add("test_case_id");
    columns.push({
      propertyKey: "test_case_id",
      propertyLabel: headers["Test Case Id"],
      groupValuesLabel: headers["Test Case Id"],
      values: [],
    });
  }
  if (headers["country"]) {
    skipHeaders.add("country");
    columns.push({
      propertyKey: "country",
      propertyLabel: headers["Country"],
      groupValuesLabel: headers["Country"],
      values: [],
    });
  }
  if (headers["technology"]) {
    skipHeaders.add("technology");
    columns.push({
      propertyKey: "technology",
      propertyLabel: headers["Technology"],
      groupValuesLabel: headers["Technology"],
      values: [],
    });
  }
  if (headers["status"]) {
    skipHeaders.add("status");
    columns.push({
      propertyKey: "status",
      propertyLabel: headers["Status"],
      groupValuesLabel: headers["Status"],
      values: [],
    });
  }
  for (let key in headers) {
    if (!skipHeaders.has(key)) {
      columns.push({
        propertyKey: key,
        propertyLabel: headers[key],
        groupValuesLabel: headers[key],
        values: [],
      });
    }
  }
  return columns;
};

export const features = ["propertyFiltering", "pagination", "sorting"];
