import React from "react";
import { Icon } from "@amzn/awsui-components-react/polaris";
import { PROGRESS_COLOR } from "../../../config/constants";
import { getJiraLink } from "../../../components/polaris/table/nav-link";

export const defaultPageSize = 10;

// define columns, table mode
export const columnDefinitions = [
  {
    id: "test_category",
    header: "Test Category",
    // cell: (item) => item.assignment_id,
    cell: (item) => item.test_category,
    minWidth: "120px",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "test_category";
      const ascending = !sortState.sortingDescending;
      return `Test Category, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    visible: true,
  },
  {
    id: "status",
    header: "Status",
    cell: (item) => (
      <div
        style={{
          color: PROGRESS_COLOR[item.status]
            ? PROGRESS_COLOR[item.status]
            : PROGRESS_COLOR.untested,
          fontWeight: 600,
        }}
      >
        {item.status}
      </div>
    ),
    minWidth: "120px",
    visible: true,
  },
  {
    id: "link",
    header: "WLENS Link/Result File",
    cell: (item) => getNavLink(item, "link"),
    minWidth: "200px",
    visible: true,
  },
  {
    id: "tested_test_cases",
    header: "Tested Cases",
    cell: (item) => item.tested_test_cases,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "total_test_cases",
    header: "Total Cases",
    cell: (item) => item.total_test_cases,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "passed",
    header: "Case Passed",
    cell: (item) => (
      <div style={{ color: PROGRESS_COLOR.pass, fontWeight: 600 }}>
        {item.passed}
      </div>
    ),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "failed",
    header: "Case Failed",
    cell: (item) => (
      <div style={{ color: PROGRESS_COLOR.fail, fontWeight: 600 }}>
        {item.failed}
      </div>
    ),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "warning",
    header: "Case Warning",
    cell: (item) => (
      <div style={{ color: PROGRESS_COLOR.warning, fontWeight: 600 }}>
        {item.warning}
      </div>
    ),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "no_limit",
    header: "No limit",
    cell: (item) => (
      <div style={{ color: PROGRESS_COLOR.no_limit, fontWeight: 600 }}>
        {item.no_limit}
      </div>
    ),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "jira",
    header: "Upload Jira",
    cell: (item) => getJiraLink(item, "link"),
    minWidth: "100px",
    visible: true,
  },
];

// define default sorting column
export const defaultSortingColumn = "";

// define sortable columns
export const sortableColumns = [
  { id: "test_category", field: "Test Category" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      {
        id: "test_category",
        label: "Test Category",
        visible: true,
        editable: false,
      },
      {
        id: "status",
        label: "Status",
        visible: true,
        editable: false,
      },
      {
        id: "link",
        label: "WLENS Link/Result File",
        visible: true,
        editable: false,
      },
      {
        id: "tested_test_cases",
        label: "Tested Cases",
        visible: true,
        editable: false,
      },
      {
        id: "total_test_cases",
        label: "Total Cases",
        visible: true,
        editable: false,
      },

      {
        id: "passed",
        label: "Case Passed",
        visible: true,
        editable: false,
      },
      {
        id: "failed",
        label: "Case Failed",
        visible: true,
        editable: false,
      },
      {
        id: "warning",
        label: "Case Warning",
        visible: true,
        editable: false,
      },
      {
        id: "no_limit",
        label: "No limit",
        visible: true,
        editable: false,
      },
      {
        id: "jira",
        label: "Upload Jira",
        visible: true,
        editable: false,
      },
    ],
  },
];

// define filtering options
export const filteringOptions = [];

const getNavLink = (item, text) => {
  return (
    <a href={`${item.link}`} target="_blank" rel="noreferrer">
      {text} <Icon name="external" size="normal" variant="link" />
    </a>
  );

  // <NavLink to={`${item.link}`}>{text}</NavLink>;
};
