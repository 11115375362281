import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { testcasesTypes } from "../types/testcases-type";
import {
  METRIC_INFO,
  USER_REQUEST_METRICS_STATUS,
} from "../../config/constants";

export const getTestCases = (testCategoryId: number) => ({
  type: testcasesTypes.GET_TESTCASES,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL + endpoints.getTestCategoryTestCases(testCategoryId),
        method: "GET",
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: testcasesTypes.GET_TESTCASES_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.THIRD_PARTY_LAB_GET_TESTCASE,
          status: USER_REQUEST_METRICS_STATUS.SUCCESS,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: testcasesTypes.GET_TESTCASES_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.THIRD_PARTY_LAB_GET_TESTCASE,
          status: USER_REQUEST_METRICS_STATUS.SUCCESS,
        },
      },
    },
  },
});
