import React, { useEffect } from "react";
import { signOut } from "../authentication/cognito-auth";

const SignOut = () => {
  useEffect(() => {
    signOut().then(() => {
      console.log("Sign out...");
    });
  }, []);

  return <React.Fragment />;
};

export default SignOut;
